import React, { useState, useEffect } from 'react';
import { bankAccount } from '../../models/bankAccount.model';
import opportunityApi from '../../api/salesforce/OpportunityAPI';
import { useNavigate } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AddNewBankAccountOrDirectDebit } from '../AddNewBankAccount';

export default function BankAccounts(props: any) {
  const navigate = useNavigate();
  const [bankIndex, setBankIndex] = useState<null | number>(null);
  const [loading, setloading] = useState<any>(false);
  const [is_selected, setIsSelected] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [selectedBankAccountData, setSelectedBankAccountData] = useState<any>({
    opportunityID: props.oppId,
    bankAccount: {},
  });

  useEffect(() => {
    if (props.opportunity.newBankAdded) {
      setIsSelected(true);
      setBankIndex(0);
      let selectedBankAccount = props.opportunity.bankAccounts[0];
      if (selectedBankAccount == null) {
        props.nextStep();
        setloading(false);
      } else {
        selectedBankAccount.accountSelected = true;
        setSelectedBankAccountData({
          ...selectedBankAccountData,
          bankAccount: { ...selectedBankAccount },
        });
      }
    }
  }, [props.opportunity]);

  const back = () => {
    props.prevStep();
  };

  const saveAndContinue = () => {
    if (selectedBankAccountData.bankAccount.id) {
      setloading(true);
      opportunityApi
        .sendBankAccountDataToSalesforce(selectedBankAccountData)
        .then((res) => {
          if (!res) {
            //navigate('/application/ThankYou');
            console.log('Bank Account not added, continue with next component');
          }
          props.nextStep();
          setloading(false);
        });
    } else if (props.opportunity.bankAccounts.length === 0) {
      props.nextStep();
    } else {
      setIsSelected(false);
    }
  };

  // When a user selects a bank, update the form state and push the user to the next step
  function onBankClick(selectedBankAccount: bankAccount, index: number) {
    setIsSelected(true);
    setBankIndex(index);
    selectedBankAccount.accountSelected = true;
    setSelectedBankAccountData({
      ...selectedBankAccountData,
      bankAccount: { ...selectedBankAccount },
    });
  }
  return (
    <React.Fragment>
      {loading && (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      <div className='row'>
        {/* <div className='col-lg-10 col-xl-8 offset-lg-1 offset-xl-1'> */}
        <div className='col-12'>
          <h5 className='mb-1 pre-heading'>Applying for loan</h5>
          <h1 className='mb-4 lh-1'>Select Your Bank Account</h1>
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => setTabIndex(index)}
          >
            <TabList style={{ marginBottom: '13px' }}>
              {props.opportunity.bankAccounts.length > 0 && (
                <Tab style={{ border: 'none' }}>Available Accounts</Tab>
              )}
              {props.currentContact.isBorrower &&
                props.currentContact.bankAccountLink !== '' && (
                  <Tab
                    style={{ border: 'none' }}
                  >{`New Account (${props?.currentContact?.firstName} ${props?.currentContact?.lastName})`}</Tab>
                )}
            </TabList>
            {props.opportunity.bankAccounts.length > 0 && (
              <TabPanel>
                {props.opportunity.bankAccounts?.map(
                  (b: bankAccount, index: number) => (
                    <div
                      key={`bank` + index}
                      className={
                        bankIndex === index
                          ? 'bank-acc bank-acc--selected'
                          : 'bank-acc'
                      }
                      onClick={() => onBankClick(b, index)}
                    >
                      <div className='bank-acc__details-wrap'>
                        <p className='bank-acc__details__acc-name'>
                          {b.accountName}
                        </p>
                        <p className='bank-acc__details__acc-number'>
                          {b.accountBSB} {b.accountNumber}
                        </p>
                      </div>
                      <div className='bank-acc__bank-wrap'>
                        <p className='bank-acc__bank__name'>{b.bankName}</p>
                      </div>
                    </div>
                  ),
                )}
              </TabPanel>
            )}
            {(props?.currentContact?.bankAccountLink ||
              props.opportunity?.bankAccountPurpose === 'Disbursement') && (
              <TabPanel>
                <AddNewBankAccountOrDirectDebit
                  {...props}
                  setTabIndex={setTabIndex}
                />
              </TabPanel>
            )}
          </Tabs>
          {is_selected == false ? (
            <div className='file-error-bank rounded'>
              <span className='error-span'>
                Please select bank account first.
              </span>
            </div>
          ) : null}
          <div className='d-flex align-items-center mt-4'>
            {props.currentStep !== 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              disabled={!is_selected}
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
          <p className='mt-4 bank-acc__note'>
            <em>
              Note: If an account is missing from this list, it may not be a
              compatible transaction bank account that allows direct debit.
            </em>
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}
