import React, { useState } from 'react';
import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import { useNavigate } from 'react-router-dom';
import { DashboardModal } from '@uppy/react';
import OpportunityAPI from '../api/salesforce/OpportunityAPI';
import Webcam from '@uppy/webcam';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
const GoogleDrive = require('@uppy/google-drive');
const Dropbox = require('@uppy/dropbox');
const Box = require('@uppy/box');
const OneDrive = require('@uppy/onedrive');
const Url = require('@uppy/url');

export default function DragAndDrop(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectSubCat, setSubCat] = useState(null);
  const navigate = useNavigate();
  const devKey = `Test/Opportunity/${props.oppId}`;
  const prodKey = `Opportunity/${props.oppId}`;

  const uppy = new Uppy({
    id: props.id,
    restrictions: {
      allowedFileTypes: ['.pdf', '.csv', '.jpg', '.jpeg', '.png', '.webp']
    },
    onBeforeFileAdded: (currentFile, files) => {
      if (currentFile.name.length > 80) {
        // show error message to the user
        uppy.info(`File names must be no more than 80 characters long.`);
        return false;
      }
    },
  })
    .use(Webcam)
    .use(GoogleDrive, { companionUrl: 'https://companion.uppy.io' })
    .use(Dropbox, { companionUrl: 'https://companion.uppy.io' })
    .use(Box, { companionUrl: 'https://companion.uppy.io' })
    .use(OneDrive, { companionUrl: 'https://companion.uppy.io' })
    .use(Url, { companionUrl: 'https://companion.uppy.io' })
    .use(AwsS3, {
      getUploadParameters(file) {
        return fetch(`${process.env.REACT_APP_PRESIGNED_API_URL}`, {
          method: 'post',
          body: JSON.stringify({
            filename: file.name,
            key: process.env.REACT_APP_STAGE == 'prod' ? prodKey : devKey,
            fileType: file.extension,
            conType: file.type,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            const obj = {
              method: `put`,
              url: `${data.url}`,
              fields: [],
              headers: {
                'Content-Type': file.type,
              },
            };
            return obj;
          });
      },
    })
    .on('upload-success', (file, data) => {
      const obj = {
        opportunityID: props.oppId,
        document: {
          name: file.name,
          status: 'Submitted',
          category: props?.id,
          subCategory: selectSubCat,
          path: data?.body?.location || null,
        },
      };
      saveDocument(obj);
    });

  const handleOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
  };
  const saveDocument = (documentObj) => {
    OpportunityAPI.saveDocument(documentObj).then((res) => {
      if (res.success === false) {
        navigate('/application/ThankYou');
        return;
      }
      setModalOpen(false);
      props.setReload((pre) => !pre);
    });
  };
  return (
    <div>
      <div className='p-3 rounded main_div' onClick={handleOpen}>
        <span className='parent_fileType'>
          {props.subCategory ? (
            <div>
              <span className='subCatList'>
                Select File Type and File
                <span className='subListItems'>
                  {props?.subCategory?.subCategory.map((item, index) => {
                    return (
                      <p key={index} onClick={(e) => setSubCat(item)}>
                        {item}
                      </p>
                    );
                  })}
                </span>
              </span>
              <span style={{ marginLeft: '18px' }}>or Browse File</span>
            </div>
          ) : (
            <span style={{ paddingLeft: '5px' }}>Browse File</span>
          )}
        </span>
      </div>
      <DashboardModal
        uppy={uppy}
        closeModalOnClickOutsid
        open={modalOpen}
        onRequestClose={handleClose}
        //plugins={['Webcam', 'GoogleDrive', 'Dropbox', 'OneDrive', 'Box', 'Url']}
        //plugins={['Webcam']}
      />
    </div>
  );
}
