import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import SignCanvas from '../SignCanvas';
import ModalView from '../ModelView';
import dateFormat from '../../utils/dateFormat';
import AdoptSignatureModal from '../AdoptSignatureModal';

export default React.memo(function Contracts(props: any) {
  console.log({ props });
  const navigate = useNavigate();
  const [contractData, setContractData] = useState<any>(null);
  const [modelShow, setModelShow] = useState(false);
  const [content, setContent] = useState<any>('');
  const [openedArray, setOpenedArray] = useState<any>([]);
  const [currentSignee, setCurrentSignee] = useState<any>(null);
  const [notSigned, setNotSigned] = useState<any>([]);
  const [totalUnsignedUser, setTotalUnsignedUser] = useState<any>(null);
  const [loading, setloading] = useState<any>(true);
  const [termsValid, setTermsValid] = useState<any>('');
  const [userSigned, setUserSigned] = useState<any>(false);
  const [showAdoptModal, setShowAdoptModal] = useState<any>(false);
  const [signValid, setSignValid] = useState<any>('');

  let sign = useRef({});

  const handleRetrieveContracts = () => {
    return OpportunityAPI.getContractData({ opportunityID: props.oppId })
      .then((res: any) => {
        //filter out not required documents;
        if (res != null && res.contracts != null && res.contracts.length > 0) {
          let contractRequiredToSign: any = [];
          res.contracts.forEach((contract: any, ind: number) => {
            contract.signees.forEach((signee: any) => {
              if (signee.oppContactId === props.currentContact.id) {
                console.log(
                  'current user signee ' +
                    signee.name +
                    ' is required to sign contract ' +
                    ind,
                );
                contractRequiredToSign.push(contract);
              }
            });
          });
          res.contracts = contractRequiredToSign;
        }

        setContractData(res);
        updateNotSigned(res);

        if (res?.contracts.length === 0) {
          saveAndContinueNext();
        } else {
          let user = res?.contracts[res?.contracts.length - 1]?.signees.filter(
            (user: any) => {
              return user?.oppContactId === props.currentContact.id;
            },
          );
          let totalUser = res?.contracts[0]?.signees.length;
          let signed = res?.contracts[0]?.signees.filter((user: any) => {
            return user?.isSigned === true;
          }).length;
          setTotalUnsignedUser(totalUser - signed);
          setUserSigned(user[0]?.isSigned);
          setCurrentSignee(user[0]);
          setloading(false);
        }
        return;
      })
      .catch((err) => {
        console.log({ err });
        setloading(false);
      });
  };

  useEffect(() => {
    handleRetrieveContracts();
  }, []);

  const updateNotSigned = (contractData: any) => {
    let notsignedContract: any = [];
    contractData?.contracts.forEach((con: any, index: number) => {
      con?.signees.forEach((user: any, ind: number) => {
        if (
          user.oppContactId === props.currentContact.id &&
          user.isSigned === false
        ) {
          notsignedContract.push(index);
        }
      });
    });
    console.log({ notsignedContract });
    setNotSigned(notsignedContract);
  };

  const back = () => {
    props.prevStep();
  };
  const saveAndContinueNext = () => {
    if (props.opportunity.opportunityStage !== 'Loan Signed') {
      setloading(true);
      if (props.oppId) {
        setTimeout(() => {
          OpportunityAPI.getOpportunityStatus(props.oppId).then(async (res) => {
            if (res?.success === true) {
              props.setOpportunity({
                ...props.opportunity,
                opportunityStage: res.stage,
              });
              props.nextStep();

              await handleRetrieveContracts();
            } else {
              navigate('/application/ThankYou');
            }
          });
        }, 8000);
      }
    } else {
      props.nextStep();
    }
  };
  const saveAndContinue = (contractsInfo: any) => {
    if (contractsInfo?.contracts.length) {
      setloading(true);
      OpportunityAPI.saveContractData(contractsInfo)
        .then(async (res: any) => {
          console.log(res);
          if (!res) {
            setloading(false);
            navigate('/application/ThankYou');
            return;
          }
          if (res.success) {
            await handleRetrieveContracts();
            setUserSigned(true);
            setloading(false);

            // on successful document sign, rerender staus
            // unread, read, signed, waiting for others
          }
        })
        .catch((err) => {
          console.log({ err });
          setloading(false);
        });
    }
  };

  const [currentViewIndex, setCurrentViewIndex] = useState<number | undefined>(
    undefined,
  );

  const handleView = (content: any, index: number) => {
    if (notSigned.includes(index)) {
      let arr = Array.from(new Set([...openedArray, index]));
      setOpenedArray(arr);
    }
    setModelShow(true);
    if (content.includes('---Signature---')) {
      console.log('contains Signature');
      content = content.replaceAll('---Signature---', '');
    } else if (content.includes('---PageBreak---')) {
      content = content.replaceAll('---PageBreak---', '');
      console.log('contains pagebreak');
    }
    setContent(content);

    setCurrentViewIndex(index);
  };
  const handleCloseView = () => {
    setModelShow(false);
    setContent('');
  };

  const handleNext = () => {
    if (currentViewIndex === undefined) return;
    const nextIndex = currentViewIndex + 1;
    const content = contractData.contracts[nextIndex]?.content;

    // no more contracts
    if (contractData?.contracts.length === nextIndex) {
      handleCloseView();
      return;
    }
    if (content) handleView(content, nextIndex);
  };

  const needsToSign = (function () {
    type Signee = { isSigned: boolean; oppContactId: string; name: string };

    const needsToSign = new Set<string>();

    contractData?.contracts?.forEach(
      (contract: { isSigned: boolean; signees: Signee[] }) => {
        if (contract.isSigned) return;

        contract.signees.forEach((s) => {
          if (!s.isSigned) needsToSign.add(s.name);
        });
      },
    );

    return Array.from(needsToSign.values());
  })();

  return (
    <React.Fragment>
      {loading && (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      {currentSignee && (
        <div className='row'>
          {/* <div className='col-sm-10 offset-sm-1'> */}
          <div className='col-12'>
            <h1 className='mb-4 lh-1'>Sign Documents</h1>
            <p className='mb-4'>
              To complete your application, please read the document(s) below
              carefully and then sign using the space provided.
            </p>
            <h3 className='mb-2'>Documents to Review</h3>
            {!contractData ? (
              <div className='d-flex justify-content-center'>
                <div className='spinner-border' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : (
              <div className='card mb-4'>
                <div className='card-body p-0'>
                  {/* <div className="table-responsive"> */}
                  <div className='responsive-table-wrapper'>
                    <table
                      className='table mb-0 align-middle'
                      data-toggle='table'
                      data-mobile-responsive='true'
                    >
                      <thead className='bg-light'>
                        <tr className='custom-row'>
                          <th scope='col' className=''>
                            Document
                          </th>
                          {/* <th scope="col" className='text-center'>Created</th> */}
                          {/* <th scope='col' className='text-center'>
                            Signed
                          </th> */}
                          <th scope='col' className='text-center'>
                            Status
                          </th>
                          {/* <th scope="col" className='text-center'>Action</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {contractData?.contracts?.map(
                          (con: any, index: number) => {
                            if (
                              totalUnsignedUser === 1 &&
                              currentSignee.isSigned === false &&
                              userSigned
                            ) {
                              con.status = 'Signed';
                            }
                            return (
                              <tr
                                key={index + 'key'}
                                className={'document-row'}
                                onClick={() => handleView(con.content, index)}
                              >
                                <td className='lh-sm' data-label='Document'>
                                  <div className='d-flex'>
                                    <div className='bg-light text-primary file-icon-wrapper'>
                                      <i className='bi bi-file-earmark-text'></i>
                                    </div>
                                    <div className='document-name-date-wrapper'>
                                      <button
                                        type='button'
                                        className='btn btn-link p-0 link-indicator'
                                      >
                                        {con.name}{' '}
                                        <sup>
                                          <i className='bi bi-box-arrow-up-right'></i>
                                        </sup>
                                      </button>
                                      <span className='text-muted document-created-text'>
                                        Created{' '}
                                        {dateFormat(new Date(con.createdDate))}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                {/* <td className='text-center custom-col'>{dateFormat(new Date(con.createdDate))}</td> */}
                                {/* <td className='text-center' data-label='Signed'>
                                  <div className='document-status-pill-wrapper'>
                                    <label
                                      className={`btn-sm rounded-pill py-0 ${
                                        con.status === 'Incomplete'
                                          ? 'btn-blue'
                                          : 'btn-green'
                                      }`}
                                    >
                                      {con.status}
                                    </label>
                                  </div>
                                </td> */}
                                <td className='text-center' data-label='Status'>
                                  <div className='document-status-pill-wrapper'>
                                    {!notSigned.includes(index) ? (
                                      <label className='btn-sm rounded-pill py-0 btn-green'>
                                        {con.status === 'Incomplete'
                                          ? 'Waiting for others'
                                          : 'Signed'}
                                      </label>
                                    ) : openedArray.includes(index) ? (
                                      <label className='btn-sm rounded-pill py-0 btn-green'>
                                        Read
                                      </label>
                                    ) : (
                                      <label className='btn-sm rounded-pill py-0 btn-yellow'>
                                        Unopened
                                      </label>
                                    )}
                                  </div>
                                </td>
                                {/* <td className='text-center'><button type="button" className="btn btn-sm" onClick={()=>handleView(con.content,index)}>Open</button></td> */}
                              </tr>
                            );
                          },
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {modelShow && (
              <ModalView
                title={
                  currentViewIndex !== undefined
                    ? contractData?.contracts[currentViewIndex].name
                    : currentViewIndex
                }
                content={content}
                close={handleCloseView}
                next={handleNext}
                isEnd={contractData?.contracts.length - 1 === currentViewIndex}
              />
            )}
            {showAdoptModal && (
              <AdoptSignatureModal
                {...props}
                close={setShowAdoptModal}
                sign={sign}
                setSignValid={setSignValid}
              />
            )}
            {openedArray.length === notSigned.length &&
            notSigned.length > 0 &&
            !userSigned ? (
              <div>
                <div className='flex flex-col mt-2 py-2'>
                  <div className='alert alert-success' role='alert'>
                    You can now sign using the canvas below or by adopting a
                    signature.
                  </div>
                  <h3 className='mt-2 mb-2'>Sign Documents</h3>
                  <SignCanvas
                    userIp={props.userIp}
                    termsId={'terms'}
                    currentUserID={props.currentContact.id}
                    signee={currentSignee}
                    contractsData={contractData}
                    oppID={props.oppId}
                    saveAndContinue={saveAndContinue}
                    setTermsValid={setTermsValid}
                    termsValid={termsValid}
                    sign={sign}
                    signValid={signValid}
                    setSignValid={setSignValid}
                    setShowAdoptModal={setShowAdoptModal}
                  />
                </div>
              </div>
            ) : totalUnsignedUser === 1 &&
              currentSignee.isSigned === false &&
              userSigned ? (
              <p>Congratulations, you have successfully signed.</p>
            ) : totalUnsignedUser >= 1 && userSigned ? (
              <>
                <div className='alert alert-success' role='alert'>
                  Waiting for other partners to sign contracts. You may proceed
                  to the next page.
                </div>
                {needsToSign.length && (
                  <div className='mb-8'>
                    <p>Others who still need to sign: </p>
                    <ul>
                      {needsToSign.map((name) => (
                        <li key={name}>{name}</li>
                      ))}
                    </ul>
                  </div>
                )}
              </>
            ) : (
              <div className='alert alert-warning' role='alert'>
                Please read all documents above
              </div>
            )}

            <div className='d-flex align-items-center'>
              {props.currentStep !== 1 && (
                <button
                  className='btn btn-outline-secondary me-3'
                  onClick={back}
                >
                  Back
                </button>
              )}
              <button
                type='submit'
                className='btn btn-primary me-3'
                disabled={!userSigned}
                id='submitBtn'
                onClick={saveAndContinueNext}
              >
                Finish
              </button>
              <p className='text-muted m-0'>
                Page {props.currentStep} of {props.totalPage}
              </p>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});
