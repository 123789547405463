import React, { useEffect, useState } from 'react';
import formatCurrency from '../../utils/formatCurrency';
import dateFormat from '../../utils/dateFormat';
import dateStringFormat from '../../utils/dateStringFormat';

const StatementAgentPdf = (props) => {
  const { inputDate } = props;
  const [tableData, setTableData] = useState([]);
  const [balOutstanding, setBalOutstanding] = useState(0.0);
  const [subTotalLent, setSubTotalLent] = useState(0.0);
  const [totalFees, setTotalFees] = useState(0.0);
  const [paidToDate, setPaidToDate] = useState(0.0);
  const [totalAgentAccount, setTotalAgentAccount] = useState(0.0);
  const [totalClientAccount, setTotalClientAccount] = useState(0.0);

  const statusArray = ['Paid', 'Waived', 'Charged', 'Posted', 'Processing'];
  const statement =
    'This schedule may not reflect any changes made within the last two business days. Please always be sure to reconfirm the balance prior to any repayment.';

  let balance = 0.0;

  useEffect(() => {
    let subTotalLent = 0.0;
    let totalFees = 0.0;
    let paidToDate = 0.0;
    let totalAgentAccount = 0.0;
    const newScheduleList = props.paymentScheduleList.filter(
      (item) =>
        item.date >= props.today &&
        item.status === 'Scheduled' &&
        item.type === 'Fee',
    );
    const newHistoryList = props.paymentHistoryList.filter(
      (item) => statusArray.includes(item.status) && item.date <= props.today,
    );
    const data = [...newScheduleList, ...newHistoryList];
    data.sort(compare);
    const filterData = data.filter(
      (item) => item.date <= inputDate && item.agentAccount,
    );
    filterData.forEach((pay) => {
      if (pay.type === 'Disbursement') {
        subTotalLent += Number(pay.amount);
        totalAgentAccount += pay.agentAccount ? Number(pay.amount) : 0;
      }
      if (pay.type === 'Fee') {
        if (pay.direction === 'Credit') {
          totalFees -= Number(pay.amount);
          totalAgentAccount -= pay.agentAccount ? Number(pay.amount) : 0;
        }
        if (pay.direction === 'Debit') {
          totalFees += Number(pay.amount);
          totalAgentAccount += pay.agentAccount ? Number(pay.amount) : 0;
        }
      }
      if (pay.type === 'Instalment') {
        if (pay.direction === 'Credit') {
          paidToDate += Number(pay.amount);
          totalAgentAccount += pay.agentAccount ? Number(pay.amount) : 0;
        }
        if (pay.direction === 'Debit') {
          paidToDate -= Number(pay.amount);
          totalAgentAccount -= pay.agentAccount ? Number(pay.amount) : 0;
        }
      }
    });

    setBalOutstanding(subTotalLent + totalFees - paidToDate);
    setTotalAgentAccount(totalAgentAccount);
    setTotalClientAccount(
      subTotalLent + totalFees - paidToDate - totalAgentAccount,
    );
    setSubTotalLent(subTotalLent);
    setTotalFees(totalFees);
    setPaidToDate(paidToDate);
    setTableData(filterData);
  }, [inputDate]);

  const filteredLoans = props.loanList.filter(
    (loan) => loan.product_code === 'VPA',
  );
  const totalVPAAmount = filteredLoans.reduce(
    (acc, loan) => acc + loan.amount_current,
    0,
  );

  const totalOwed = subTotalLent + totalFees;

  function compare(a, b) {
    if (a.date < b.date) {
      return -1;
    }
    if (a.date > b.date) {
      return 1;
    }
    return 0;
  }
  const css = `
    .print_data{
        height:0;
        overflow:hidden;
    }
  
    @media print {
        main,section {page-break-inside: avoid;}
        .print_data{
            height:auto;
            overflow:visible;
        }
      }
    `;

  return (
    <div id='Print_Agency_Pdf'>
      <div className='print_data'>
        <link
          href='https://app.prod.pcredit.com.au/css/custom.css?ckcachebust=5C9A1196895FEA49358621845239A41'
          rel='stylesheet'
        />
        <link
          href='https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700;900&amp;family=Roboto+Slab:wght@400;700;900&amp;display=swap'
          rel='stylesheet'
        />
        <link
          href='https://app.prod.pcredit.com.au/css/main.css'
          rel='stylesheet'
        />
        <link
          href='https://app.prod.pcredit.com.au/css/partner-tapi.css'
          rel='stylesheet'
        />
        <link
          href='https://app.prod.pcredit.com.au/css/partner-auctions-live.css'
          rel='stylesheet'
        />
        <link
          href='https://app.prod.pcredit.com.au/css/pdfprint.css'
          rel='stylesheet'
        />
        <style>{css}</style>
        <div className='body'>
          <div className='row mb-4'>
            <div className='col-9'>
              <span tabIndex='-1'>
                {/* <img alt="" data-widget="image" src={pcLogo1} width="250" /> */}
                <img
                  alt=''
                  data-widget='image'
                  src='https://app.prod.pcredit.com.au/static/media/pc-logo.826064642572267e5dd9d5fb1aebc800.svg'
                  width='250'
                />
              </span>
            </div>

            {props?.countryCode === 'AU' && (
              <div className='col-3'>
                <span>
                  <b>Property Credit Pty Ltd</b>
                  <br />
                  <span>ABN 93 638 329 674</span>
                  <br />
                  PO Box 387
                  <br />
                  Varsity Lakes QLD 4227 Australia
                </span>
              </div>
            )}
            {props?.countryCode === 'NZ' && (
              <div className='col-3'>
                <span>
                  <b>Property Credit Pty Ltd</b>
                  <br />
                  <span>NZBN 9429050855445</span>
                  <br />
                  PO Box 387
                  <br />
                  Varsity Lakes QLD 4227 Australia
                </span>
              </div>
            )}
          </div>
          <div className='row'>
            <div className='col-12'>
              <h5 className='mb-3 pre-heading'>{props.agencyName}</h5>
              <h2 className='mb-1 lh-1'>{props.shortAddress} </h2>
              <p
                className='p mb-4
                p-font'
              >
                <strong>{props.accountName} </strong> {props.opportunityName}
              </p>

              <h2 className='h2 mb-3'>Finance Details</h2>

              <div className='card mb-5'>
                <div className='card-body border-bottom'>
                  <div className='row'>
                    <div className='col-sm-3 pb-2 pb-sm-0'>
                      <h6 className='mb-0 pre-heading-h6'>Statement Date</h6>
                      <p className='p-font mb-0'>
                        {dateStringFormat(inputDate, '-', '/', true)}
                      </p>
                    </div>
                    <div className='col-sm-3 pb-2 pb-sm-0'>
                      <h6 className='mb-0 pre-heading-h6'>Loan Purpose</h6>
                      <p className='p-font mb-0'>Vendor Paid Advertising</p>
                    </div>
                    <div className='col-sm-6 pb-2 pb-sm-0'>
                      <h6 className='mb-0 pre-heading-h6'>Property Address</h6>
                      <p className='p-font mb-0'>{props.propertyAddress}</p>
                    </div>
                  </div>
                </div>
                <div className='card-body border-bottom'>
                  <div className='row'>
                    <div className='col-sm-3 pb-2 pb-sm-0'>
                      <h6 className='mb-0 pre-heading-h6'>
                        Advertising Amount
                      </h6>
                      <p className='p-font mb-0'>
                        {formatCurrency(totalVPAAmount)}
                      </p>
                    </div>
                    <div className='col-sm-3'>
                      <h6 className='mb-0 pre-heading-h6'>
                        Balance Outstanding
                      </h6>
                      <p className='p-font mb-0'>
                        {formatCurrency(balOutstanding)}
                      </p>
                    </div>
                    <div className='col-sm-3 pb-2 pb-sm-0'>
                      <h6 className='mb-0 pre-heading-h6'>Repayment</h6>
                      <p className='p-font mb-0'>
                        {/* {props.loan.repaymentFrequency} */}
                        At Settlement
                      </p>
                    </div>
                    <div className='col-sm-3'>
                      <h6 className='mb-0 pre-heading-h6'>Loan Term</h6>
                      <p className='p-font mb-0'>{props.loan.loanTerm}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <h2 className='h2 mb-3'>Agency Statement</h2>
            <div className='table-responsive-lg'>
              <table className='table card__table mb-4'>
                <thead className='card__table__table-head'>
                  <tr>
                    <th scope='col' className=''>
                      DATE
                    </th>
                    <th scope='col' className=''>
                      TRANSACTION{' '}
                    </th>
                    <th scope='col' className='text-end'>
                      DEBIT
                    </th>
                    <th scope='col' className='text-end'>
                      CREDIT
                    </th>
                    <th scope='col' className='text-end'>
                      Balance
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((pay, ind) => {
                    if (pay.direction === 'Credit') {
                      balance -= Number(pay.amount);
                    }
                    if (pay.direction === 'Debit') {
                      balance += Number(pay.amount);
                    }
                    return (
                      <tr key={ind + 'pay'}>
                        <td className=''>{dateFormat(new Date(pay.date))}</td>
                        <td className=''>
                          {pay.name}
                          {pay.status === 'Processing' && ' (Processing)'}{' '}
                          {pay?.agentAccount && (
                            <div className='badge rounded-pill bg-white border fw-normal text-secondary'>
                              Agent
                            </div>
                          )}
                        </td>
                        <td className='text-end'>
                          {pay.direction === 'Debit'
                            ? `${formatCurrency(pay.amount)}`
                            : null}
                        </td>
                        <td className='text-end'>
                          {pay.direction === 'Credit'
                            ? `${formatCurrency(pay.amount)}`
                            : null}
                        </td>
                        <td className='text-end'>{formatCurrency(balance)}</td>
                      </tr>
                    );
                  })}
                  {!tableData.length && (
                    <tr>
                      <td colSpan={4} className='align-middle'>
                        There are no records to display...
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <section className='col-6 offset-6 '>
            <div className='table-responsive-lg'>
              <table className='table card__table mb-4'>
                <tbody>
                  <tr>
                    <td>Total Fees</td>
                    <td className='text-end'>{formatCurrency(totalOwed)}</td>
                  </tr>
                  <tr>
                    <td>Payments to Statement Date</td>
                    <td className='text-end'>{formatCurrency(paidToDate)}</td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Balance Outstanding</strong>
                    </td>
                    <td className='text-end'>
                      {formatCurrency(balOutstanding)}
                    </td>
                  </tr>
                </tbody>
              </table>
              <p style={{ color: 'red' }}>
                This schedule may not reflect any changes made within the last
                two business days. Please reconfirm the balance prior to any
                repayment.
              </p>
            </div>
          </section>
        </div>
        <main className='row'>
          <div className='col-12'>
            <h2>How to Pay</h2>
          </div>
          {props?.loan?.repaymentByDR == true && (
            <section className='col-6'>
              <h5>DIRECT DEBIT</h5>
              <p>
                Your direct debit has been setup with the following details:
              </p>
              <div className='table-responsive-lg'>
                <table className='table card__table mb-4'>
                  <tbody>
                    <tr>
                      <td>Repayment Frequency:</td>
                      <td>{props?.loan?.repaymentFrequency}</td>
                    </tr>
                    <tr>
                      <td>BSB:</td>
                      <td>{props?.payIdBSB}</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td>{props?.payIdBankAcct}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
          {props?.payIdActive == true && (
            <section className='col-6'>
              <h5>PAYID OR BANK DEPOSIT</h5>
              <p>
                Please deposit funds into the following PayID or bank account.
              </p>
              <div className='table-responsive-lg'>
                <table className='table card__table mb-4'>
                  <tbody>
                    <tr>
                      <td>PayID:</td>
                      <td>{props?.payIdEmail}</td>
                    </tr>
                    <tr>
                      <td>BSB:</td>
                      <td>{props?.payIdBSB}</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td>{props?.payIdBankAcct}</td>
                    </tr>
                    <tr>
                      <td>Payment Reference:</td>
                      <td>{props?.opportunityName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
          {props?.payIdActive == false && props?.countryCode === 'AU' && (
            <section className='col-6'>
              <h5>BANK DEPOSIT</h5>
              <p>Please deposit funds into the following account.</p>
              <div className='table-responsive-lg'>
                <table className='table card__table mb-4'>
                  <tbody>
                    <tr>
                      <td>Account Name:</td>
                      <td>Property Credit</td>
                    </tr>
                    <tr>
                      <td>BSB:</td>
                      <td>084-917</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td>850176774</td>
                    </tr>
                    <tr>
                      <td>Payment Reference:</td>
                      <td>{props?.opportunityName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
          {props?.payIdActive == false && props?.countryCode === 'NZ' && (
            <section className='col-6'>
              <h5>BANK DEPOSIT</h5>
              <p>
                Please deposit funds into the following account in New Zealand
                Dollars.
              </p>
              <div className='table-responsive-lg'>
                <table className='table card__table mb-4'>
                  <tbody>
                    <tr>
                      <td>Account Name:</td>
                      <td>Property Credit Pty Ltd</td>
                    </tr>
                    <tr>
                      <td>Account Number:</td>
                      <td>04-2024-0257870-00</td>
                    </tr>
                    <tr>
                      <td>Payment Reference:</td>
                      <td>{props?.opportunityName}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          )}
        </main>
      </div>
    </div>
  );
};

export default StatementAgentPdf;
