import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Components from './components/Components';
import ThankYou from './components/forms/ThankYou';
import OppDetails from './components/OppDetails';
import MainForm from './form/MainForm';
import LoanDetails from './components/LoanDetails';
import { BasiqCallback } from './components/basiq/BasiqPreConsentForm';

import ConfirmationPage from './components/forms/ConfirmationPage';
function App() {
  return (
    <div className='pc-body-wrapper d-flex flex-column'>
      <Routes>
        <Route path='/' element={<MainForm />} />
        <Route path='/components' element={<Components />} />
        <Route path='/application/:oppId' element={<MainForm />} />
        <Route path='/application/ThankYou' element={<ThankYou />} />
        <Route path='/application/ThankYou/:dataOwner' element={<ThankYou />} />
        <Route path='/application/oppDetails/:oppId' element={<OppDetails />} />
        <Route
          path='/application/loanDetails/:oppId'
          element={<LoanDetails />}
        />
        <Route path='/BasiqCallback' element={<BasiqCallback />} />
      </Routes>
    </div>
  );
}
export default App;
