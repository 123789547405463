import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DragAndDrop from '../DragAndDrop';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import { Select, Input } from 'semantic-ui-react';
import dateFormat from '../../utils/dateFormat';

const DocumentsUploader = (props) => {
  const [documentData, setDocumentData] = useState(null);
  const [category, setCategory] = useState(null);
  const [loading, setloading] = useState(false);
  const [reload, setReload] = useState(false);
  const navigate = useNavigate();
  const catArray = [
    'Proof of Income',
    'Bank Statements',
    'Proof of ID',
    'Proof of Address',
  ];

  useEffect(() => {
    OpportunityAPI.getDocumentCategory().then((res) => {
      console.log('res category', res);
      if (res.success === false) {
        navigate('/application/ThankYou');
        return;
      } else {
        setCategory(res);
      }
    });
  }, []);

  useEffect(() => {
    setloading(true);
    OpportunityAPI.getDocuments(props.oppId).then((res) => {
      console.log('res document', res);
      if (res.success === false) {
        navigate('/application/ThankYou');
        return;
      } else {
        setDocumentData(res);
        setloading(false);
      }
    });
  }, [reload]);
  const back = () => {
    props.prevStep();
  };
  const saveAndContinueNext = () => {
    setloading(true);
    if (props.oppId) {
      OpportunityAPI.getOpportunityStatus(props.oppId).then((res) => {
        if (res?.success === true) {
          //&& (res?.stage === 'Loan Offered' || res?.stage === 'Facility Offered'))) {
          props.nextStep();
        } else {
          navigate('/application/ThankYou');
        }
      });
    }
  };
  const updateDocument = (newData) => {
    const obj = {
      opportunityID: props.oppId,
      documents: [newData],
    };
    OpportunityAPI.updateDocument(obj).then((res) => {
      if (res.success === false) {
        navigate('/application/ThankYou');
        return;
      } else {
        setReload((pre) => !pre);
      }
    });
  };
  return (
    <React.Fragment>
      {loading && (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}

      <div className='row'>
        <div className='col-12'>
          <h5 className='mb-1 pre-heading'>Applying for loan</h5>
          <h1 className='mb-4 lh-1'>Upload Documents</h1>
          <p className='mb-4'>
            To complete your application, we need you to upload documents for
            each of the categories listed below.
          </p>
          <p className='mb-3'>
            Please upload as many of the following documents that you have
            available.
          </p>
          <div className=''>
            {documentData &&
              category &&
              documentData?.categories.map((c, index) => {
                return (
                  <div key={index + 'c'} className=' mb-4'>
                    <h4>
                      {c?.name}
                      {c?.name === 'Proof of ID' &&
                        ' (2 forms required. Front and back images of Driver Licence)'}
                    </h4>
                    <div className='p-3 border rounded'>
                      {c.documents.map((doc, ind) => {
                        return (
                          <div
                            key={ind + 'doc'}
                            className='position-relative ps-4 mb-2'
                          >
                            <div
                              className={`d-flex flex-column flex-md-row ms-3 justify-content-md-between 
                                                    ${
                                                      doc.status === 'Rejected'
                                                        ? 'file_reject'
                                                        : doc.status ===
                                                          'Approved'
                                                        ? 'file_submit'
                                                        : 'file_upload'
                                                    }
                                                    `}
                            >
                              <div>
                                <h6 className='m-0 text-break'>{doc.name}</h6>
                                <span className='file-text'>
                                  {doc.subCategory}
                                </span>
                                <span className='file-text'>
                                  {' . '}
                                  {doc.status}
                                </span>
                                <span className='file-text'>
                                  {' . '}
                                  {dateFormat(new Date(doc.createdDate))}
                                </span>
                              </div>

                              <div className='d-flex flex-column flex-sm-row align-items-sm-start justify-content-sm-between'>
                                {c?.name === 'Other Documents' ? (
                                  <Input
                                    className='me-2 mb-2'
                                    onBlur={(event) => {
                                      updateDocument({
                                        id: `${doc.id}`,
                                        fileDescription: `${event.target.value}`,
                                      });
                                    }}
                                    placeholder='Enter File description'
                                    defaultValue={doc.fileDescription}
                                  />
                                ) : null}
                                {catArray.includes(c.name) ? (
                                  <Select
                                    className='me-2 mb-2'
                                    placeholder='Select a type'
                                    defaultValue={doc?.subCategory}
                                    onChange={(event, data) => {
                                      updateDocument({
                                        id: `${doc.id}`,
                                        subCategory: `${data.value}`,
                                      });
                                    }}
                                    options={category?.categories
                                      .filter((cat) => cat.name === c.name)[0]
                                      ?.subCategory.map((item) => {
                                        return { text: item, value: item };
                                      })}
                                  />
                                ) : null}

                                <Select
                                  className='me-2 mb-2 float-right'
                                  placeholder='Select a contact'
                                  onChange={(event, data) => {
                                    updateDocument({
                                      id: `${doc.id}`,
                                      contact: `${data.value}`,
                                    });
                                  }}
                                  defaultValue={doc?.contact}
                                  options={props.opportunity?.contacts
                                    .filter((contact) => contact.isBorrower)
                                    .map((user) => {
                                      return {
                                        text: `${user.firstName} ${user.lastName}`,
                                        value: user.id,
                                      };
                                    })}
                                />
                              </div>
                            </div>
                            {doc.status === 'Rejected' ? (
                              <div className='file-error'>
                                <span className='error-span'>
                                  <b>Rejected Reason:</b>
                                </span>
                                <span>
                                  <i>{' ' + doc?.rejectedReason}</i>
                                </span>
                              </div>
                            ) : null}
                          </div>
                        );
                      })}
                      <DragAndDrop
                        id={c.name}
                        {...props}
                        subCategory={
                          category?.categories.filter(
                            (cat) => cat.name === c.name,
                          )[0]
                        }
                        setReload={setReload}
                      />
                    </div>
                  </div>
                );
              })}
          </div>

          <div className='d-flex align-items-center'>
            {props.currentStep !== 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinueNext}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DocumentsUploader;
