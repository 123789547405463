import React, { useState } from 'react';
import dateFormat from '../../utils/dateFormat';
import formatCurrency from '../../utils/formatCurrency';

export default function LoanOfferPage(props: any) {
  const {
    paymentScheduleList,
    loan,
    loanList,
    facilityDetails,
    propertyAddress,
    contacts,
  } = props.opportunity;
  const [asc, setAsc] = useState(true);
  const [paymentList, setPaymentList] = useState(paymentScheduleList);
  //console.log(props.opportunity);
  const back = () => {
    props.prevStep();
  };
  const saveAndContinue = () => {
    props.nextStep();
  };

  const handleSortTable = () => {
    setAsc((pre) => !pre);
    setPaymentList((pre: any) => pre.reverse());
  };

  let loanListDisplay = loanList?.filter(
    (loan: any) => loan.stage === 'Offered',
  );

  //console.log('loanListDisplay', loanListDisplay);

  const getProductDescription = (product_code: string) => {
    switch (product_code) {
      case 'VPA':
        return 'Advertising';
      case 'POP':
        return 'Pre-Sale Improvements';
      case 'ERC':
        return 'Equity Access';
      case 'GST':
        return 'GST Funding';
      default:
        return '';
    }
  };

  let fundingPurposeList = '';

  let fundingPurposeArr: any = [];

  if (loanListDisplay.length === 1) {
    fundingPurposeList = getProductDescription(loanListDisplay[0].product_code);
  } else if (loanListDisplay.length > 1) {
    loanListDisplay.forEach((loan: any) => {
      fundingPurposeArr.push(getProductDescription(loan.product_code));
    });

    fundingPurposeList = `${fundingPurposeArr.slice(0, -1).join(', ')} and ${
      fundingPurposeArr[fundingPurposeArr.length - 1]
    }`;
  }

  //console.log(fundingPurposeList);

  return (
    <React.Fragment>
      <div className='row'>
        {/* <div className='col-sm-10 offset-sm-1'> */}
        <div className='col-12'>
          <h1 className='mb-4 lh-1'>Application Approved</h1>
          <p className='mb-4'>
            Congratulations, your application for {fundingPurposeList} has been
            successful.
          </p>

          <h3 className='h3 mb-3'>Offer Details</h3>
          <div className='card mb-5'>
            <div className='card-body border-bottom bg-light'>
              <div className='row'>
                <div className='col-12 pb-2 pb-md-0'>
                  <h6 className='mb-0 pre-heading-h6'>
                    <i
                      className='bi bi-house-fill'
                      style={{ fontSize: '1.2rem', marginRight: '0.5rem' }}
                    ></i>
                    <span className='me-1'>{propertyAddress}</span>{' '}
                    <span className='badge rounded-pill bg-primary'>
                      {facilityDetails?.facilityContractStatusVerified}
                    </span>
                  </h6>
                </div>
              </div>
            </div>
            <div className='card-body border-bottom'>
              <div className='row'>
                <div className='col-12 pb-2 pb-md-0 mt-1'>
                  <h5 className='mb-0 pre-heading-h5'>
                    Applicant
                    {contacts?.filter((contact: any) => contact.isBorrower)
                      .length > 1 && 's'}
                  </h5>

                  <div className='card mb-4 mt-2 bg-light'>
                    {contacts
                      ?.filter((contact: any) => contact.isBorrower)
                      .map((con: any, index: number) => {
                        return (
                          <div
                            key={index + 'con'}
                            className='card-body border-bottom'
                          >
                            <div className='row'>
                              <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                <h6 className='mb-0 pre-heading-h6 font-normal'>
                                  Borrower
                                </h6>
                                <p className='p-font mb-0'>
                                  {con?.contactName}
                                </p>
                              </div>
                              <div className='col-sm-6'>
                                <h6 className='mb-0 pre-heading-h6 font-normal'>
                                  Borrower Email
                                </h6>
                                <p className='p-font mb-0'>{con?.email}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className='col-12 pb-2 pb-md-0 mt-1'>
                  <h5 className='mb-0 pre-heading-h5'>Equity Access</h5>
                  <div className='card mt-2 bg-light'>
                    <div className='card-body py-2'>
                      <div className='row py-1'>
                        <div className='col-6 col-md-3 pb-3 pb-md-0'>
                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                            Pre-Approved Limit
                          </h6>
                          <p className='p-font mb-0'>
                            {facilityDetails?.facilityLimitCurrent > 0
                              ? formatCurrency(
                                  facilityDetails?.facilityLimitCurrent,
                                )
                              : formatCurrency(facilityDetails?.facilityLimit)}
                          </p>
                        </div>
                        <div className='col-6 col-md-3 pb-3 pb-md-0'>
                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                            Amount Used
                          </h6>
                          <p className='p-font mb-0'>
                            {formatCurrency(
                              facilityDetails?.facilityUsedPending,
                            )}
                          </p>
                        </div>
                        <div className='col-6 col-md-3 pb-3 pb-md-0'>
                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                            Balance Available
                          </h6>
                          <p className='p-font mb-0'>
                            {formatCurrency(facilityDetails?.facilityAvailable)}
                          </p>
                        </div>

                        <div className='col-12 col-md-9 pb-2 pb-md-0 mt-2'>
                          <div className='p-font mb-0 bank-acc__note d-flex  flex-row'>
                            <i
                              className='bi bi-info-circle'
                              style={{
                                fontSize: '1.2rem',
                                marginRight: '0.5rem',
                              }}
                            ></i>
                            <div className='pt-1'>
                              <b>Access your equity</b> for advertising,
                              improvements, deposits and moving costs. <br />
                              <b>Further funding</b> may be available as your
                              sale progresses –{' '}
                              <span className='fst-italic'>
                                fees only apply for money you access.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-12 pb-0 pb-md-0 mt-4'>
                  <h5 className='mb-0 pre-heading-h5'>
                    Loan Approval
                    {loanListDisplay.length > 1 && 's'}
                  </h5>

                  {loanListDisplay.map((loan: any, index: number) => {
                    return (
                      <div className='card mt-2 bg-light'>
                        <div key={index + 'loan'} className='card-body py-2'>
                          <div className='row py-1'>
                            <div className='col-6 col-md-3 pb-2 pb-md-0'>
                              <h6 className='mb-0 pre-heading-h6 font-normal'>
                                {loan?.product_code === 'VPA' && 'Advertising'}
                                {loan?.product_code === 'POP' &&
                                  'Pre-Sale Improvements'}
                                {loan?.product_code === 'ERC' &&
                                  'Other Equity Release'}
                                {loan?.product_code === 'BDF' && 'Deposit'}
                              </h6>

                              <p className='p-font mb-0'>
                                {formatCurrency(loan?.amount_current)}
                              </p>
                            </div>

                            {loan?.paid_to_name && (
                              <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                <h6 className='mb-0 pre-heading-h6 font-normal'>
                                  Paid To
                                </h6>
                                <p className='p-font mb-0'>
                                  {loan?.paid_to_name}
                                </p>
                              </div>
                            )}

                            {loan?.establishment_fee && (
                              <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                <h6 className='mb-0 pre-heading-h6 font-normal'>
                                  Drawdown Fee
                                </h6>
                                <p className='p-font mb-0'>
                                  {formatCurrency(loan?.establishment_fee)}
                                </p>
                              </div>
                            )}
                            {loan?.monthly_fee_current && (
                              <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                <h6 className='mb-0 pre-heading-h6 font-normal'>
                                  Monthly Admin Fee
                                </h6>
                                <p className='p-font mb-0'>
                                  {formatCurrency(loan?.monthly_fee_current)}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className='col-12 pb-0 pb-md-0 mt-4'>
                  <h6 className='mb-0 pre-heading-h6 text-secondary'>
                    Additional Information
                  </h6>
                  <div className='card mt-2 bg-light'>
                    <div className='card-body py-2'>
                      <div className='row py-1'>
                        <div className='col-6 col-md-3 pb-2 pb-md-0'>
                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                            Payment Date
                          </h6>

                          <p className='p-font mb-0'>Within 1 business day</p>
                        </div>
                        <div className='col-6 col-md-3 pb-2 pb-md-0'>
                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                            Max. Loan Term
                          </h6>
                          <p className='p-font mb-0'>
                            {/* {loan?.payment_frequency_current !== 'Single' &&
                              loan?.instalmentsAmount && (
                                <>
                                  {formatCurrency(loan?.instalmentsAmount)}{' '}
                                  {loan?.payment_frequency_current}
                                  <br />
                                </>
                              )} */}
                            {loan?.loanTerm}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <h3 className='h3 mb-3'>Payment Schedule</h3>
          <div className='card mb-4'>
            <div className='card-body p-0'>
              <div className='table-responsive'>
                <table className='table mb-0'>
                  <thead className='bg-light'>
                    <tr className='custom-row'>
                      <td scope='col' className=''>
                        <span
                          className='cursor-pointer'
                          onClick={handleSortTable}
                        >
                          Date{' '}
                          {asc ? (
                            <i className='bi bi-arrow-down'></i>
                          ) : (
                            <i className='bi bi-arrow-up'></i>
                          )}
                        </span>
                      </td>
                      <td scope='col' className=''>
                        Type
                      </td>
                      <td scope='col' className=''>
                        Amount
                      </td>
                      <td scope='col' className=''>
                        Status
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList?.map((pay: any, ind: any) => {
                      return (
                        <tr key={ind + 'pay'}>
                          <td className=''>{dateFormat(new Date(pay.date))}</td>
                          <td className=''>{pay.name}</td>
                          <td className=''>${pay.amount}</td>
                          <td className=''>
                            <button className='btn btn-sm rounded-pill bg-light text-secondary py-0'>
                              {pay.status}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div> */}
          {/* <div className='d-flex align-items-center justify-content-end mb-4'>
            <button className='btn btn-primary-2' type='button'>
              Accept Terms
            </button>
          </div> */}
          <div className='d-flex align-items-center'>
            {props.currentStep != 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
