import React from "react";
import formatCurrency from '../../utils/formatCurrency';

export default function Facilities(props: any) {

	const { contacts,propertyAddress,facilityDetails } = props.opportunity;


	const back = () => {
		props.prevStep();
	};
	const saveAndContinue = () => {
		props.nextStep();
	};

	return (
    <React.Fragment>
      <div className='row'>
        <div className='col-12'>
          <h5 className='mb-1 pre-heading'>Applying for loan</h5>
          <h1 className='mb-4 lh-1'>Facility Offer</h1>
          <p className='mb-4'>
            Congratulations, we are pleased to be able to offer you a facility
            with the following details:
          </p>
          <div className='card mb-5'>
            <div className='card-body border-bottom'>
              <div className='row'>
                <div className='col-sm-4 pb-2 pb-sm-0'>
                  <h6 className='mb-0 pre-heading-h6'> Facility Limit</h6>
                  <p className='p-font mb-0'>
                    {formatCurrency(facilityDetails?.facilityOffered)}
                  </p>
                </div>
                <div className='col-sm-8'>
                  <h6 className='mb-0 pre-heading-h6'>Property Address</h6>
                  <p className='p-font mb-0'>{propertyAddress}</p>
                </div>
              </div>
            </div>

            {contacts
              ?.filter((contact: any) => contact.isBorrower)
              .map((con: any, index: number) => {
                return (
                  <div key={index + 'f'} className='card-body border-bottom'>
                    <div className='row'>
                      <div className='col-sm-4 pb-2 pb-sm-0'>
                        <h6 className='mb-0 pre-heading-h6'>Borrower</h6>
                        <p className='p-font mb-0'>{`${con?.firstName} ${con?.lastName}`}</p>
                      </div>
                      <div className='col-sm-8'>
                        <h6 className='mb-0 pre-heading-h6'>Borrower Email</h6>
                        <p className='p-font mb-0'>{con?.email}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className='d-flex align-items-center'>
            {props.currentStep != 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
