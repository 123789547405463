import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";

export default function AccountVerification(props) {
	const $=window.$;

	let navigate = useNavigate();

	const link = props.currentContact?.bankStatementsLink;
	console.log('creditsense link', link);

	const paramsData = {};

	if (link != null) {
		const words = link.split('?');
		const arrayOfkeyValuePairs = words[1].split('&');
		for (var i=0; i <arrayOfkeyValuePairs.length; i++) {
			const keyValuePair = arrayOfkeyValuePairs[i].split('=');
			paramsData[keyValuePair[0]] = keyValuePair[1];
		}
		paramsData.cssOverride = 'portal.property.credit/css/creditsense.css';
	}
	const [isLoaded, setIsLoaded ] = useState(false);
	const back = () => {
		props.setAccountVerificationShow(false);
		navigate("/application/"+props.oppId);

	};

	const finish = () => {
		console.log('creditSense.finish');
		props.setAccountVerificationShow(false);
		navigate("/application/"+props.oppId);
		props.currentContact.bankStatements = "completed";
		props.setCurrentContact(props.currentContact);
	}

	const hideLoadingBar = () => {
		setIsLoaded(true);
	}

	useEffect(()=>{

		function logMsg(message) {
			if (typeof console == "object") console.log(message);
			else alert(message);
		  }
		  $("creditSenseIFrame").ready(function() {
			$.CreditSense.Iframe({
			  elementSelector: "#creditSenseIFrame",
			  enableDynamicHeight: true,
			  params: paramsData,
				callback: function(response, data) {
					switch (response) {
						case "99": 
							logMsg('Bank details collected successfully');
							finish();
							break;
						case "100": 
							logMsg('Application completed successfully');
							break;
					}
				}
			});
		  });
	},[])
	

	return (
		<React.Fragment>
			{!isLoaded &&
				<h2> Loading ... </h2>
			}
			
			{link && 
				(
					<iframe id="creditSenseIFrame" src={link} style={{height: '580px', width:'98%', border: 'none'}} 
					onLoad={hideLoadingBar} 
					title="Credit Sense Account Verification Frame" 
					loading='lazy' ></iframe>
				)
			}
			<button className="btn btn-outline-secondary me-3" onClick={back}>
				Cancel
			</button>
		</React.Fragment>
	);
}	
