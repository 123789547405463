import React, { useState, useEffect } from 'react';
import ContactDetails from '../contact-components/ContactDetails';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import { useNavigate } from 'react-router-dom';
import { XMarkIcon, CheckIcon } from '@heroicons/react/24/solid';
import { ReactComponent as IconQuestion } from '../../assets/icon/question.svg';

type ProductCodes =
  | OwnerPersonalProducts
  | OwnerPersonalInvestmentProducts
  | AgentProducts
  | OwnerPersonalProducts
  | AgencyProducts
  | ServiceProviderProducts
  | AuctioneerProducts
  | PersonalLoan;
type OwnerPersonalProducts = 'GST' | 'BDF' | 'VPAPOP' | 'ERC';
type OwnerPersonalInvestmentProducts = 'PEF' | 'TAB' | 'TAB-DRAW';
type AgentProducts = 'CF-Agent' | 'LC-Agent' | 'APA-Agent';
type AgencyProducts = 'CF-Agency' | 'LC-Agency' | 'APA-Agency';
type ServiceProviderProducts = 'INV';
type AuctioneerProducts = 'AUC';
type PersonalLoan = 'PL';

type ProductCategories =
  | 'OwnerPersonalProducts'
  | 'OwnerPersonalInvestmentProducts'
  | 'AgentProducts'
  | 'AgencyProducts'
  | 'ServiceProviderProducts'
  | 'AuctioneerProducts'
  | 'PersonalLoan';

const productIdToCategory: { [Key in ProductCodes]: ProductCategories } = {
  GST: 'OwnerPersonalProducts',
  BDF: 'OwnerPersonalProducts',
  VPAPOP: 'OwnerPersonalProducts',
  ERC: 'OwnerPersonalProducts',
  PEF: 'OwnerPersonalInvestmentProducts',
  TAB: 'OwnerPersonalInvestmentProducts',
  'TAB-DRAW': 'OwnerPersonalInvestmentProducts',
  'CF-Agent': 'AgentProducts',
  'LC-Agent': 'AgentProducts',
  'APA-Agent': 'AgentProducts',
  'CF-Agency': 'AgencyProducts',
  'LC-Agency': 'AgencyProducts',
  'APA-Agency': 'AgencyProducts',
  INV: 'ServiceProviderProducts',
  AUC: 'AuctioneerProducts',
  PL: 'PersonalLoan',
};

export const companyTypes = [
  'Property Owner',
  'Agency',
  'Agent Entity',
  'Vendor Lawyer Firm',
  'Buyer Lawyer Firm',
  'Property Management Agency',
  'Service Provider',
  'Auctioneer Entity',
] as const;

export const individualTypes = [
  'Property Owner',
  'Agent',
  'Property Manager',
  'Office Admin',
  'Broker',
  'Third Party',
  'Vendor Lawyer',
  'Buyer Lawyer',
  'Power of Attorney',
  'Principal',
  'Service Provider Contact',
  'Auctioneer',
] as const;

type IndividualType = typeof individualTypes[number];
type CompanyType = typeof companyTypes[number];

// each product code has required individuals and companies
const productCodeRequiredEntitiesMapping: {
  [TKey in ProductCategories]: {
    options: {
      individuals: Readonly<IndividualType[]>;
      companies: Readonly<CompanyType[]>;
    };
    required: {
      individuals: Readonly<(IndividualType | IndividualType[])[]>;
      companies: Readonly<(CompanyType | CompanyType[])[]>;
    };
    applicantEntities: (IndividualType | CompanyType)[];
  };
} = {
  OwnerPersonalProducts: {
    options: {
      individuals: [
        'Property Owner',
        'Office Admin',
        'Agent',
        'Broker',
        'Principal',
        'Third Party',
        'Vendor Lawyer',
        'Buyer Lawyer',
        'Power of Attorney',
      ],
      companies: [
        'Property Owner',
        'Agency',
        'Agent Entity',
        'Vendor Lawyer Firm',
        'Buyer Lawyer Firm',
      ],
    },
    required: {
      individuals: ['Property Owner', ['Agent', 'Principal']],
      companies: [],
    },
    applicantEntities: ['Property Owner'],
  },
  OwnerPersonalInvestmentProducts: {
    options: {
      individuals: [
        'Property Owner',
        'Property Manager',
        'Office Admin',
        'Agent',
        'Third Party',
        'Power of Attorney',
      ],

      companies: [
        'Property Management Agency',
        'Agent Entity',
        'Vendor Lawyer Firm',
        'Buyer Lawyer Firm',
        'Service Provider',
      ],
    },
    required: {
      companies: ['Property Management Agency'],
      individuals: ['Property Owner', 'Property Manager'],
    },
    applicantEntities: ['Property Owner'],
  },
  AgentProducts: {
    options: {
      individuals: [
        'Agent',
        'Property Owner',
        'Office Admin',
        'Broker',
        'Principal',
        'Third Party',
        'Vendor Lawyer',
        'Buyer Lawyer',
        'Power of Attorney',
      ],
      companies: [
        'Agency',
        'Agent Entity',
        'Vendor Lawyer Firm',
        'Buyer Lawyer Firm',
      ],
    },
    required: {
      individuals: [['Agent', 'Principal']],
      companies: [],
    },
    applicantEntities: ['Agent', 'Agent Entity'],
  },
  AgencyProducts: {
    options: {
      individuals: [
        'Property Owner',
        'Office Admin',
        'Agent',
        'Broker',
        'Principal',
        'Third Party',
        'Vendor Lawyer',
        'Buyer Lawyer',
        'Power of Attorney',
      ],
      companies: [
        'Agency',
        'Agent Entity',
        'Vendor Lawyer Firm',
        'Buyer Lawyer Firm',
      ],
    },
    required: {
      individuals: [],
      companies: ['Agency'],
    },
    applicantEntities: ['Agency'],
  },
  ServiceProviderProducts: {
    options: {
      individuals: [
        'Service Provider Contact',
        'Property Manager',
        'Property Owner',
      ],
      companies: ['Service Provider', 'Property Management Agency'],
    },
    required: {
      individuals: ['Service Provider Contact'],
      companies: ['Service Provider'],
    },
    applicantEntities: ['Service Provider', 'Service Provider Contact'],
  },
  AuctioneerProducts: {
    options: {
      individuals: ['Auctioneer', 'Property Owner'],
      companies: ['Auctioneer Entity', 'Agency'],
    },
    required: {
      individuals: ['Auctioneer'],
      companies: ['Auctioneer Entity'],
    },
    applicantEntities: ['Auctioneer', 'Agent Entity'],
  },
  PersonalLoan: {
    options: {
      individuals: ['Property Owner', 'Vendor Lawyer'],
      companies: [],
    },
    required: {
      individuals: ['Property Owner'],
      companies: [],
    },
    applicantEntities: ['Property Owner'],
  },
};

// gets the required companies and individuals for the product code
const getRequiredEntities = (
  productCode: ProductCodes,
  opportunityContacts: [{ entityType: string }],
) => {
  const requiredForProductCode =
    productCodeRequiredEntitiesMapping[productIdToCategory[productCode]]
      ?.required;

  // find required companies/individuals which are not in opportunityContacts
  const requiredCompanies = requiredForProductCode.companies.filter(
    (company) => {
      if (typeof company === 'object') {
        // is array of OR types eg ["Principal", "Agent"]
        // check if a required contact is found
        return !Boolean(
          opportunityContacts.find((contact) =>
            company.find((c) => contact.entityType === c),
          ),
        );
      }

      // if required contact found, don't require
      return !Boolean(
        opportunityContacts.find((contact) => contact.entityType === company),
      );
    },
  );
  const requiredIndividuals = requiredForProductCode.individuals.filter(
    (individual) => {
      if (typeof individual === 'object') {
        // is array of OR types eg ["Principal", "Agent"]
        // check if a required contact is found
        return !Boolean(
          opportunityContacts.find((contact) =>
            individual.find((c) => contact.entityType === c),
          ),
        );
      }

      // if required contact found, don't require
      return !Boolean(
        opportunityContacts.find(
          (contact) => contact.entityType === individual,
        ),
      );
    },
  );
  //   console.log('required', requiredCompanies, requiredIndividuals);

  return {
    individuals: requiredIndividuals,
    companies: requiredCompanies,
  };
};

const ContactComponent = (props: any) => {
  const [addNewIndividual, setAddNewIndividual] = useState(false);
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [oppContactData, setOppContactData] = useState<any>(null);

  const [entitiesToAdd, setEntitiesToAdd] = useState<
    undefined | ReturnType<typeof getRequiredEntities>
  >();
  const [requiredEntities, setRequiredEntites] = useState<
    | undefined
    | {
        individuals: Readonly<(IndividualType | IndividualType[])[]>;
        companies: Readonly<(CompanyType | CompanyType[])[]>;
      }
  >();
  const [options, setOptions] = useState<
    | undefined
    | {
        individuals: Readonly<IndividualType[]>;
        companies: Readonly<CompanyType[]>;
      }
  >(undefined);

  const [applicantEntities, setApplicantEntities] = useState<
    undefined | (IndividualType | CompanyType)[]
  >(undefined);

  // cannot go next until the required entites have been entered
  const allEntitiesPresent = (function () {
    if (!entitiesToAdd) return false;
    return (
      entitiesToAdd.companies.length === 0 &&
      entitiesToAdd.individuals.length === 0
    );
  })();

  const nextIsDisabled = !allEntitiesPresent;

  const [oppContacts, setOppContacts] = useState<null | {
    oppContacts: any[];
  }>(null);
  const navigate = useNavigate();

  const handleAddNewIndividual = () => {
    setAddNewIndividual(true);
  };

  const handleAddNewCompany = () => {
    setAddNewCompany(true);
  };

  const handleEdit = (id: string, index: number) => {
    console.log('handleEdit', id, oppContacts?.oppContacts[index]);
    if (
      oppContacts?.oppContacts[index].entityType === 'Agency' ||
      oppContacts?.oppContacts[index].type === 'Company'
    ) {
      setEdit(false);
    } else {
      setEdit(true);
    }
    setOppContactData(oppContacts?.oppContacts[index]);
  };

  const back = () => {
    props.prevStep();
  };

  const saveAndContinue = () => {
    props.nextStep();
  };

  const getProductCode = () => {
    const productCode = props.opportunity?.opportunityName?.split('-')[0];
    console.log(productCode);
    if (Object.keys(productIdToCategory).includes(productCode)) {
      return productCode as keyof typeof productIdToCategory;
    } else if (['CF', 'LC', 'APA'].includes(productCode)) {
      // is an agency if there is not an agent agency
      const isAgency =
        !props.opportunity?.agentAgency ||
        props.opportunity?.agentAgency === '';
      return `${productCode}-${
        isAgency ? 'Agency' : 'Agent'
      }` as keyof typeof productIdToCategory;
    }
    return undefined;
  };

  useEffect(() => {
    OpportunityAPI.getOppContact(props.oppId).then((res) => {
      // console.log("res oppContact", res)
      if (res.success === false) {
        navigate('/application/ThankYou');
        return;
      } else {
        setOppContacts(res);

        const productCode = getProductCode();
        // console.log('Product code', productCode);
        // get required options based on product ID, or have all options
        const options = productCode
          ? productCodeRequiredEntitiesMapping[productIdToCategory[productCode]]
              .options
          : {
              individuals: individualTypes,
              companies: companyTypes,
            };

        setOptions(options);
        if (productCode)
          setApplicantEntities(
            productCodeRequiredEntitiesMapping[productIdToCategory[productCode]]
              .applicantEntities,
          );

        // no required fields if there is an invalid property code
        const required = productCode
          ? getRequiredEntities(productCode, res.oppContacts)
          : { individuals: [], companies: [] };
        setEntitiesToAdd(required);

        if (productCode) {
          setRequiredEntites(
            productCodeRequiredEntitiesMapping[productIdToCategory[productCode]]
              .required,
          );
        }

        // console.log('opp contacts', res.data);
      }
    });
  }, [isSaved]);

  return (
    <div>
      {addNewIndividual ? (
        <ContactDetails
          {...props}
          setSave={setAddNewIndividual}
          setIsSaved={setIsSaved}
          identity='Individual'
          options={options}
          applicantEntities={applicantEntities}
        />
      ) : addNewCompany ? (
        <ContactDetails
          {...props}
          setSave={setAddNewCompany}
          setIsSaved={setIsSaved}
          identity='Company'
          options={options}
          applicantEntities={applicantEntities}
        />
      ) : edit && oppContactData ? (
        <ContactDetails
          {...props}
          oppContactData={oppContactData}
          setSave={setEdit}
          setIsSaved={setIsSaved}
          identity={
            oppContactData && oppContactData.type
              ? oppContactData.type
              : 'Individual'
          }
          options={options}
          applicantEntities={applicantEntities}
        />
      ) : (
        <div className='col-12'>
          <h5 className='mb-2 pre-heading'>Applying for loan</h5>
          <h2 className='mb-4 lh-1' style={{ color: '#00467e' }}>
            Add People &amp; Companies
          </h2>

          {/*Add people and company*/}
          <div className='mb-3'>
            <div>
              {(
                [
                  { entityType: 'individuals', title: 'People' },
                  { entityType: 'companies', title: 'Companies' },
                ] as const
              ).map(({ entityType, title }) => {
                return requiredEntities &&
                  requiredEntities[entityType].length ? (
                  <>
                    {!allEntitiesPresent && (
                      <p className='mb-0'>Please add {entityType} details:</p>
                    )}

                    <div className='list-group row px-0 py-2 gy-3 gx-3 mb-3'>
                      {requiredEntities &&
                        requiredEntities[entityType].map((e, i) => {
                          let content: string;
                          let isAdded: boolean;
                          if (Array.isArray(e)) {
                            content = e.join(' or ');
                            isAdded = !Boolean(
                              entitiesToAdd &&
                                entitiesToAdd[entityType].includes(e as any),
                            );
                          } else {
                            content = e;
                            isAdded = !Boolean(
                              entitiesToAdd &&
                                entitiesToAdd[entityType]?.includes(e as any),
                            );
                          }
                          console.log('content' + content);
                          if (!isAdded) {
                            return (
                              <div
                                className='col col-12 col-md-6'
                                key={content}
                              >
                                <div
                                  key={content}
                                  className='bank-st document-row contact-add'
                                  onClick={
                                    entityType === 'individuals'
                                      ? handleAddNewIndividual
                                      : handleAddNewCompany
                                  }
                                >
                                  <div className='bank-st-wrap bank-st-wrap-before'>
                                    <IconQuestion className='bank-st-icon' />
                                    <p className='bank-st-name'>{content}</p>
                                  </div>

                                  <svg
                                    xmlns='http://www.w3.org/2000/svg'
                                    viewBox='0 0 24 24'
                                    fill='#fbb941'
                                    /**fill='#5db4f7'*/
                                    className='w-5 h-5 me-2'
                                  >
                                    <path
                                      fillRule='evenodd'
                                      d='M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zM12.75 9a.75.75 0 00-1.5 0v2.25H9a.75.75 0 000 1.5h2.25V15a.75.75 0 001.5 0v-2.25H15a.75.75 0 000-1.5h-2.25V9z'
                                      clipRule='evenodd'
                                    />
                                  </svg>
                                </div>
                              </div>
                            );
                          }
                        })}

                      {/* {oppContacts &&
                        oppContacts.oppContacts.map((con, index) => {
                          return (
                            
                          );
                        })} */}
                    </div>
                  </>
                ) : null;
              })}
            </div>
            {/**  <p className='mb-2'>
             <strong>
                Please add everyone connected to this application.
              </strong>
            </p>*/}
            {/*Button */}
          </div>

          {/*Completed people and company*/}
          {oppContacts && oppContacts.oppContacts.length > 0 ? (
            <>
              <h4 className='text-orange-500 mt-4'>Thank you for supplying.</h4>
              <p className='pre-heading'>
                Please review these people &amp; companies and{' '}
                <b>supply any other property owners</b>.
              </p>
              <div className='card p-3 py-2 mt-2 mb-3'>
                <div className='list-group row px-0 py-2 gy-3 gx-3'>
                  {oppContacts &&
                    oppContacts.oppContacts.map((con, index) => {
                      return (
                        <div className='col col-12 col-md-6' key={con?.id}>
                          <div
                            key={con.id}
                            className='bank-st document-row'
                            onClick={() => handleEdit(con.id, index)}
                          >
                            {con.type === 'Company' ? (
                              <div
                                className='bank-st-wrap bank-st-wrap-before'
                                data-initials={
                                  `${con?.entityName}`.charAt(0).toUpperCase() +
                                  `${con?.entityName}`
                                    .charAt(
                                      `${con?.entityName}`.indexOf(' ') + 1,
                                    )
                                    .toUpperCase()
                                }
                              >
                                <p className='bank-st-name'>{`${con?.entityName}`}</p>
                                <p className='bank-stmt-name'>{`${
                                  con?.entityType
                                } ${
                                  con.entityType && con.isApplicant ? '&' : ''
                                } ${
                                  con?.isApplicant === true ? 'APPLICANT' : ''
                                }`}</p>
                              </div>
                            ) : (
                              <div
                                className='bank-st-wrap bank-st-wrap-before'
                                data-initials={
                                  `${con?.firstName || ''}`
                                    .charAt(0)
                                    .toUpperCase() +
                                  `${con?.lastName || ''}`
                                    .charAt(0)
                                    .toUpperCase()
                                }
                              >
                                <p className='bank-st-name'>
                                  {con.firstName && con.lastName
                                    ? `${con?.firstName} ${con?.lastName}`
                                    : con?.entityName}
                                </p>
                                <p className='bank-stmt-name'>{`${
                                  con?.entityType
                                } ${
                                  con.entityType && con.isApplicant ? '&' : ''
                                } ${
                                  con?.isApplicant === true ? 'APPLICANT' : ''
                                }`}</p>
                              </div>
                            )}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              viewBox='0 0 24 24'
                              fill='currentColor'
                              className='w-5 h-5 me-2'
                            >
                              <path d='M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32L19.513 8.2z' />
                            </svg>
                          </div>
                        </div>
                      );
                    })}
                  <div
                    className='d-flex align-items-center'
                    style={{ backgroundColor: '#fff' }}
                  >
                    <button
                      type='submit'
                      className='flex align-items-center btn btn-outline-primary me-3 '
                      onClick={handleAddNewIndividual}
                      id='submitBtn'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        className='w-5 h-5 mr-2'
                      >
                        <path
                          fillRule='evenodd'
                          d='M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z'
                          clipRule='evenodd'
                        />
                      </svg>
                      <span>Another Person</span>
                    </button>
                    <button
                      type='submit'
                      className='flex align-items-center btn btn-outline-primary me-3'
                      onClick={handleAddNewCompany}
                      id='submitBtn'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 24 24'
                        fill='currentColor'
                        className='w-5 h-5 mr-2'
                      >
                        <path
                          fillRule='evenodd'
                          d='M12 3.75a.75.75 0 01.75.75v6.75h6.75a.75.75 0 010 1.5h-6.75v6.75a.75.75 0 01-1.5 0v-6.75H4.5a.75.75 0 010-1.5h6.75V4.5a.75.75 0 01.75-.75z'
                          clipRule='evenodd'
                        />
                      </svg>

                      <span>Another Company</span>
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {/* <pre>{JSON.stringify(entitiesToAdd, null, 2)}</pre> */}

          {/*Save and Next- button*/}
          <div className='d-flex align-items-center mt-5'>
            {props.currentStep !== 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
              disabled={nextIsDisabled}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactComponent;
