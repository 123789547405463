import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Footer from '../Footer';
import NavigationBar from '../NavigationBar';

export default function ThankYou(props: any) {
  const { state } = useLocation();

  const errorMessage = (state as any)?.error?.message as string | undefined;
  const { dataOwner } = useParams();

  if (errorMessage) console.log('Error', errorMessage);

  return (
    <React.Fragment>
      {!props.opportunity && <NavigationBar dataOwner={dataOwner} />}
      <main className='pc-page-content-wrapper flex-grow-1'>
        <div className='container-md pb-5'>
          <div className='row'>
            <div className='col-lg-10 offset-lg-1'>
              <div className='body'>
                {/* <img className="d-sm-none mb-3 pc-god-circle" src={greekGodCircleCrop} alt="Greek god character illustration"/> */}

                <h1 className='h4'>Thank You!</h1>
                <p>
                  We'll continue to process your application and get back in
                  touch with you shortly.
                </p>

                {props.opportunity && (
                  <a
                    className='btn btn-primary'
                    href={props.opportunity.returnUrl}
                  >
                    Continue
                  </a>
                )}
                {!props.opportunity && dataOwner === 'Tapi' && (
                  <a className='btn btn-primary' href='https://tapi.app/'>
                    Continue
                  </a>
                )}
                {!props.opportunity && dataOwner !== 'Tapi' && (
                  <a
                    className='btn btn-primary'
                    href='https://www.property.credit'
                  >
                    Continue
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      {dataOwner === 'Tapi' ? (
        <Footer countryCode={'NZ'} />
      ) : (
        <Footer countryCode={'AU'} />
      )}
    </React.Fragment>
  );
}
