import React, { useEffect } from 'react';
import { Select, Input, Form, Checkbox } from 'semantic-ui-react';

const Person = (props) => {
  const { register, errors, unregister, oppContactData, setValue, borrower } =
    props;
  useEffect(() => {
    unregister('entity');
    if (oppContactData && oppContactData.id) {
      setValue('id', oppContactData.id);
    }
  }, []);

  return (
    <div>
      <div className='card-body border-bottom'>
        <div className='row'>
          <div className='col-sm-4 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>First Name</p>
            <Form.Field>
              <input
                type={'text'}
                {...register('firstName', {
                  required: true,
                  value: oppContactData ? oppContactData.firstName : '',
                })}
              />
            </Form.Field>
            {errors?.firstName && (
              <p className='text-danger'>This field is required.</p>
            )}
          </div>
          <div className='col-sm-4 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>Middle Name</p>
            <Form.Field>
              <input
                {...register('middleName', {
                  value: oppContactData ? oppContactData.middleName : '',
                })}
                type={'text'}
              />
            </Form.Field>
          </div>
          <div className='col-sm-4 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>Last Name</p>
            <Form.Field>
              <input
                {...register('lastName', {
                  required: true,
                  value: oppContactData ? oppContactData.lastName : '',
                })}
                type={'text'}
              />
            </Form.Field>
            {errors?.lastName && (
              <p className='text-danger'>This field is required.</p>
            )}
          </div>
        </div>
      </div>

      <div className='card-body border-bottom'>
        <div className='row'>
          <div className='col-sm-6 pb-2'>
            <p className='mb-1 pre-heading-h6'>Email</p>
            <Form.Field>
              <input
                style={{ width: '100%' }}
                {...register('email', {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.',
                  },

                  value: oppContactData ? oppContactData.email : '',
                })}
                type={'email'}
              />
            </Form.Field>
            {errors?.email && (
              <p className='text-danger'>
                {errors.email.type === 'required'
                  ? 'This field is required.'
                  : errors.email.message}
              </p>
            )}
          </div>
          <div className='col-sm-6 pb-2'>
            <p className='mb-1 pre-heading-h6'>Mobile</p>
            <Form.Field>
              <input
                {...register('mobile', {
                  value: oppContactData ? oppContactData.mobile : '',
                  pattern: {
                    value: /^\+?[0-9\s*]+$/,
                    message: 'Please enter a valid phone number.',
                  },
                  required: true,
                })}
                type={'tel'}
              />
            </Form.Field>
            {errors?.mobile && (
              <p className='text-danger'>
                {errors.mobile.type === 'required'
                  ? 'This field is required.'
                  : errors.mobile.message}
              </p>
            )}
          </div>
        </div>
      </div>
      {borrower && (
        <div className='card-body border-bottom'>
          <div className='row'>
            <div className='col-sm-6 pb-2'>
              <p className='mb-1 pre-heading-h6'>Date of birth</p>
              <Form.Field>
                <input
                  style={{ width: '100%' }}
                  {...register('dateOfBirth', {
                    required: true,
                    value: oppContactData ? oppContactData.dateOfBirth : null,
                  })}
                  type={'date'}
                />
              </Form.Field>
              {errors?.dateOfBirth && (
                <p className='text-danger'>This field is required.</p>
              )}
            </div>

            <div className='col-sm-6 pb-2 d-flex align-items-center pt-4'>
              <div className='form-group form-check'>
                <input
                  type='checkbox'
                  className='form-check-input'
                  {...register('ausResident', {})}
                  id='confirmation'
                  defaultChecked={oppContactData && oppContactData.ausResident}
                />
                <label className='form-check-label'>
                  {props?.opportunity.countryCode === 'AU'
                    ? 'I am an Australian Resident or Citizen.'
                    : 'I am an New Zealand Resident or Citizen.'}
                </label>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Person;
