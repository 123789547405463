import ReactHtmlParser from 'html-react-parser';

const ModalView = (props: any) => {
  return (
    <div
      className='position-fixed top-0 start-0 end-0 bottom-0 bg-secondary bg-opacity-25 d-flex justify-content-center align-items-center'
      style={{ zIndex: 1 }}
    >
      <div className='card flex flex-col mb-4 modal-view-card h-75 p-4'>
        <h4 className='text-center'>{props.title}</h4>
        <div className='d-flex flex-column justify-content-center align-items-center flex-grow min-h-0'>
          <div className='mb-4 overflow-auto h-full p-sm-5 w-100 contract-scrollbar'>
            {ReactHtmlParser(props.content)}
          </div>
          <div className='flex w-full gap-x-2 justify-evenly'>
            <button
              type='button'
              className='btn btn-lg w-100 border border-dark'
              onClick={props.close}
            >
              Close
            </button>
            {!props.isEnd && (
              <button
                type='button'
                className='btn btn-lg w-100 border border-dark'
                onClick={props.next}
              >
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalView;
