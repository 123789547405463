export default function (value) {

    let monthNames =["Jan","Feb","Mar","Apr",
                      "May","Jun","Jul","Aug",
                      "Sep", "Oct","Nov","Dec"];
    
    let day = value.getDate();
    if (day < 10) {
        day = '0' + day;
      }
    let monthIndex = value.getMonth();
    let monthName = monthNames[monthIndex];
    
    let year = value.getFullYear();
    
    return `${day} ${monthName} ${year}`;  
}