import React, { useState, useEffect } from 'react';
import { Input } from 'semantic-ui-react';
import StatementPdf from './StatementPdf';
import StatementAgentPdf from './StatementAgentPdf';

const StatementModal = (props) => {
  const [isDisable, setIsDisable] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [loading, setloading] = useState(false);
  const [inputDate, setInputDate] = useState(props.today);
  const [agencyPDF, setAgencyPDF] = useState(false);

  useEffect(() => {
    if (showTable) {
      setShowTable(false);
    }
  }, [inputDate]);

  const checkForAgencyAccount = (paymentList) => {
    if (!agencyPDF) {
      for (const payment of paymentList) {
        if (payment.agentAccount) {
          setAgencyPDF(true);
          break;
        }
      }
    }
  };

  useEffect(() => {
    if (props.paymentScheduleList) {
      checkForAgencyAccount(props.paymentScheduleList);
    }

    if (!agencyPDF && props.paymentHistoryList) {
      checkForAgencyAccount(props.paymentHistoryList);
    }
  }, []);

  const handleSubmit = () => {
    setShowTable(true);
    setTimeout(() => {
      setloading(true);
      const html = document.getElementById('Print_Pdf')?.innerHTML;
      if (html) {
        fetch(process.env.REACT_APP_PDFCONVERTER_API_URL, {
          method: 'post',
          body: html,
        })
          .then((response) => {
            if (response) {
              response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download =
                  props.opportunityName + '-Statement-' + inputDate + '.pdf';
                alink.click();
                alink.remove();
              });
            }
            setloading(false);
          })
          .catch((err) => {
            console.log({ err });
            setloading(false);
          });
      }
    }, 1000);
  };
  const handleSubmitAgency = () => {
    setShowTable(true);
    setTimeout(() => {
      setloading(true);
      const html = document.getElementById('Print_Agency_Pdf')?.innerHTML;
      if (html) {
        fetch(process.env.REACT_APP_PDFCONVERTER_API_URL, {
          method: 'post',
          body: html,
        })
          .then((response) => {
            if (response) {
              response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download =
                  props.opportunityName +
                  '-Agency-Statement-' +
                  inputDate +
                  '.pdf';
                alink.click();
                alink.remove();
              });
            }
            setloading(false);
          })
          .catch((err) => {
            console.log({ err });
            setloading(false);
          });
      }
    }, 1000);
  };
  const closeModal = () => {
    props.setPrint(false);
  };
  return (
    <>
      <div
        className='modal fade show'
        style={{ display: 'block', background: '#00000077' }}
      >
        <div className='modal-dialog modal-fullscreen-sm-down modal-xl'>
          <div className='modal-content'>
            {loading && (
              <div
                className='position-fixed top-0 start-0 w-100 h-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
                style={{ zIndex: 100 }}
              >
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
            <div className='modal-header'>
              <h3 className='h3 modal-title'>Print Statement</h3>
              <button
                type='button'
                className='btn-close'
                onClick={closeModal}
              ></button>
            </div>
            <div className='modal-body'>
              <h5 className=''>Please select a date for the statement</h5>
              <div className='mb-4'>
                <Input
                  className='w-50'
                  type='date'
                  onChange={(e) => setInputDate(e.target.value)}
                  name='selectedDate'
                  defaultValue={props.today}
                />
              </div>

              <div className='d-flex align-items-center pb-2 mb-4'>
                <button
                  id='submitWithdraw'
                  type='submit'
                  className='btn btn-primary me-2'
                  disabled={inputDate ? false : true}
                  onClick={handleSubmit}
                >
                  Borrower Statement
                </button>
                {agencyPDF && (
                  <button
                    id='submitWithdraw'
                    type='submit'
                    className='btn btn-primary me-2'
                    disabled={inputDate ? false : true}
                    onClick={handleSubmitAgency}
                  >
                    Agency Statement
                  </button>
                )}

                <button
                  type='button'
                  className='btn fw-normal'
                  onClick={closeModal}
                  text
                >
                  Cancel
                </button>
              </div>
              {/* {showTable && ( */}
              <StatementAgentPdf
                {...props}
                inputDate={inputDate}
                setShowTable={true}
                // {setShowTable}
              />
              <StatementPdf
                {...props}
                inputDate={inputDate}
                setShowTable={true}
                // {setShowTable}
              />
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatementModal;
