import React, { useState } from 'react';
import BasiqPreConsentForm from '../basiq/BasiqPreConsentForm';
import { contact } from '../../models/contact.model';
import CreditSenseFrame from '../creditsense/CreditSenseFrame';

export default function BankStatements(props: any) {
  const {
    accountVerificationShow,
    setAccountVerificationShow,
    currentContact,
  } = props;
  const [client, setClient] = useState<contact | undefined>(currentContact);

  const canContinueNext =
    props.currentContact.bankStatementStatus !== 'Required';

  const back = () => {
    props.prevStep();
  };

  const skipAndContinue = async () => {
    props.nextStep();
  };

  const saveAndContinue = async () => {
    props.nextStep();
  };

  const showAccountVerificationForContact = (currentContact: contact) => {
    props.setCurrentContact(currentContact);
    setAccountVerificationShow(true);
  };
  return (
    <React.Fragment>
      {accountVerificationShow && client?.bankStatementsProvider === 'basiq' && (
        <React.Fragment>
          <div className='modal-dialog modal-fullscreen-sm-down'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='h5 modal-title'>Bank Statements</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => {
                    setAccountVerificationShow(false);
                  }}
                ></button>
              </div>
              <div
                className='modal-body'
                style={{
                  maxHeight: '80vh',
                  overflowY: 'auto',
                }}
              >
                <BasiqPreConsentForm
                  oppId={props.oppId}
                  currentContact={currentContact}
                  setAccountVerificationShow
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}

      {accountVerificationShow &&
        client?.bankStatementsProvider === 'credit sense' && (
          <React.Fragment>
            <CreditSenseFrame {...props} />
          </React.Fragment>
        )}

      {!accountVerificationShow && (
        <React.Fragment>
          <div className='col-12'>
            <h5 className='mb-1 pre-heading'>Applying for loan</h5>
            <h1 className='mb-4 lh-1'>Verify Your Bank</h1>
            <p className='mb-4'>
              <strong>
                Submit Your Bank Statements for{' '}
                {props.opportunity.opportunityName}
              </strong>
            </p>
            <p>
              In order to progress your application, we need to verify your bank
              account and confirm your mortgage statements. You can securely
              submit them through our partner BASIQ below. BASIQ has a bank
              grade security posture and follows ISO27001 best practices, for
              more information{' '}
              <a href='https://basiq.io/data-security/' target='_blank'>
                cick here
              </a>
            </p>

            <div className='mb-5'>
              <div className='list-group'>
                {props.opportunity.contacts
                  ?.filter((contact: any) => contact.isBorrower)
                  .map((c: contact, index: number) => {
                    return (
                      <div
                        key={`st` + index}
                        className={
                          c.bankStatementStatus === 'Pending'
                            ? 'bank-st bank-st-pending'
                            : c.bankStatements == 'required'
                            ? 'bank-st'
                            : 'bank-st bank-st-done'
                        }
                        onClick={() => showAccountVerificationForContact(c)}
                      >
                        <div className='bank-st-wrap'>
                          <p className='bank-st-name'>
                            {c.firstName} {c.lastName}
                          </p>
                        </div>
                        <p className='bank-stmt-name my-auto'>
                          {(
                            c.bankStatementStatus ?? c.bankStatements
                          ).toUpperCase()}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>

            <div className='d-flex align-items-center'>
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>

              {(props.opportunity.productCode === 'TAB' || props.opportunity.productCode === 'TAB-DRAW') 
              && !canContinueNext 
              && (
                <button className='btn btn-primary me-3' onClick={skipAndContinue}>
                Skip this for now
              </button>
              )}
              <button
                type='submit'
                className='btn btn-primary me-3'
                id='submitBtn'
                onClick={saveAndContinue}
                disabled={!canContinueNext}
              >
                Next
              </button>

              <p className='text-muted m-0'>
                Page {props.currentStep} of {props.totalPage}
              </p>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
