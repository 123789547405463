import React, { useState } from 'react';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import { useNavigate } from 'react-router-dom';

const WithdrawModal = (props) => {
  const [isDisable, setIsDisable] = useState(false);
  const [loading, setloading] = useState(false);
  const [showMsg, setMsg] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setIsDisable((pre) => !pre);
  };

  const closeModal = () => {
    props.setModalClose(false);
  };
  const handleSubmit = () => {
    setloading(true);
    const dataObj = {
      opportunityID: props.opportunityID,
      stageName: 'Withdraw',
      subStageName: 'Applicant Declined Offer',
    };
    OpportunityAPI.saveOpportunity({ ...dataObj })
      .then((res) => {
        console.log('withdraw api');
        if (!res) {
          navigate('/application/ThankYou');
          return;
        }
        if (res.success) {
          setTimeout(() => {
            props.setModalClose(false);
          }, 3000);
          props.fetchOppData();
          setloading(false);
          setMsg(true);
        }
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return (
    <>
      <div
        className='modal fade show'
        style={{ display: 'block', background: '#00000077' }}
      >
        <div className='modal-dialog modal-fullscreen-sm-down'>
          <div className='modal-content'>
            {loading && (
              <div
                className='position-absolute top-0 start-0 w-100 h-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
                style={{ zIndex: 100 }}
              >
                <div className='spinner-border text-primary' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            )}
            <div className='modal-header'>
              <h5 className='h5 modal-title'>Withdraw Offer</h5>
              <button
                type='button'
                className='btn-close'
                onClick={closeModal}
              ></button>
            </div>
            {!showMsg ? (
              <div className='modal-body'>
                <div className=' form-check mb-4'>
                  <input
                    type='checkbox'
                    className='form-check-input'
                    id='confirmWithdraw'
                    onChange={handleChange}
                  />
                  <label className='form-check-label' htmlFor='confirmWithdraw'>
                    I wish to withdraw this offer.
                  </label>
                </div>
                <div className='d-flex align-items-center pb-2'>
                  <button
                    id='submitWithdraw'
                    type='submit'
                    className='btn btn-primary me-2'
                    disabled={!isDisable}
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type='button'
                    className='btn btn-outline-secondary'
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            ) : (
              <div className='modal-body'>
                <div className=' form-check mb-4'>
                  <p className='text-success'>
                    Opportunity {props.opportunityName} successfully withdrawn.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawModal;
