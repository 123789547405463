import React, { useState, useEffect, useRef } from 'react'
import { Select, Input, Form, Checkbox } from 'semantic-ui-react';
import PropertyManager from './PropertyManager';

const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

function loadAsyncScript(src) {
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type: "text/javascript",
            async: true,
            src
        })
        script.addEventListener("load", () => resolve(script));
        const tag = document.body.appendChild(script);
    })
}

const extractAddress = (place) => {
     console.log("place",place.address_components)
    let st = "";
    const address = {
        unit: "",
        city: "",
        state: "",
        postCode: "",
        country: "",
        addressSt: "",
        suburb: ""
    }

    if (!Array.isArray(place?.address_components)) {
        return address;
    }

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;
        console.log(`${component.types[0]} - ${component.long_name}`);
        if (types.includes("subpremise")||(types.includes("premise"))) {
            address.unit = value;
        }  
        if ((types.includes("sublocality"))||(types.includes("locality") && (address.suburb===''))) {
            address.suburb = value;
        }
        if (types.includes("street_number")) {
            st = component.short_name;
        }
        if (types.includes("route")) {
            address.addressSt = `${st} ${value}`;
        }

        if (types.includes("administrative_area_level_2")) {
            address.city = value;
        }
        if (types.includes("administrative_area_level_1")) {
            address.state = value;
        }

        if (types.includes("postal_code")) {
            address.postCode = value;
        }

        if (types.includes("country")) {
            address.country = value;
        }

    });
    if(address.country!=="Australia"){
        let city=address.state;
        address.city = city;
        address.state = null;
    }

    return address;
}

const AddressDetails = (props) => {
    const { register, errors, setValue, getValues, oppContactData } = props;
    const [showDetails, setShowDetails] = useState(false)
    const searchInput = useRef(null);
    const [address, setAddress] = useState({});
    const [isPm, setIsPm] = useState(oppContactData ? oppContactData.livingArragement : "Own");
    const [state, setState] = useState(oppContactData ? oppContactData.addressState : null);
    const [country, setCountry] = useState(oppContactData ? oppContactData.addressCountry : null);

    const initMapScript = () => {
        // if script already loaded
        if (window.google) {
            return Promise.resolve();
        }
        const src = `${mapApiJs}?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`;
        return loadAsyncScript(src);
    }

    // do something on address change
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();
        setAddress(extractAddress(place));
    }

    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current) return;

        const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(['address_component']);
        autocomplete.setComponentRestrictions({
            country: ["nz", "au"]
          });
        autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));

    }
    useEffect(() => {
        if (address.country) {
            setValue("addressUnit", address.unit)
            setValue("addressStreet", address.addressSt)
            setValue("addressSuburb", address.suburb)
            setValue("addressCity", address.city)
            setValue("addressPostcode", address.postCode)
            setValue("addressCountry", address.country)
            setValue("addressState", address.state)
            setState(address.state)
            setCountry(address.country)

        }
    }, [address]);

    useEffect(() => {
        initMapScript().then(() => initAutocomplete())
        if(oppContactData){
            setValue("residentialAddress",oppContactData.residentialAddress)
            setValue("addressUnit", oppContactData.addressUnit)
            setValue("addressStreet", oppContactData.addressStreet)
            setValue("addressSuburb", oppContactData.addressSuburb)
            setValue("addressCity", oppContactData.addressCity)
            setValue("addressPostcode", oppContactData.addressPostcode)
            setValue("addressCountry", oppContactData.addressCountry)
            setValue("addressState", oppContactData.addressState)
            setValue("yearsAtAddress", oppContactData.yearsAtAddress)
            setValue("livingArragement",oppContactData.livingArragement)
        }
    }, []);
    return (
        <div>
            <div className="card-body border-bottom">
                <h2 className='h2 mb-3'>Your Details </h2>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-8 pb-2 pb-sm-0">
                        <p className='m-0'>Where Do You Currently Live?</p>
                        <Form.Field className='m-0'>
                            <input
                                ref={searchInput}
                                type={"text"}
                                onBlur={(e)=>{
                                    setValue("residentialAddress",e.target.value)
                                }}
                                defaultValue={oppContactData ? oppContactData.residentialAddress : ''}
                            // {...register("residentialAddress", {
                            //    // required: true
                            // })}
                            />
                        </Form.Field>
                        <p className='btn-link' role="button" onClick={() => setShowDetails((pre) => !pre)}>Can't find address?</p>
                    </div>
                </div>
            </div>
            {showDetails &&
                <>
                    <div className="card-body ">
                        <div className="row">
                            <div className="col-sm-3 pb-2 mb-2 pb-sm-0">
                                <p className='mb-1 pre-heading-h6'>Unit No.</p>
                                <Form.Field>
                                    <input
                                        type={"text"}
                                        {...register("addressUnit", {
                                            // required: true,
                                            value:oppContactData ? oppContactData.addressUnit : ''
                                        })}
                                    />
                                </Form.Field>
                            </div>
                            <div className="col-sm-9 pb-2 mb-2 pb-sm-0">
                                <p className='mb-1 pre-heading-h6'>Address</p>
                                <Form.Field>
                                    <input
                                        {...register("addressStreet", {
                                        value: oppContactData ? oppContactData.addressStreet : ''
                                        })}
                                        type={"text"} />
                                </Form.Field>

                            </div>
                        </div>
                    </div>

                    <div className="card-body ">
                        <div className="row">

                            <div className="col-sm-4 mb-2 pb-2">
                                <p className='mb-1 pre-heading-h6'>Suburb</p>
                                <Form.Field>
                                    <input
                                        style={{ width: "100%" }}
                                        {...register("addressSuburb", {
                                            // required: true,
                                            value: oppContactData ? oppContactData.addressSuburb : ''
                                        })}
                                        type={"text"} />
                                </Form.Field>

                            </div>
                            <div className="col-sm-4 mb-2 pb-2">
                                <p className='mb-1 pre-heading-h6'>City</p>
                                <Form.Field>
                                    <input
                                        {...register("addressCity", {
                                            // required: true,
                                            value: oppContactData ? oppContactData.addressCity : ''
                                        })}
                                        type={"text"} />
                                </Form.Field>
                            </div>
                            {(country==="Australia"||country===null) &&
                            <div className="col-sm-4 mb-2 pb-2">
                                <p className='mb-1 pre-heading-h6'>State</p>
                                <Form.Field>
                                    <Select
                                        style={{ width: "100%", minWidth: "100%" }}
                                        name="addressState"
                                        onChange={(e, data) => {
                                            setValue("addressState", `${data.value}`)
                                            setState(data.value)
                                        }}
                                        value={state}
                                        placeholder="Choose..."
                                        options={[
                                            { value: "Australian Capital Territory", text: "Australian Capital Territory" },
                                            { value: "New South Wales", text: "New South Wales" },
                                            { value: "Northern Territory", text: "Northern Territory" },
                                            { value: "Queensland", text: "Queensland" },
                                            { value: "South Australia", text: "South Australia" },
                                            { value: "Tasmania", text: "Tasmania" },
                                            { value: "Victoria", text: "Victoria" },
                                            { value: "Western Australia", text: "Western Australia" }
                                        ]}
                                    />
                                </Form.Field>
                            </div>}
                            <div className="col-sm-4 mb-2 pb-2">
                                <p className='mb-1 pre-heading-h6'>Postcode</p>
                                <Form.Field>
                                    <input
                                        style={{ width: "100%" }}
                                        {...register("addressPostcode", {
                                            // required: true,
                                            value: oppContactData ? oppContactData.addressPostcode : ''
                                        })}
                                        type={"text"} />
                                </Form.Field>
                            </div>

                            <div className="col-sm-4 mb-2 pb-2">
                                <p className='mb-1 pre-heading-h6'>Country</p>
                                <Form.Field>
                                <Select
                                        style={{ width: "100%", minWidth: "100%" }}
                                        name="addressCountry"
                                        onChange={(e, data) => {
                                            setValue("addressCountry", `${data.value}`)
                                            setCountry(data.value)
                                        }}
                                        value={country}
                                        placeholder="Choose..."
                                        options={[
                                            { value: "Australia", text: "Australia" },
                                            { value: "New Zealand", text: "New Zealand" },
                                        ]}
                                    />
                                </Form.Field>
                            </div>
                        </div>
                    </div>
                    <div className="card-body border-bottom">
                        <div className="row">

                            <div className="col-sm-6 pb-2">
                                <p className='mb-1 pre-heading-h6'>Years at Your Current Address</p>
                                <Form.Field>
                                    <Select
                                        style={{ width: "100%", minWidth: "100%" }}
                                        refs={register("yearsAtAddress", {
                                        })}
                                        onChange={(e, data) => {
                                            setValue("yearsAtAddress", `${data.value}`)
                                        }}
                                        defaultValue={oppContactData ? oppContactData.yearsAtAddress : ''}
                                        placeholder="Choose..."
                                        options={[
                                            { text: "0-1 Years", value: "0-1 years" },
                                            { text: "1-3 Years", value: "1-3 years" },
                                            { text: "3+ Years", value: "3+ years" },

                                        ]}
                                    />

                                </Form.Field>
                            </div>

                            <div className="col-sm-5 pb-2 mt-1">
                                <p className=' form-check-label mb-2'>Living Arrangement</p>
                                <div className="form-check form-check-inline">

                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        {...register("livingArragement", {
                                            // required: true
                                            onChange: (e) => setIsPm(e.target.defaultValue)
                                        })}
                                        id="Own"
                                        value="Own"
                                        checked={isPm === "Own"}
                                    />
                                    <label className="form-check-label" htmlFor="Own">
                                        Own
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id="Rent"
                                        {...register("livingArragement", {
                                            // required: true
                                            onChange: (e) => setIsPm(e.target.defaultValue)
                                        })}
                                        value="Rent"
                                        checked={isPm === "Rent"}
                                    />
                                    <label className="form-check-label" htmlFor="Rent">
                                        Rent
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            {isPm === "Rent" &&
                <PropertyManager {...props} register={register} />}
        </div>
    )
}

export default AddressDetails
