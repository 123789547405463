import React, { useEffect } from 'react';
import { Select, Input, Form } from 'semantic-ui-react';

const Entity = (props) => {
  const { register, unregister, oppContactData, errors, setValue } = props;
  // console.log("oppid",oppContactData)
  useEffect(() => {
    if (oppContactData && oppContactData.id) {
      setValue('entity.id', oppContactData.id);
    }
  }, []);

  return (
    <div>
      <div className='card-body border-bottom'>
        <div className='row'>
          <div className='col-sm-6 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>Entity Name/Trading Name</p>
            <Form.Field>
              <input
                type={'text'}
                {...register('entity.entityName', {
                  required: true,
                  value: oppContactData ? oppContactData.entityName : '',
                })}
              />
            </Form.Field>
            {errors?.entity?.entityName && (
              <p className='text-danger'>This field is required.</p>
            )}
          </div>
          <div className='col-sm-6 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>Mobile</p>
            <Form.Field>
              <input
                {...register('entity.mobile', {
                  value: oppContactData ? oppContactData.mobile : '',
                  pattern: {
                    value: /^\+?[0-9\s*]+$/,
                    message: 'Please enter a valid phone number.',
                  },
                  required: true,
                })}
                type={'tel'}
              />
            </Form.Field>
            {errors?.entity?.mobile && (
              <p className='text-danger'>
                {errors.entity.mobile.type === 'required'
                  ? 'This field is required.'
                  : errors.entity.mobile.message}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className='card-body border-bottom'>
        <div className='row'>
          <div className='col-sm-6 pb-2'>
            <p className='mb-1 pre-heading-h6'>Email</p>
            <Form.Field>
              <input
                style={{ width: '100%' }}
                {...register('entity.email', {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email.',
                  },

                  value: oppContactData ? oppContactData.email : '',
                })}
                type={'email'}
              />
            </Form.Field>
            {errors?.entity?.email && (
              <p className='text-danger'>
                {errors?.entity?.email.type === 'required'
                  ? 'This field is required.'
                  : errors?.entity?.email.message}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className='card-body border-bottom'>
        <div className='row'>
          <div className='col-sm-6 pb-2'>
            <p className='mb-1 pre-heading-h6'>Full Legal Name</p>
            <Form.Field>
              <input
                type={'text'}
                {...register('entity.legalName', {
                  required: true,
                  value: oppContactData ? oppContactData.legalName : '',
                })}
              />
            </Form.Field>
          </div>
          <div className='col-sm-6 pb-2'>
            <p className='mb-1 pre-heading-h6'>ABN/NZBN</p>
            <Form.Field>
              <input
                type={'text'}
                {...register('entity.abn', {
                  required: true,
                  value: oppContactData ? oppContactData.abn : '',
                })}
              />
            </Form.Field>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Entity;
