export default function (str,splitBy,joinBy,isReverse) {
    if((str=='')||(!str))
    {
        return;
    }
    let newStr;
    if(isReverse==true){
        newStr= str.split(splitBy).reverse().join(joinBy);
        return newStr;
    }
    newStr= str.split(splitBy).join(joinBy);
    return newStr;
}