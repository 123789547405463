import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';

const AddNewBankAccount = (props) => {
  const $ = window.$;

  function receive(event) {
    if (
      event.origin !== 'https://go.sandbox.split.cash' &&
      event.origin !== 'https://go.split.cash'
    ) {
      //props.setTabIndex(0)
      return;
    }
    setTimeout(async () => {
      let opportunity = await OpportunityAPI.getOpportunity(props.oppId);
      let contact = opportunity?.contacts.filter(
        (user) => user.id == props.currentContact.id,
      )[0];
      props.setCurrentContact(contact);
      props.setOpportunity({ ...opportunity, newBankAdded: true });

      window.removeEventListener('message');
      props.setTabIndex(0);
    }, 4000);
  }
  useEffect(() => {
    window.addEventListener('message', receive, false);
  }, []);

  let navigate = useNavigate();

  let link = props.currentContact?.bankAccountLink;
  let newLink = `${link}?embed=1&whitelabel=1&allow_manual=true&handle_failure=1&handle_success=1&custom_template=${window.location.origin}/css/zepto_ua.css`;

  const paramsData = {};

  if (link != '') {
    const words = newLink.split('?');
    const arrayOfkeyValuePairs = words[1].split('&');
    for (var i = 0; i < arrayOfkeyValuePairs.length; i++) {
      const keyValuePair = arrayOfkeyValuePairs[i].split('=');
      paramsData[keyValuePair[0]] = keyValuePair[1];
    }
  }
  // console.log('add bank link', link,newLink,paramsData);
  const [isLoaded, setIsLoaded] = useState(false);
  const back = () => {
    navigate('/application/' + props.oppId);
  };

  const hideLoadingBar = () => {
    setIsLoaded(true);
  };

  return (
    <React.Fragment>
      {!isLoaded && <h2 className='p-4'> Loading ... </h2>}
      {link !== '' && (
        <iframe
          id='addBankIFrame'
          src={newLink}
          style={{ height: '810px', width: '100%', border: 'none' }}
          onLoad={hideLoadingBar}
          title='Add new bank account'
          loading='eager'
        ></iframe>
      )}
      {props.opportunity.bankAccounts.length > 0 && (
        <button
          className='btn btn-outline-secondary me-3 ml-4 mb-3'
          onClick={back}
        >
          Cancel
        </button>
      )}
    </React.Fragment>
  );
};

export default AddNewBankAccount;
