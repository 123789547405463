import React, { useState } from 'react';
import dateFormat from '../../utils/dateFormat';
import formatCurrency from '../../utils/formatCurrency';
import repaymentPathImage from '../../assets/images/repayment-path.jpg';

export default function RepaymentPath(props: any) {
  const {
    paymentScheduleList,
    loan,
    loanList,
    facilityDetails,
    propertyAddress,
    contacts,
  } = props.opportunity;
  const [asc, setAsc] = useState(true);
  const [paymentList, setPaymentList] = useState(paymentScheduleList);
  //console.log(props.opportunity);
  const back = () => {
    props.prevStep();
  };
  const saveAndContinue = () => {
    props.nextStep();
  };

  return (
    <React.Fragment>
      <div className='row'>
        {/* <div className='col-sm-10 offset-sm-1'> */}
        <div className='col-12'>
          <h1 className='mb-4 lh-1'>Path to Repayment</h1>
          <p className='mb-4 italic'>
            By continuing, you acknowledge that you understand the repayment
            path for your loan.
          </p>

          <h3 className='h3 mb-3'>Repayment of your finance is due:</h3>
          <ul>
            <li>at the settlement of your property</li>
            <li>if the property is removed from market</li>
            <li>if the 6 month loan term is reached</li>
          </ul>
          <div className='mb-5'>
            <img src={repaymentPathImage} className='w-100' />
          </div>
          <div className='d-flex align-items-center'>
            {props.currentStep != 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
            >
              Accept
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
