import React, { useEffect, useState } from 'react';

const AdoptSignatureModal = (props: any) => {
  const userName = `${props?.currentContact.firstName} ${props?.currentContact.lastName}`;
  let { sign } = props;
  //   console.log('AdoptSignatureModal', props, userName);
  const [signature, setSignature] = useState<any>(userName);
  const [clickIndex, setClickIndex] = useState<any>(0);

  const styleList = [
    {
      fontFamily: 'Meddon',
      fontSize: '18px',
    },
    {
      fontFamily: 'Cedarville Cursive',
      fontSize: '30px',
    },
    {
      fontFamily: 'Kristi',
      fontSize: '30px',
    },
    {
      fontFamily: 'Mrs Saint Delafield',
      fontSize: '30px',
    },
    {
      fontFamily: 'Caveat',
      fontSize: '30px',
    },
    {
      fontFamily: 'Marck Script',
      fontSize: '30px',
    },
  ];

  const getSignature = () => {
    let drawText = signature;
    if (drawText === '') {
      drawText = userName;
    }
    sign.current.clear();
    const signPad = sign.current.getCanvas().getContext('2d') as any;
    signPad.font = `30px ${styleList[clickIndex].fontFamily}`;

    signPad.fillText(drawText, 20, 60);
    sign.current.getSignaturePad()._isEmpty = false;
    props.setSignValid('');
    props.close(false);
  };
  return (
    <>
      <div
        className='position-fixed top-0 start-0 end-0 bottom-0 bg-secondary bg-opacity-25 d-flex justify-content-center align-items-center'
        style={{ zIndex: 1 }}
      >
        <div className='card modal-view-card modal-lg'>
          <div className='card-body'>
            <h3 className='h4 pb-1 border-bottom '>Adopt Signature</h3>

            <input
              className='border-0 border-bottom text-center text-primary w-100 outline-none'
              style={{ ...styleList[clickIndex], height: '4rem' }}
              name='userName'
              id='userName'
              type='text'
              placeholder='Signature'
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            />

            <h5 className='text-center mb-2'>
              Type your signature above and choose style.
            </h5>
            <div className='d-flex flex-column-sm flex-wrap'>
              {styleList.map((style1: any, ind: number) => {
                return (
                  <div
                    key={ind + 'a'}
                    className='signatureWrapper'
                    onClick={(e) => setClickIndex(ind)}
                  >
                    <label
                      placeholder='Signature'
                      className='d-flex align-items-center w-100 h-100'
                      style={style1}
                    >
                      <input
                        type='radio'
                        className='form-check-input radioWrapper'
                        id={'selectSignature' + ind}
                        name='selectSignature'
                        defaultChecked={ind === 0 && true}
                      />
                      {signature ? signature : 'Signature'}
                    </label>
                  </div>
                );
              })}
            </div>

            <div className='d-flex align-items-center justify-content-center mt-3'>
              <button
                type='button'
                className='btn  me-3  border border-dark'
                onClick={() => props.close(false)}
              >
                Close
              </button>
              <button
                className='btn btn-primary '
                onClick={() => getSignature()}
              >
                Adopt Signature
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdoptSignatureModal;
