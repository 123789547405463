import React, { useState } from 'react'

const PaymentModal = (props) => {

    const [isDisable, setIsDisable] = useState(false)
    const [loading, setloading] = useState(false);
    const [showMsg, setMsg] = useState(false);

    const closeModal = () => {
        props.setModalClose(false)
    }

    return (<>
       
        <div className='modal fade show' style={{ display: "block", background: "#00000077" }}>
            <div className='modal-dialog modal-fullscreen-sm-down modal-lg'>
                <div className='modal-content'>
                {loading && <div className='position-absolute top-0 start-0 w-100 h-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
            style={{ zIndex: 100 }}
        >
            <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
            </div></div>}
                    <div className='modal-header'>
                        <h5 className='h5 modal-title'>
                            Make a Payment
                        </h5>
                        <button type='button' className='btn-close' onClick={closeModal}></button>
                    </div>
                    <div className="modal-body">
                        {props?.countryCode === "NZ" &&
                            <div className='card mb-4'>
                                <div className='card-header'>
                                    <h5 className='h5 card-title'>
                                        Bank Deposit
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                {"Please deposit funds into the following account, using this loan number " + (props?.opportunityName) + " as your reference:"
                                                }
                                            </p>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Account Name:</strong></td>
                                                    <td> Property Credit</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Account Number:</strong></td>
                                                    <td> 042024 025787000</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Payment Reference:</strong></td>
                                                    <td> {props?.opportunityName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {(props?.countryCode === "AU" && props?.loan?.repaymentByDR) &&
                            <div className='card mb-4'>
                                <div className='card-header'>
                                    <h5 className='h5 card-title'>
                                        Direct DEBIT
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                Your direct deposit has been setup with the following details:
                                            </p>
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <td><strong>Repayment Frequency:</strong></td>
                                                    <td> {props?.loan?.repaymentFrequency}</td>
                                                </tr>
                                                <tr>
                                                    <td><strong>BSB:</strong></td>
                                                    <td> {props?.bankAccounts.filter((acc) => acc.accountSelected)[0].accountBSB} </td>
                                                </tr>
                                                <tr>
                                                    <td><strong>Account Number:</strong></td>
                                                    <td> {props?.bankAccounts.filter((acc) => acc.accountSelected)[0].accountNumber}</td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                        <p>Payment will be attempted on the morning of the next scheduled payment.
                                        </p>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {(props?.countryCode === "AU" && props?.payIdActive != true) &&
                            <div className='card mb-4'>
                                <div className='card-header'>
                                    <h5 className='h5 card-title'>
                                        Bank Deposit
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                {
                                                    "Please deposit funds into the following account, using this loan number " + (props?.opportunityName) + " as your reference:"
                                                }
                                            </p>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td><strong>Account Name:</strong></td>
                                                        <td> Property Credit</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>BSB:</strong></td>
                                                        <td> 084-917 </td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Account Number:</strong></td>
                                                        <td> 850176774</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Payment Reference:</strong></td>
                                                        <td> {props?.opportunityName}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>}

                        {(props?.countryCode === "AU" && props?.payIdActive == true) &&
                            <div className='card mb-4'>
                                <div className='card-header'>
                                    <h5 className='h5 card-title'>
                                        Payid or Bank Deposit
                                    </h5>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <p>
                                                {"Please deposit funds into the following PayID or bank account, using this loan number " + (props?.opportunityName) + " as your reference:"
                                                }
                                            </p>

                                            <table className="table table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <td><strong>PayID:</strong></td>
                                                        <td> {props?.payIdEmail}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>BSB:</strong></td>
                                                        <td> {props?.payIdBSB} </td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Account Number:</strong></td>
                                                        <td> {props?.payIdBankAcct}</td>
                                                    </tr>
                                                    <tr>
                                                        <td><strong>Payment Reference:</strong></td>
                                                        <td> {props?.opportunityName}</td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        </div>
                                    </div>
                                </div>
                            </div>}

                        <div className="d-flex align-items-center pb-2">
                            <button type="button" className="btn btn-outline-secondary" onClick={closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default PaymentModal;


