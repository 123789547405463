import React, {useState } from 'react';
import dateFormat from '../../utils/dateFormat';
import formatCurrency from '../../utils/formatCurrency';

export default function LoanOfferPage(props: any) {
  const { paymentScheduleList, loan, propertyAddress, contacts } =
    props.opportunity;
  const [asc, setAsc] = useState(true);
  const [paymentList, setPaymentList] = useState(paymentScheduleList);
  console.log(props.opportunity);
  const back = () => {
    props.prevStep();
  };
  const saveAndContinue = () => {
    props.nextStep();
  };

  const handleSortTable = () => {
    setAsc((pre) => !pre);
    setPaymentList((pre: any) => pre.reverse());
  };

  return (
    <React.Fragment>
      <div className='row'>
        {/* <div className='col-sm-10 offset-sm-1'> */}
        <div className='col-12'>
          <h5 className='mb-1 pre-heading'>Applying for loan</h5>
          <h1 className='mb-4 lh-1'>Loan Offer</h1>
          <p className='mb-4'>
            Congratulations, we are pleased to be able to offer you a loan with
            the following details:
          </p>
          <div className='card mb-5'>
            <div className='card-body border-bottom'>
              <div className='row'>
                <div className='col-sm-4 pb-2 pb-sm-0'>
                  <h6 className='mb-0 pre-heading-h6'> Loan Amount</h6>
                  <p className='p-font mb-0'>
                    {formatCurrency(loan?.loanAmountOffered)}
                  </p>
                </div>
                <div className='col-sm-4'>
                  <h6 className='mb-0 pre-heading-h6'>Loan Term</h6>
                  <p className='p-font mb-0'>{loan?.loanTerm}</p>
                </div>
              </div>
            </div>
            <div className='card-body border-bottom'>
              <div className='row'>
                <div className='col-sm-12'>
                  <h6 className='mb-0 pre-heading-h6'>Property Address</h6>
                  <p className='p-font mb-0'>{propertyAddress}</p>
                </div>
              </div>
            </div>
            <div className='card-body border-bottom'>
              <div className='row'>
                {loan?.releaseFee && (
                  <div className='col-sm-4 pb-2 pb-sm-0'>
                    <h6 className='mb-0 pre-heading-h6'>Release Fee</h6>
                    <p className='p-font mb-0'>
                      {formatCurrency(loan?.releaseFee)}
                    </p>
                  </div>
                )}
                <div className='col-sm-4 pb-2 pb-sm-0'>
                  <h6 className='mb-0 pre-heading-h6'>Monthly Admin Fee</h6>
                  <p className='p-font mb-0'>
                    {formatCurrency(loan?.monthlyAdminFee)}
                  </p>
                </div>
                {loan?.repaymentFrequency !== 'Single' && (
                  <div className='col-sm-4 pb-2 pb-sm-0'>
                    <h6 className='mb-0 pre-heading-h6'>
                      {loan?.repaymentFrequency} Repayment
                    </h6>
                    <p className='p-font mb-0'>
                      {formatCurrency(loan?.instalmentsAmount)}
                    </p>
                  </div>
                )}
              </div>
            </div>
            {contacts
              ?.filter((contact: any) => contact.isBorrower)
              .map((con: any, index: number) => {
                return (
                  <div key={index + 'con'} className='card-body border-bottom'>
                    <div className='row'>
                      <div className='col-sm-4 pb-2 pb-sm-0'>
                        <h6 className='mb-0 pre-heading-h6'>Borrower</h6>
                        <p className='p-font mb-0'>{`${con?.firstName} ${con?.lastName}`}</p>
                      </div>
                      <div className='col-sm-8'>
                        <h6 className='mb-0 pre-heading-h6'>Borrower Email</h6>
                        <p className='p-font mb-0'>{con?.email}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <h2 className='h2 mb-3'>Payment Schedule</h2>
          <div className='card mb-4'>
            <div className='card-body p-0'>
              <div className='table-responsive'>
                <table className='table mb-0'>
                  <thead className='bg-light'>
                    <tr className='custom-row'>
                      <td scope='col' className=''>
                        <span
                          className='cursor-pointer'
                          onClick={handleSortTable}
                        >
                          Date{' '}
                          {asc ? (
                            <i className='bi bi-arrow-down'></i>
                          ) : (
                            <i className='bi bi-arrow-up'></i>
                          )}
                        </span>
                      </td>
                      <td scope='col' className=''>
                        Type
                      </td>
                      <td scope='col' className=''>
                        Amount
                      </td>
                      <td scope='col' className=''>
                        Status
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentList?.map((pay: any, ind: any) => {
                      return (
                        <tr key={ind + 'pay'}>
                          <td className=''>{dateFormat(new Date(pay.date))}</td>
                          <td className=''>{pay.name}</td>
                          <td className=''>${pay.amount}</td>
                          <td className=''>
                            <button className='btn btn-sm rounded-pill bg-light text-secondary py-0'>
                              {pay.status}
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* <div className='d-flex align-items-center justify-content-end mb-4'>
            <button className='btn btn-primary-2' type='button'>
              Accept Terms
            </button>
          </div> */}
          <div className='d-flex align-items-center'>
            {props.currentStep != 1 && (
              <button className='btn btn-outline-secondary me-3' onClick={back}>
                Back
              </button>
            )}
            <button
              type='submit'
              className='btn btn-primary me-3'
              id='submitBtn'
              onClick={saveAndContinue}
            >
              Next
            </button>
            <p className='text-muted m-0'>
              Page {props.currentStep} of {props.totalPage}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
