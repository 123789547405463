import React, { useState } from 'react'
import { Select, Input, Form, Checkbox } from 'semantic-ui-react';
import IdFields from './IdFields';


const Identification = (props) => {
    const { register, setValue, oppContactData, errors } = props;
    const [idType1, setIdType1] = useState(oppContactData ? oppContactData.idVerificationType1 : null)
    const [idType2, setIdType2] = useState(oppContactData ? oppContactData.idVerificationType2 : null)

    const optionsData = [
        { text: `Choose...`, value: "" },
        { text: `Australian Driver Licence`, value: "AUDriverLicence" },
        { text: `Australian Medicare Card`, value: "AUMedicareCard" },
        { text: `Australian Passport`, value: "AUPassport" },
        { text: `New Zealand Birth Certificate`, value: "NZBirthCertificate" },
        { text: `New Zealand Citizenship Certificate`, value: "NZCitizenshipCertificate" },
        { text: `New Zealand Passport`, value: "NZPassport" },
        { text: `New Zealand Driver Licence`, value: "NZDriverLicence" }]

    return (
        <div>
            <div className="card-body border-bottom">
                <h2 className='h2 mb-3'>Identification (100 Points ID) </h2>
            </div>
            <div className="card-body border-bottom">
                <div className="row mb-4">
                    <div className="col-sm-4 pb-2 pb-sm-0">
                        <p className='mb-1 pre-heading-h6'>ID Type 1</p>
                        <Form.Field>
                            <Select
                                style={{ width: "100%", minWidth: "100%" }}
                                refs={register("idVerificationType1", {
                                    value: oppContactData ? oppContactData.idVerificationType1 : ''
                                })}
                                placeholder="Choose..."
                                onChange={(e, data) => {
                                    setValue("idVerificationType1", `${data.value}`)
                                    setIdType1(data.value)
                                }}
                                value={idType1}
                                options={optionsData.map((opt) =>
                                    (opt.value !== '' && opt.value === idType2) ? { ...opt, disabled: true } : opt
                                )}
                            />
                        </Form.Field>
                    </div>
                </div>
                {idType1 &&
                    <IdFields selectedIdType={idType1} {...props} />
                }
            </div>
            <div className="card-body border-bottom">
                <div className="row mb-4">
                    <div className="col-sm-4 pb-2 pb-sm-0">
                        <p className='mb-1 pre-heading-h6'>ID Type 2</p>
                        <Form.Field>
                            <Select
                                style={{ width: "100%", minWidth: "100%" }}
                                refs={register("idVerificationType2", {
                                    value: oppContactData ? oppContactData.idVerificationType2 : ''
                                })}
                                placeholder="Choose..."
                                onChange={(e, data) => {
                                    setValue("idVerificationType2", `${data.value}`)
                                    setIdType2(data.value)
                                }}
                                value={idType2}

                                options={optionsData.map((opt) =>
                                    (opt.value !== '' && opt.value === idType1) ? { ...opt, disabled: true } : opt
                                )}
                            />
                        </Form.Field>
                    </div>
                </div>
                {idType2 &&
                    <IdFields selectedIdType={idType2} {...props} />
                }
            </div>
        </div>
    )
}

export default Identification