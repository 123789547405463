import axios from 'axios';

async function getUserConsentUrl(userId: string) {
  console.log(`getUserConsentUrl(${userId})`);

  let endpoint = `${process.env.REACT_APP_BASIQ_API_URL}/v1/user/${userId}/consentUrl`;
  let config = {
    method: 'GET',
    url: endpoint,
  };

  const response = await axios(config);
  return response.data;
}

async function getBasiqUser(email: string) {
  console.log(`getBasiqUser(${email})`);

  let endpoint = `${process.env.REACT_APP_BASIQ_API_URL}/v1/user`;
  let config = {
    method: 'POST',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
    },
    data: JSON.stringify({ email: email }),
  };

  const response = await axios(config);
  return response.data;
}

async function saveJob(oppId: string, jobId: string) {
  if (process.env.NODE_ENV === 'development') {
    console.log('job not saved in development environment');
    return;
  }
  console.log(`saveJob(${oppId}, ${jobId})`);

  if (!oppId || !jobId) return;

  let endpoint = `${process.env.REACT_APP_BASIQ_API_URL}/v1/jobs`;
  let payload = { oppId: oppId, jobId: jobId };

  let config = {
    method: 'POST',
    url: endpoint,
    headers: {
      'Content-Type': 'application/json',
    },
    data: payload,
  };

  try {
    let response = await axios(config);
    if (response) {
      console.log(`Saved Job((${oppId}, ${jobId})`);
    }
  } catch (err) {
    console.error('Error saving Job', err);
  }
}

// Permanently deletes a connection with the Basiq API
// Once the connection has been deleted, all of the associated financial data e.g. accounts and transactions can still be accessed via the users end-point
// https://api.basiq.io/reference/delete-a-connection
async function deleteConnection(userId: string, jobId: string) {
  console.log('deleteConnection');

  let endpoint = `${process.env.REACT_APP_BASIQ_API_URL}/v1/user/${userId}/connections/${jobId}`;
  let config = {
    method: 'GET',
    url: endpoint,
  };

  const response = await axios(config);
  return response.data;
}

// Retrieves the details of the job
// https://api.basiq.io/reference/retrieve-a-job
async function checkJobStatus(jobId: string) {
  console.log(`checkJobStatus(${jobId})`);

  let endpoint = `${process.env.REACT_APP_BASIQ_API_URL}/v1/jobs/${jobId}`;
  let config = {
    method: 'GET',
    url: endpoint,
  };

  const response = await axios(config);
  return response.data;
}

export default {
  getBasiqUser,
  getUserConsentUrl,
  saveJob,
  deleteConnection,
  checkJobStatus,
};
