import { useState } from 'react';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import { TGlobalFormProps } from '../../types/form';
import { FieldError, Resolver, useForm } from 'react-hook-form';
import { bankAccount } from '../../models/bankAccount.model';
import { Form } from 'semantic-ui-react';

type NewBankAccountFormValues = Pick<
  bankAccount,
  'accountName' | 'accountBSB' | 'accountNumber'
>;

const resolver: Resolver<NewBankAccountFormValues> = async (values) => {
  const errors: { [key in keyof NewBankAccountFormValues]?: FieldError } = {};

  const accountBSB = Number(values.accountBSB);
  const accountNumber = Number(values.accountNumber);
  const { accountName } = values;

  if (isNaN(accountBSB))
    errors.accountBSB = { message: 'Must be a number', type: 'valueAsNumber' };
  if (isNaN(accountNumber))
    errors.accountNumber = {
      message: 'Must be a number',
      type: 'valueAsNumber',
    };

  const bsbLength = 6;
  const accountNumberLengthMin = 5;
  const accountNumberLengthMax = 9;

  if (values.accountBSB.length !== bsbLength)
    errors.accountBSB = {
      message: `Must be ${bsbLength} digits`,
      type: 'min',
    };
  if (
    values.accountNumber.length < accountNumberLengthMin ||
    values.accountNumber.length > accountNumberLengthMax
  )
    errors.accountNumber = {
      message: `Must be ${accountNumberLengthMin} to ${accountNumberLengthMax} digits`,
      type: 'min',
    };

  if (!accountName.length)
    errors.accountName = {
      message: `This field is required`,
      type: 'required',
    };

  return {
    values,
    errors,
  };
};

export function AddNewBankAccount(
  props: TGlobalFormProps & {
    setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  },
) {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { register, handleSubmit, formState } =
    useForm<NewBankAccountFormValues>({
      resolver,
      mode: 'onChange',
    });
  const { errors, isValid } = formState;

  // const [requestError, setRequestError] = useState<undefined | string>(undefined);

  const onSubmit = handleSubmit(async (data) => {
    try {
      setIsSubmitting(true);

      const res = await OpportunityAPI.addNewBankAccount({
        opportunityID: props.opportunity.opportunityID,
        contactID: props.currentContact.id,
        bankAccount: {
          ...data,
        },
      });
      console.log(res);
      if (res.data?.success) {
        // retrive new opportunity and select in 'Available Accounts' menu

        const newBankAccount = res.data?.data;

        // insert new bank account instead of refetching the opportunity
        const newOpportunity = props.opportunity;
        newOpportunity.bankAccounts.unshift({
          id: newBankAccount?.id_x,
          ...newBankAccount,
        });

        props.setOpportunity({ ...props.opportunity, newBankAdded: true });

        // let opportunity = (await OpportunityAPI.getOpportunity(
        //   props.opportunity.opportunityID,
        // )) as any;
        // if (!opportunity) throw new Error('No opportunity');

        // let contact = opportunity?.contacts?.filter(
        //   (user: any) => user.id === props.currentContact.id,
        // )[0];
        // if (!contact) throw new Error('No contact');
        // props.setCurrentContact(contact);
        props.setOpportunity({ ...props.opportunity, newBankAdded: true });
      }
    } catch (err) {
      console.log('err', err);
      alert('Something went wrong!');
    } finally {
      setIsSubmitting(false);
    }
    setIsSubmitting(false);
  });

  const fields: {
    input: keyof NewBankAccountFormValues;
    title: string;
    required: boolean;
    type?: React.HTMLInputTypeAttribute;
  }[] = [
    { input: 'accountName', title: 'Account name', required: true },
    {
      input: 'accountBSB',
      title: 'BSB',
      required: true,
      type: 'number',
    },
    {
      input: 'accountNumber',
      title: 'Account number',
      required: true,
      type: 'number',
    },
  ];

  return (
    <Form onSubmit={onSubmit} className='flex flex-col card-body'>
      <div className='card-body pt-3 pb-2'>
        {fields.map(({ input, title, type, required }) => {
          const error = errors[input]?.message;

          return (
            <div className={`mb-2 w-full`} key={input}>
              {/* <div
                className={`flex justify-between ${
                  error ? '[&>p]:text-critical-bold' : ''
                }`}
              >
                <p className={'mb-1 pre-heading-h6'}>{title}</p>
                {error && (
                  <p className='m-0 text-sm text-critical-bold'>{error}</p>
                )}
              </div>
              <input
                className={`w-full px-2 py-1 border-1 outline-none ${
                  error ? 'border-critical-bold' : ''
                }`}
                {...register(input)}
                placeholder={title}
                type={type}
              /> */}
              <div className='col-sm-4 pb-2 pb-sm-0 mb-2'>
                <p className='mb-1 pre-heading-h6'>{title}</p>
                <Form.Field>
                  <input {...register(input)} placeholder={title} type={type} />
                </Form.Field>
                {error && <p className='text-danger'>{error}</p>}
              </div>
            </div>
          );
        })}
      </div>
      <div className='card-body border-bottom pt-3 pb-2'>
        <button
          type='submit'
          disabled={!isValid || isSubmitting}
          className='btn btn-primary me-2 mb-2'
        >
          Submit
        </button>
      </div>
    </Form>
  );
}
