import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';
// import './index.css';
// import './styles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import './custom.css'
// import './partner-tapi.css';

import App from './App';

Sentry.init({
  dsn: 'https://7b89d8cd25204efaaa4df00e314e62d4@o1223215.ingest.sentry.io/6388282',
  integrations: [new BrowserTracing()],
  enabled: process.env.STAGE !== 'dev',
  environment: process.env.STAGE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


// const routing = (
// 	<BrowserRouter>
// 		<div className="pc-body-wrapper d-flex flex-column">
// 		<NavigationBar />
// 			<Routes>
// 				<Route path="/" element={<App />} />
//         <Route path="/application/:oppId" element={<TapiForm />} />
// 				<Route path="/application/thankYou" element={<ThankYou />} />
// 				{/* <Route path="/apply/tapi/apply-now" element={<TapiForm />} /> */}
// 			</Routes>
// 			<Footer />
// 			</div>
// 	</BrowserRouter>
// );
/* const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink); */

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(  
  // <React.StrictMode>
    <BrowserRouter>
       <App />
    </BrowserRouter>
  // </React.StrictMode>,
);