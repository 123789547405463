import { useState } from 'react';
import { Select, Form } from 'semantic-ui-react';
import IdFields from './IdFields';

const IdentificationSingle = (props) => {
  const { register, setValue, oppContactData } = props;
  const [idType1, setIdType1] = useState(
    oppContactData ? oppContactData.idVerificationType1 : null,
  );

  const optionsData = [
    { text: `Choose...`, value: '' },
    { text: `Australian Driver Licence`, value: 'AUDriverLicence' },
    { text: `Australian Passport`, value: 'AUPassport' },
    { text: `New Zealand Passport`, value: 'NZPassport' },
    { text: `New Zealand Driver Licence`, value: 'NZDriverLicence' },
  ];

  return (
    <div>
      <div className='card-body border-bottom'>
        <h2 className='h2 mb-3'>Identification</h2>
      </div>
      <div className='card-body border-bottom'>
        <div className='row mb-4'>
          <div className='col-sm-4 pb-2 pb-sm-0'>
            <p className='mb-1 pre-heading-h6'>ID Type</p>
            <Form.Field>
              <Select
                style={{ width: '100%', minWidth: '100%' }}
                refs={register('idVerificationType1', {
                  value: oppContactData
                    ? oppContactData.idVerificationType1
                    : '',
                })}
                placeholder='Choose...'
                onChange={(e, data) => {
                  setValue('idVerificationType1', `${data.value}`);
                  setIdType1(data.value);
                }}
                value={idType1}
                options={optionsData.map((opt) => opt)}
              />
            </Form.Field>
          </div>
        </div>
        {idType1 && <IdFields selectedIdType={idType1} {...props} />}
      </div>
    </div>
  );
};

export default IdentificationSingle;
