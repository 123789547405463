import AddDirectDebitZepto from '../zepto/AddNewBankAccount';
import { useMemo, useState } from 'react';
import { TGlobalFormProps } from '../../types/form';
import { AddNewBankAccount } from './AddNewBankAccount';
import React from 'react';

type TActiveItem = undefined | 'bankAccount' | 'directDebit';

const AddNewBankAccountOrDirectDebit = (
  props: TGlobalFormProps & {
    setTabIndex: React.Dispatch<React.SetStateAction<number>>;
  },
) => {
  const [activeItem, setActiveItem] = useState<TActiveItem>('bankAccount');

  const getHandleOptionChange = (item: TActiveItem) => (e: any) => {
    setActiveItem(item);
  };

  const items = [
    {
      name: 'bankAccount',
      title: 'Add bank account',
    },
    {
      name: 'directDebit',
      title: 'Add direct debit account',
    },
  ] as const;

  const bankAccountPurpose = (function () {
    const purposeOptions = ['Disbursement', 'Instalment', 'Both'] as const;
    const purpose = props.opportunity?.bankAccountPurpose;

    return (
      purposeOptions.includes(purpose) ? purpose : 'Both'
    ) as typeof purposeOptions[number];
  })();

  return (
    <div className='card'>
      {/* <div className='border-solid border border-[#D4D4D5] !border-t-0 rounded-b-md'> */}
      <div className='ui form'>
        <div className='card-body border-bottom'>
          <h4>Please add your bank account details below.</h4>
          <p>This account will be used for</p>
          <ul>
            {['Disbursement', 'Both'].includes(bankAccountPurpose) && (
              <li>Advancing your loan funds to you</li>
            )}
            {['Instalment', 'Both'].includes(bankAccountPurpose) && (
              <li>Direct debits according to your loan schedule</li>
            )}
          </ul>
        </div>

        {bankAccountPurpose === 'Disbursement' ? (
          <AddNewBankAccount {...props} />
        ) : (
          <AddDirectDebitZepto {...props} />
        )}
      </div>
    </div>
  );
};

export { AddNewBankAccountOrDirectDebit };
