import React from 'react'
import { Select, Input, Form, Checkbox } from 'semantic-ui-react';
import { countries } from './countries';
import dateStringFormat from '../../utils/dateStringFormat';



const IdFields = (props) => {
    const { register, setValue, selectedIdType, oppContactData, errors } = props;
    return (
        <div>
            {selectedIdType === "AUDriverLicence" &&
                <div id='ADL'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Driver Licence Number</p>
                            <Form.Field>
                                <input
                                    {...register(`driverLicenseNumber`, {
                                        required: true,
                                        pattern:/[A-Za-z0-9]*/,
                                        value: oppContactData ? oppContactData.driverLicenseNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.driverLicenseNumber && <p className="text-danger">Please enter a valid driver licence number.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Driver Card Number</p>
                            <Form.Field>
                                <input
                                    {...register(`driverCardNumber`, {
                                        required: true,
                                        // pattern:/[A-Za-z0-9]*/,
                                        value: oppContactData ? oppContactData.driverCardNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.driverCardNumber && <p className="text-danger">Please enter a valid driver Card number.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>State of Issued</p>
                            <Form.Field>
                                <Select
                                    style={{ width: "100%", minWidth: "100%" }}
                                    refs={register(`driverLicenseState`, {
                                        required: true,
                                        value: oppContactData ? oppContactData.driverLicenseState : null
                                    })}
                                    defaultValue={oppContactData ? oppContactData.driverLicenseState : null}
                                    placeholder="Choose..."
                                    onChange={(e, data) => {
                                        setValue(`driverLicenseState`, `${data.value}`)
                                    }}

                                    options={[
                                        { value: "ACT", text: "Australian Capital Territory" },
                                        { value: "NSW", text: "New South Wales" },
                                        { value: "NT", text: "Northern Territory" },
                                        { value: "QLD", text: "Queensland" },
                                        { value: "SA", text: "South Australia" },
                                        { value: "TAS", text: "Tasmania" },
                                        { value: "VIC", text: "Victoria" },
                                        { value: "WA", text: "Western Australia" }
                                    ]}
                                />
                            </Form.Field>
                            {errors?.driverLicenseState && <p className="text-danger">This field is required.</p>}

                        </div>
                    </div>
                </div>}

            {selectedIdType === "NZDriverLicence" &&
                <div id='NZDL'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Driver Licence Number</p>
                            <Form.Field>
                                <input

                                    {...register(`nzDriverLicenseNumber`, {
                                        required: true,
                                        pattern:/^[A-Za-z]{2}[0-9]{6}$/,
                                        value: oppContactData ? oppContactData.nzDriverLicenseNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.nzDriverLicenseNumber && <p className="text-danger">Please enter a valid driver licence number.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Driver Licence Version</p>
                            <Form.Field>
                                <input

                                    {...register(`nzDriverLicenseVersion`, {
                                        required: true,
                                        pattern:/^[0-9]{3}$/,
                                        value: oppContactData ? oppContactData.nzDriverLicenseVersion : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.nzDriverLicenseVersion && <p className="text-danger">Please enter a valid driver licence version.</p>}
                        </div>
                    </div>
                </div>}

            {selectedIdType === "AUPassport" &&
                <div id='As_Passport'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Australian Passport Number</p>
                            <Form.Field>
                                <input

                                    {...register(`auPassportNumber`, {
                                        required: true,
                                        pattern:/^[A-Za-z]{1,2}[0-9]{7}$/,
                                        value: oppContactData ? oppContactData.auPassportNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.auPassportNumber && <p className="text-danger">Please enter a valid passport number.</p>}

                        </div>
                    </div>
                </div>}

            {selectedIdType === "AUMedicareCard" &&
                <div id='AMC'>
                    <div className="row mb-4">
                        <div className="col-sm-6 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Medicare Card Number</p>
                            <Form.Field>
                                <input

                                    {...register(`medicareNumber`, {
                                        required: true,
                                        pattern:/[0-9]{10}/,
                                        value: oppContactData ? oppContactData.medicareNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.medicareNumber && <p className="text-danger">Please enter a valid medicare card number.</p>}

                        </div>
                        <div className="col-sm-6 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Full Name as it appears on your medicare card</p>
                            <Form.Field>
                                <input

                                    {...register(`medicareName`, {
                                        required: true,
                                     pattern:/[A-Za-z0-9\s\-]*/,
                                        value: oppContactData ? oppContactData.medicareName : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.medicareName && <p className="text-danger">Please enter a valid name.</p>}

                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Individual Reference Number</p>
                            <Form.Field>
                                <Select
                                    style={{ width: "100%", minWidth: "100%" }}
                                    refs={register(`medicareIRN`, {
                                        required: true,
                                        value: oppContactData ? oppContactData.medicareIRN : ''
                                    })}
                                    defaultValue={oppContactData ? oppContactData.medicareIRN : null}

                                    placeholder="Choose..."
                                    onChange={(e, data) => {
                                        setValue(`medicareIRN`, `${data.value}`)
                                    }}

                                    options={[
                                        { text: "1", value: "1" },
                                        { text: "2", value: "2" },
                                        { text: "3", value: "3" },
                                        { text: "4", value: "4" },
                                        { text: "5", value: "5" },
                                        { text: "6", value: "6" },
                                        { text: "7", value: "7" },
                                        { text: "8", value: "8" },
                                        { text: "9", value: "9" }
                                    ]}
                                />
                            </Form.Field>
                            {errors?.medicareIRN && <p className="text-danger">Please select an option</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Valid To</p>
                            <Form.Field>
                                <input
                                    {...register(`medicareExpiry`, {
                                        required: true,
                                        pattern: /^((0[1-9])|(1[0-2]))\/((2022)|(20[2][0-9]))$/,
                                        value: oppContactData && oppContactData.medicareExpiry ?(oppContactData.medicareExpiry.split('-').slice(0,2).reverse().join("/")) : ''
                                    })}
                                    placeholder={"MM/YYYY"}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.medicareExpiry && <p className="text-danger">Please enter a valid medicare card expiry in the format as MM/YYYY.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Colour</p>
                            <Form.Field>
                                <Select
                                    style={{ width: "100%", minWidth: "100%" }}
                                    refs={register(`medicareType`, {
                                        required: true,
                                        value: oppContactData ? oppContactData.medicareType : ''
                                    })}
                                    defaultValue={oppContactData ? oppContactData.medicareType : null}
                                    onChange={(e, data) => {
                                        setValue(`medicareType`, `${data.value}`)
                                    }}
                                    placeholder="Choose..."
                                    options={[
                                        { text: "Blue", value: "B" },
                                        { text: "Green", value: "G" },
                                        { text: "Yellow", value: "Y" }
                                    ]}
                                />
                            </Form.Field>
                            {errors?.medicareType && <p className="text-danger">Please select an option</p>}

                        </div>
                    </div>
                </div>}

            {selectedIdType === "NZBirthCertificate" &&
                <div id='NZBC'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>New Zealand Birth Certificate Number</p>
                            <Form.Field>
                                <input

                                    {...register(`nzBirthdayCertNumber`, {
                                        required: true,
                                        pattern:/^[0-9]+$/,
                                        value: oppContactData ? oppContactData.nzBirthdayCertNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.nzBirthdayCertNumber && <p className="text-danger">Please enter a valid number for the birth certificate.</p>}

                        </div>
                    </div>
                </div>}

            {selectedIdType === "NZCitizenshipCertificate" &&
                <div id='NZ_CC'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>New Zealand Citizenship Certificate Number</p>
                            <Form.Field>
                                <input

                                    {...register(`nzCitizenCertNumber`, {
                                        required: true,
                                        value: oppContactData ? oppContactData.nzCitizenCertNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.nzCitizenCertNumber && <p className="text-danger">This field is required.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Country of Birth</p>
                            <Form.Field>
                                <Select
                                    style={{ width: "100%", minWidth: "100%" }}
                                    refs={register(`nzCountryOfBirth`, {
                                        // required:true,
                                        value: oppContactData ? oppContactData.nzCountryOfBirth : ''
                                    })}
                                    placeholder="Choose..."
                                    onChange={(e, data) => {
                                        setValue("nzCountryOfBirth", `${data.value}`)
                                    }}

                                    options={countries}
                                />
                            </Form.Field>

                        </div>
                    </div>
                </div>}

            {selectedIdType === "NZPassport" &&
                <div id='NZ_PassPort'>
                    <div className="row mb-4">
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>New Zealand Passport Number</p>
                            <Form.Field>
                                <input

                                    {...register(`nzPassportNumber`, {
                                        required: true,
                                        pattern:/^[A-Za-z]{1,2}[0-9]{6}$/,
                                        value: oppContactData ? oppContactData.nzPassportNumber : ''
                                    })}
                                    type={"text"} />
                            </Form.Field>
                            {errors?.nzPassportNumber && <p className="text-danger">Please enter a valid passport number.</p>}

                        </div>
                        <div className="col-sm-4 pb-2 pb-sm-0">
                            <p className='mb-1 pre-heading-h6'>Expiry Date</p>
                            <Form.Field>
                                <input

                                    {...register(`nzPassportExpiry`, {
                                        required: true,
                                        value: oppContactData ? oppContactData.nzPassportExpiry : ''
                                    })}
                                    type={"date"} />
                            </Form.Field>
                            {errors?.nzPassportExpiry && <p className="text-danger">This field is required.</p>}

                        </div>
                    </div>
                </div>}

        </div>
    )
}

export default IdFields