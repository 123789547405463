import React from 'react';

export default function Footer({countryCode} : any) {

  return (
    <footer className='d-flex w-100 border-top'>
      <div className='container-lg'>
        <div className='row'>
          <div className='col mb-0 py-4'>
            {(countryCode === "AU") &&
            <p className='mb-0'>
              © 2020-{new Date().getFullYear()} Property Credit • ACN 638 329 674 23 • Australian
              Credit Licence No. 528878. • <a href="https://property.credit/policies/" target="_blank">Policy Information</a>
            </p>
            }
            {(countryCode === "NZ") &&
            <p className='mb-0'>
              © 2020-{new Date().getFullYear()} Property Credit • NZBN 9429050855445 • FSP 1003655 • <a href="https://property.credit/policies/" target="_blank">Policy Information</a>
            </p>
            }
          </div>
        </div>
      </div>
    </footer>
  );
}
