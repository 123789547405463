import React from 'react'
import { Select, Input, Form } from 'semantic-ui-react';

const PropertyManager = (props) => {
    const { register,errors,oppContactData } = props;

    return (
        <div>
            <div className="card-body border-bottom">
                <h2 className='h2 mb-3'>Property Manager Details </h2>
            </div>
            <div className="card-body ">
                <div className="row">
                    <div className="col-sm-6 pb-2 pb-sm-0">
                        <p className='mb-1 pre-heading-h6'>Property Manager's Name</p>
                        <Form.Field>
                            <input
                                type={"text"}
                                {...register("pmName", {
                                    required:true,
                                    value:oppContactData?oppContactData.pmName:''
                                })}

                            />
                        </Form.Field>
                        {errors?.pmName && <p className="text-danger">This field is required.</p>}

                    </div>
                    <div className="col-sm-6 pb-2 pb-sm-0">
                        <p className='mb-1 pre-heading-h6'>Property Manager's Phone</p>
                        <Form.Field>
                            <input
                                {...register("pmPhone", {
                                    required:true,
                                    value:oppContactData?oppContactData.pmPhone:''
                                })}
                                type={"number"} />
                        </Form.Field>
                        {errors?.pmPhone && <p className="text-danger">This field is required.</p>}

                    </div>

                </div>
            </div>

            <div className="card-body border-bottom">
                <div className="row">

                    <div className="col-sm-6 pb-2">
                        <p className='mb-1 pre-heading-h6'>Property Manager's Email</p>
                        <Form.Field>
                            <input
                                style={{ width: "100%" }}
                                {...register("pmEmail",{
                                    required:true,
                                    value:oppContactData?oppContactData.pmEmail:''
                                })}
                                type={"email"} />
                        </Form.Field>
                        {errors?.pmEmail && <p className="text-danger">This field is required.</p>}

                    </div>

                </div>
            </div>

        </div>
    )
}

export default PropertyManager