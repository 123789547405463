import React from 'react';

const Declaration = (props) => {
  const { register, errors } = props;

  console.log(errors);

  return (
    <div>
      <div className='card-body border-bottom'>
        <h2 className='h2 mb-3'>Declaration</h2>
        <div className='form-check form-check-inline'>
          <input
            className='form-check-input'
            type='checkbox'
            // name='Declaration'
            // id='Declaration'
            {...register('declaration', {
              validate: {
                isTrue: (v) => v === true,
              },
              required: 'Please accept the declaration',
            })}
            // onChange={(e)=>setDeclaration(e.target.checked)}
          />
          <label className='' htmlFor='Declaration'>
            I confirm that I am authorised to provide the personal details
            presented and I consent to my information being passed to and
            checked with the document issuer, official record holder, a credit
            bureau and authorised third parties for the purpose of verifying my
            identity and address.
          </label>
          {errors?.declaration && (
            <p className='text-danger mt-2'>{errors?.declaration?.message}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Declaration;
