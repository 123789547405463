import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import NavigationBar from '../components/NavigationBar';
import BankAccounts from '../components/forms/BankAccounts.tsx';
import LoanOffer from '../components/forms/LoanOffer.tsx';
import LoansOffer from '../components/forms/LoansOffer.tsx';
import BankStatements from '../components/forms/BankStatements.tsx';
import Contracts from '../components/forms/Contracts.tsx';
import Facilities from '../components/forms/Facilities.tsx';
import ConfirmationPage from '../components/forms/ConfirmationPage';
import OpportunityAPI from '../api/salesforce/OpportunityAPI.tsx';
import DocumentsUploader from '../components/forms/DocumentsUploader';
import ContactComponent from '../components/forms/ContactComponent';
import Footer from '../components/Footer';
import RepaymentPath from '../components/forms/RepaymentPath';

export default function MainForm() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsContentID = searchParams.get('OC');
  const DocumentUpload = searchParams.get('DocumentUpload');
  const ContactUpdate = searchParams.get('ContactUpdate');
  const { oppId } = useParams();
  const [accountVerificationShow, setAccountVerificationShow] = useState(false);
  const [opportunity, setOpportunity] = useState(null);
  const [currentContact, setCurrentContact] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [lastStep, setLastStep] = useState(1);
  const [pageMap, setpageMap] = useState(new Map());
  const [userIp, setuserIp] = useState('');

  const Pages = {
    1: ContactComponent,
    3: DocumentsUploader,
    2: BankStatements,
    4: Facilities,
    6: LoanOffer,
    7: LoansOffer,
    8: BankAccounts,
    9: RepaymentPath,
    10: Contracts,
    11: ConfirmationPage,
  };

  const fetchOppData = async () => {
    try {
      if (!oppId) {
        throw new Error('No opportunity ID found');
      }
      let opportunity = await OpportunityAPI.getOpportunity(oppId);
      console.log(opportunity);
      if (!opportunity) throw new Error('Invalid opportunity ID');

      localStorage.setItem('productCode', opportunity?.productCode);
      //AddScript(opportunity?.productCode);

      if (opportunity.dataOwner === 'Auctions Live') {
        document.body.className = 'partner-auctions-live';
      }
      if (opportunity.dataOwner === 'Tapi') {
        document.body.className = 'partner-tapi';
      }

      let currentUserIsBorrower = false;

      if (!currentContact && !paramsContentID) {
        let contact = opportunity?.contacts.filter(
          (user) => user.isBorrower,
        )[0];
        console.log('setting current contact to :', contact?.firstName);
        currentUserIsBorrower = contact?.isBorrower;
        setCurrentContact(contact);
      }
      if (paramsContentID) {
        let contact = opportunity?.contacts.filter(
          (user) => user.id === paramsContentID,
        )[0];
        currentUserIsBorrower = contact?.isBorrower;
        console.log('setting current contact:', contact?.firstName);
        setCurrentContact(contact);
      }

      let hasOfferedLoans =
        opportunity?.loanList?.filter((loan: any) => loan.stage === 'Offered')
          .length > 0;

      let formPages = [];

      if (currentUserIsBorrower) {
        if (DocumentUpload) {
          formPages.push(DocumentsUploader);
        } else if (ContactUpdate) {
          formPages.push(ContactComponent);
        } else {
          if (opportunity?.contact === 'required') {
            formPages.push(ContactComponent);
          }
          if (opportunity?.documents === 'required') {
            formPages.push(DocumentsUploader);
          }
          if (opportunity?.bankStatements === 'required') {
            formPages.push(BankStatements);
          }
          if (opportunity?.bankAccount === 'required') {
            formPages.push(BankAccounts);
          }

          if (opportunity?.opportunityStage === 'Facility Offered') {
            formPages.push(Facilities);
          }

          if (hasOfferedLoans) {
            formPages.push(LoansOffer);
          } else if (
            opportunity?.opportunityStage === 'Loan Offered' ||
            opportunity?.opportunityStage === 'Loan Setup'
          ) {
            formPages.push(LoanOffer);
          }
          if (
            (hasOfferedLoans ||
              opportunity?.opportunityStage === 'Loan Offered' ||
              opportunity?.opportunityStage === 'Loan Setup') &&
            opportunity?.productCode !== 'PEF' &&
            opportunity?.productCode !== 'TAB' &&
            opportunity?.productCode !== 'TAB-DRAW' &&
            opportunity?.productCode !== 'GST'
          ) {
            formPages.push(RepaymentPath);
          }
        }
      }

      if (
        (opportunity?.opportunityStage === 'Loan Offered' ||
          opportunity?.opportunityStage === 'Facility Offered') &&
        opportunity?.contract === 'required'
      ) {
        formPages.push(Contracts);
      }

      if (opportunity?.confirmation === 'required') {
        formPages.push(ConfirmationPage);
      }

      let pageMap = new Map();
      formPages.forEach((page, index) => {
        pageMap.set(index + 1, page);
      });
      setpageMap(pageMap);
      setOpportunity(opportunity);
    } catch (err) {
      navigate('/application/ThankYou', {
        state: {
          error: {
            message: err instanceof Error ? err.message : 'Error',
          },
        },
      });
    }
  };

  useEffect(() => {
    fetchOppData();
    fetch('https://api.ipify.org/?format=json')
      .then((response) => response.json())
      .then((res) => {
        setuserIp(res?.ip);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const updateCurrentContact = (newContact) => {
    setCurrentContact(...newContact);
  };

  const nextStep = () => {
    setLastStep(currentStep);
    setCurrentStep(currentStep + 1);
  };
  const prevStep = () => {
    setLastStep(currentStep);
    setCurrentStep(currentStep - 1);
  };

  const PageContent = () => {
    if (pageMap?.size > 0) {
      const props = {
        prevStep: prevStep,
        nextStep: nextStep,
        lastStep: lastStep,
        currentStep: currentStep,
        totalPage: pageMap.size - 1,
        oppId: oppId,
        userIp,
        opportunity,
        paramsContentID,
        currentContact,
        accountVerificationShow,
        setAccountVerificationShow,
        setOpportunity,
        setCurrentContact,
        updateCurrentContact,
      };
      console.log('props', props);
      return React.createElement(
        React.Fragment,
        null,
        React.createElement(pageMap.get(currentStep), props),
      );
    } else {
      const props = {
        prevStep,
        nextStep,
        lastStep,
        currentStep,
        totalPage: Pages.size - 1,
        oppId: oppId,
        userIp,
        paramsContentID,
        opportunity,
        currentContact,
        accountVerificationShow,
        setAccountVerificationShow,
        setCurrentContact,
        setOpportunity,
        updateCurrentContact,
      };

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(Pages[currentStep], props),
      );
    }
  };

  return (
    <React.Fragment>
      {opportunity ? (
        <>
          <NavigationBar dataOwner={opportunity.dataOwner} />
          <main className='pc-page-content-wrapper flex-grow-1'>
            <div className='container-md pb-5'>
              <div className='row'>
                <div className='col-lg-10 offset-lg-1'>
                  <div className='body'>
                    <PageContent currentStep={currentStep} />
                  </div>
                </div>
              </div>
            </div>
          </main>
          <Footer countryCode={opportunity.countryCode} />
        </>
      ) : (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
