const formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
  useGrouping: 'always',
});

/**
 * Utility function for formatting a number (e.g. a bank balance) as currency
 * e.g. 123.45 will be formatted as $123.45
 */
export default function formatCurrency(value) {
  let formattedValue = formatter.format(value);
  if (formattedValue === "-$0.00") {
    formattedValue = formatter.format(Math.abs(value));
  }
  return formattedValue;
}
