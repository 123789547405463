import React from 'react';
import { useParams } from 'react-router-dom';
import NavigationBar from '../NavigationBar';
import dateFormat from '../../utils/dateFormat';

export default function ConfirmationPage(props: any) {
  console.log('confirm props', props);
  const { dataOwner } = useParams();

  const continueUrl = (function () {
    if (!props.opportunity) {
      if (dataOwner === 'Tapi') return 'https://tapi.app/';
      return 'https://www.property.credit';
    }

    if (
      props.opportunity.opportunityStage === 'Loan Funded' ||
      props.opportunity.opportunityStage === 'Load Amended'
    )
      return `/application/loanDetails/${props.opportunity.opportunityID}`;

    return props.opportunity.returnUrl;
  })();

  const payNowUrl = (function () {
    let payNowLink = 'https://property.credit/pay-now';
    if (!props.opportunity) {
      return payNowLink;
    } else {
      payNowLink += '/?appId=' + props.opportunity.opportunityID;
      payNowLink += '&app=' + props.opportunity.opportunityName;
      payNowLink += '&a=250.00';
      payNowLink += props.opportunity.shortAddress
        ? '&for=' + props.opportunity.loan?.expenseReason
        : '';
      payNowLink += props?.currentContact?.firstName
        ? '&fn=' + props?.currentContact?.firstName
        : '';
      payNowLink += props?.currentContact?.lastName
        ? '&ln=' + props?.currentContact?.lastName
        : '';
      payNowLink += props.opportunity.agencyName
        ? '&to=' + props.opportunity.agencyName
        : '';
      payNowLink += props.opportunity.shortAddress
        ? '&pstreet=' + props.opportunity.shortAddress
        : '';
      payNowLink += props.opportunity.propertySuburb
        ? '&psuburb=' + props.opportunity.propertySuburb
        : '';
      payNowLink += props.opportunity.propertyPostCode
        ? '&ppost=' + props.opportunity.propertyPostCode
        : '';
      payNowLink += props.opportunity.propertyState
        ? '&pstate=' + props.opportunity.propertyState
        : '';
      payNowLink += props.opportunity.country
        ? '&pcountry=' + props.opportunity.country
        : '';
      payNowLink += props?.currentContact?.email
        ? '&e=' + props?.currentContact?.email
        : '';
      payNowLink += props?.currentContact?.mobile
        ? '&mob=' + props?.currentContact?.mobile
        : '';
    }
    console.log(payNowLink);
    return payNowLink;
  })();
  return (
    <React.Fragment>
      {!props.opportunity && <NavigationBar dataOwner={dataOwner} />}
      <div className='row pb-5'>
        <div className='col-12'>
          <h5 className='mb-1 pre-heading'>
            {props?.opportunity?.opportunityName}
          </h5>
          <h1 className='mb-4 lh-1'>{props?.opportunity?.shortAddress}</h1>

          {props?.opportunity?.opportunityStage === 'Loan Signed' &&
          props?.opportunity?.productCode === 'INV' ? (
            <>
              <p className='mt-4 mb-4'>
                Congratulations {props?.currentContact?.firstName}, there's
                nothing more you need to do for now.
              </p>

              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    <p>We'll transfer the funds within one business day.</p>
                    <p>
                      Your Agency will pay your invoice and you'll{' '}
                      <b>
                        pay us ${props?.opportunity?.loan.instalmentsAmount} on{' '}
                        {dateFormat(
                          new Date(props?.opportunity?.loan.repaymentNextDate),
                        )}
                      </b>
                      .
                    </p>
                    <p>
                      We’ll contact you the week before to advise payment
                      methods.
                    </p>
                    <p>
                      To learn more about using FastPay for more invoices, click
                      the button below.
                    </p>
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={continueUrl}>
                          Continue
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : props?.opportunity?.opportunityStage === 'Loan Signed' ? (
            <>
              <p className='mt-4 mb-4'>
                Congratulations {props?.currentContact?.firstName}, there's
                nothing more you need to do for now.
              </p>

              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    <p>We'll transfer the funds within one business day.</p>
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={continueUrl}>
                          Continue
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : props?.opportunity?.opportunityStage === 'Loan Funded' ? (
            <>
              <p className='mt-4 mb-4'>
                Congratulations {props?.currentContact?.firstName}.
              </p>
              <p>
                We've sent ${props?.opportunity?.loan.disbursementAmount}
                {props?.opportunity?.loan.expenseReason &&
                  ` for ${props?.opportunity?.loan.expenseReason}`}
                .
              </p>

              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    {(props?.opportunity?.productCode === 'TAB-DRAW' ||
                      props?.opportunity?.productCode === 'PEF') && (
                      <p>
                        Your next payment of $
                        {props?.opportunity?.loan.instalmentsAmount} is due on{' '}
                        {dateFormat(
                          new Date(props?.opportunity?.loan.repaymentNextDate),
                        )}
                        .
                      </p>
                    )}
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={continueUrl}>
                          Continue
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (props?.opportunity?.opportunityStage === 'Facility Signed' ||
              props?.opportunity?.opportunityStage === 'Facility Activated') &&
            props?.opportunity?.productCode === 'TAB' ? (
            <>
              <p className='mt-4 mb-4'>
                <b> Congratulations {props?.currentContact?.firstName}!</b> Your
                Property Tab has been activated.
              </p>
              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    <p>
                      Now that your Property Tab is active, when you want to pay
                      an invoice later, just add it to your Tab!
                    </p>
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={continueUrl}>
                          Continue
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : props?.opportunity?.productCode === 'TAB' ||
            props?.opportunity?.productCode === 'TAB-DRAW' ||
            props?.opportunity?.productCode === 'INV' ? (
            <>
              <p className='mt-4 mb-4'>
                <b> Thanks {props?.currentContact?.firstName}! </b> We have got
                your application and we will take it from here.
              </p>
              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    <p>
                      We will contact you with updates. In the meantime, head
                      back to your Tapi portal.
                    </p>
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={continueUrl}>
                          Continue
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (props?.opportunity?.opportunityStage === 'Facility Signed' ||
              props?.opportunity?.opportunityStage === 'Facility Activated') &&
            props?.opportunity?.productCode === 'ERC' &&
            props?.opportunity?.isDepositForFacility === true ? (
            <>
              <p className='mt-4 mb-4'>
                <b> Thank you {props?.currentContact?.firstName}, </b> for
                signing your Property Deposit Facility agreement.
              </p>
              <div className='row gx-0'>
                <div className='card p-3 py-4'>
                  <div className='col-sm-10'>
                    <h3 className='mb-3'>What's next?</h3>

                    <p>
                      You will be now taken to our payment page to process your
                      $250 facility activation fee. Your facility is not active
                      until payment has been made.
                    </p>
                    <p>
                      {props.opportunity && (
                        <a className='btn btn-primary' href={payNowUrl}>
                          Go to Payment Page
                        </a>
                      )}
                    </p>
                    <p>
                      Feel free to contact us at any time:
                      <br />
                      On 1300 829 536 or{' '}
                      <a href='mailto:funding@property.credit' target='_blank'>
                        funding@property.credit
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <p>Thanks {props?.currentContact?.firstName}</p>
              <p>
                We'll continue to process your application and get back in touch
                with you shortly.
              </p>
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
