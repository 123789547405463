import noIdJson from '../../data/noId.json';
import noBankStatementJson from '../../data/noBankStatement.json';
import oppContactJson from '../../data/oppContact.json';
import axios, { AxiosError } from 'axios';

function getOpportunity(oppId: any) {
  switch (oppId) {
    case 'oppContact':
      return oppContact();
    case 'no-id':
      return noId();
    case 'no-bankstatement':
      return noBankStatement();
    default:
      return fetchData(oppId);
  }
}

async function fetchData(oppId: any) {
  console.log('OpportunityAPI.fetchData(oppId)');
  let data = '';

  let payload = { opportunityID: oppId };

  let config = {
    method: 'POST',
    url: `/services/apexrest/getOpportunity`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function saveOpportunity(dataObj: any) {
  let data = '';

  let payload = { ...dataObj };

  let config = {
    method: 'POST',
    url: `/services/apexrest/saveOpportunity`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function saveOppContact(dataObj: any) {
  let data = '';

  let payload = { ...dataObj };

  let config = {
    method: 'POST',
    url: `/services/apexrest/saveOppContact`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function updateOppContact(updateObj: any): Promise<any> {
  let data = '';

  let config = {
    method: 'POST',
    url: `/services/apexrest/updateOppContact`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: updateObj,
  };
  //console.log('config', config);
  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
function oppContact() {
  return oppContactJson != null ? oppContactJson : null;
}
async function getContractData(payloadData: any) {
  let data = '';

  let payload = { ...payloadData };

  let config = {
    method: 'POST',
    url: `/services/apexrest/getContract`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function saveContractData(contractData: any) {
  let data = '';

  let payload = { ...contractData };

  let config = {
    method: 'POST',
    url: `/services/apexrest/saveContract`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      data = response.data;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}
async function sendBankAccountDataToSalesforce(selectedBankData: any) {
  let data = '';

  let payload = { ...selectedBankData };

  let config = {
    method: 'POST',
    url: `/services/apexrest/saveBankAccount`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };
  //console.log('config', config);
  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      //console.log('response', response);
      data = response.data;
      //console.log('data', data);
    }
    return data;
  } catch (err) {
    console.log(err);
  }
}

const propertyCreditSalesforce = axios.create({
  baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers':
      'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
  },
});

async function addNewBankAccount(body: {
  opportunityID: string;
  contactID: string;
  bankAccount: {
    accountBSB: string;
    accountNumber: string;
    accountName: string;
  };
}) {
  const res = await propertyCreditSalesforce.post(
    '/services/apexrest/newBankAccount',
    body,
  );

  return res;
}

async function getOpportunityStatus(oppId: any): Promise<any> {
  let data = '';

  let payload = { opportunityID: oppId };

  let config = {
    method: 'POST',
    url: `/services/apexrest/getOpportunityStatus`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };
  //console.log('config', config);
  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  console.log('data', data);
  return data;
}
async function getDocuments(oppId: any): Promise<any> {
  console.log('OpportunityAPI.getDocuments');
  let data = '';

  let payload = { opportunityID: oppId };

  let config = {
    method: 'POST',
    url: `/services/apexrest/getDocument`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
async function getOppContact(oppId: any): Promise<any> {
  let data = '';

  let payload = { opportunityID: oppId };

  let config = {
    method: 'POST',
    url: `/services/apexrest/getOppContact`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: payload,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
async function updateDocument(updateObj: any): Promise<any> {
  let data = '';

  let config = {
    method: 'POST',
    url: `/services/apexrest/updateDocument`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: updateObj,
  };
  //console.log('config', config);
  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
async function saveDocument(documentObj: any): Promise<any> {
  let data = '';

  let config = {
    method: 'POST',
    url: `/services/apexrest/saveDocument`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
    data: documentObj,
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
async function getDocumentCategory(): Promise<any> {
  let data = '';
  let config = {
    method: 'GET',
    url: `/services/apexrest/getDocumentCategory`,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
      'Access-Control-Allow-Headers':
        'Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length',
    },
  };

  try {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
    });
    let response = await instance(config);
    if (response) {
      console.log('response', response);
      data = response.data;
    }
  } catch (err) {
    console.log('err', err);
    data = '{"success" : false, "error": "' + err + '"}';
  }
  return data;
}
function noId() {
  return noIdJson != null ? noIdJson : null;
}

function noBankStatement() {
  return noBankStatementJson != null ? noBankStatementJson : null;
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_SALESFORCE_API_URL,
})

function updateContactBankStatementStatus(body: {
  oppConId: string,
  bankStatementStatus: "Pending"| "Requested"
}) {
  return instance.post("/services/apexrest/updateContact", body)
}


export default {
  getOpportunity,
  getContractData,
  saveContractData,
  sendBankAccountDataToSalesforce,
  addNewBankAccount,
  getOpportunityStatus,
  getDocuments,
  saveDocument,
  getDocumentCategory,
  updateDocument,
  saveOpportunity,
  saveOppContact,
  getOppContact,
  updateOppContact,
  updateContactBankStatementStatus
};
