import React, { useEffect, useState } from "react";
import {useNavigate, useParams} from 'react-router-dom'
import OpportunityAPI from "../api/salesforce/OpportunityAPI";

export default function OppDetails()  {

	const { oppId } = useParams();

	const [oppDetail, setOppDetail] = useState<any>();
	
	const navigate = useNavigate();
	console.log(`loading Opportunity with opportunity id ${oppId}`);

	const fetchOppData = async () => {
		console.log("OppDetails.fetchData()", oppId);

		if (!oppId) {
			navigate('/application/ThankYou');
		return;
		}
		
		let oppDetail : any;
		 oppDetail = await OpportunityAPI.getOpportunity(oppId)!;

		//console.log(oppDetail);
		
		if (!oppDetail) {
			navigate('/application/ThankYou');
			return;
    	}

		setOppDetail(oppDetail);
	}

	useEffect(()=> {
		fetchOppData();
		//const values = [...pageMap.values()];
	},[]);


	const Opportunity = ({}) => {
		// (destructured) data could be a prop for example
		return (<div><pre>{ JSON.stringify(oppDetail, null, 2) }</pre></div>);
	}
/* 
	const Opportunity = () => {

		if (oppDetail) {
			return React.createElement(
				React.Fragment,
				null,
				React.createElement('Opportunity', {JSON.stringify(oppDetail, null, 2) })
			);
		}  */

	return(
		<React.Fragment>
			<main className="pc-page-content-wrapper flex-grow-1">
				<div className="container-lg">
					<div className="row pb-5">
						<div className="col-sm-8 col-md-7 col-lg-6 col-xl-5 offset-md-1 offset-lg-1 offset-xl-2">
							<div className="mt-sm-5 mt-md-5 pt-lg-3 pt-xl-5 text-center text-sm-start">
								
								<h1 className="h4">Opportunity Detail for {oppId}</h1>
								<Opportunity />
							</div>
						</div>
					</div>
				</div>
        </main>
		</React.Fragment>
	)

}