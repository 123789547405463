import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Person from './Person';
import Entity from './Entity';
import Identification from './Identification';
import IdentificationSingle from './IdentificationSingle';
import Declaration from './Declaration';
import AddressDetails from './AddressDetails';
import { Select, Input, Form, Checkbox } from 'semantic-ui-react';
import OpportunityAPI from '../../api/salesforce/OpportunityAPI';
import { useNavigate } from 'react-router-dom';
import dateStringFormat from '../../utils/dateStringFormat';

import { individualTypes, companyTypes } from '../forms/ContactComponent.tsx';

const ContactDetails = (props) => {
  let loanAmount = 0;
  loanAmount = props.opportunity.facilityDetails.facilityUsedPending;
  const {
    oppContactData,
    setSave,
    setIsSaved,
    identity,
    options,
    applicantEntities,
  } = props;

  // TODO: show other fields depending on oppContactData and prefill data
  // either identity is defined, or oppContactData is
  // get identity from oppContactData

  // const [identity, setIdentity] = useState(
  //   oppContactData && oppContactData.type ? oppContactData.type : 'Individual',
  // );
  const [entity, setEntity] = useState(
    oppContactData && oppContactData.entityType
      ? oppContactData.entityType
      : '',
  );
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    console.log(data);
    setloading(true);
    // console.log('form data', data);
    let obj = {
      opportunityID: props.oppId,
      oppContact: {},
    };
    if (data.type === 'Company') {
      obj.oppContact = {
        ...data.entity,
        entityType: data.entityType,
        type: data.type,
        isApplicant: data.isApplicant,
      };
    } else {
      obj.oppContact = {
        ...data,
        // isApplicant:
        //   data.isApplicant === 'true' || data.isApplicant === true
        //     ? true
        //     : data.isApplicant === 'false' || data.isApplicant === false
        //     ? false
        //     : data.dateOfBirth != null,
      };
    }
    if (obj.oppContact.medicareExpiry) {
      let str = '01/' + obj.oppContact.medicareExpiry;
      obj.oppContact.medicareExpiry = dateStringFormat(str, '/', '-', true);
    }
    console.log(data);
    // return;

    // console.log("payload Data",obj)
    if (obj.oppContact.id) {
      delete obj.opportunityID;
      OpportunityAPI.updateOppContact(obj)
        .then((res) => {
          // console.log({ res })
          if (!res) {
            setloading(false);
            navigate('/application/ThankYou');
            return;
          }
          setSave(false);
          setIsSaved((pre) => !pre);
          setloading(false);
        })
        .catch((err) => {
          console.log({ err });
          setloading(false);
        });
    } else {
      OpportunityAPI.saveOppContact(obj)
        .then((res) => {
          if (!res) {
            setloading(false);
            navigate('/application/ThankYou');
            return;
          }
          setSave(false);
          setIsSaved((pre) => !pre);
          setloading(false);
        })
        .catch((err) => {
          console.log({ err });
          setloading(false);
        });
    }
  };

  const isEntitiyTypeApplicant = (function () {
    // no applicant entities given, assume not applicant
    // console.log(applicantEntities);
    if (oppContactData?.isApplicant) return true;

    if (!applicantEntities) return false;
    const selectedEntity = getValues('entityType');
    // console.log(applicantEntities, selectedEntity, oppContactData);
    return applicantEntities.includes(selectedEntity);
  })();

  useEffect(() => {
    setValue('isApplicant', isEntitiyTypeApplicant);
  }, [isEntitiyTypeApplicant, setValue]);

  return (
    <div className='card mb-5 overflow-visible'>
      {loading && (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className='card-body border-bottom'>
          <h2 className='h2 mb-3'>
            {identity === 'Company' ? 'Company' : 'Contact'} Details
          </h2>

          <input type='hidden' {...register('isApplicant')} />
          <input type='hidden' {...register('type')} value={identity} />
        </div>
        <div className='card-body border-bottom'>
          <div className='row'>
            <div className='col-sm-4 pb-2'>
              <p className='mb-1 pre-heading-h6'>
                {identity === 'Individual' ? 'Person' : 'Company'} Type
              </p>
              <Form.Field>
                <Select
                  style={{ width: '100%', minWidth: '100%' }}
                  refs={register(`entityType`, {
                    required: true,
                    value:
                      oppContactData && oppContactData.entityType
                        ? oppContactData.entityType
                        : '',
                  })}
                  onChange={(e, data) => {
                    setValue('entityType', `${data.value}`);
                    setEntity(data.value);
                  }}
                  value={entity}
                  placeholder='Choose...'
                  options={
                    (options
                      ? options[
                          identity === 'Individual'
                            ? 'individuals'
                            : 'companies'
                        ]
                      : identity === 'Individual'
                      ? individualTypes
                      : companyTypes
                    ).map((t) => ({ value: t, text: t }))
                    //   [
                    //   {
                    //     value: 'Admin/Office Manager',
                    //     text: 'Admin/Office Manager',
                    //   },
                    //   { value: 'Agency', text: 'Agency' },
                    //   { value: 'Agent', text: 'Agent' },

                    //   { value: 'Agent Entity', text: 'Agent Entity' },
                    //   { value: 'Broker', text: 'Broker' },
                    //   {
                    //     value: 'Joint Property Owner',
                    //     text: 'Joint Property Owner',
                    //   },
                    //   { value: 'Principal', text: 'Principal' },
                    //   { value: 'Property Owner', text: 'Property Owner' },

                    //   {
                    //     value: 'Property Management Agency',
                    //     text: 'Property Management Agency',
                    //   },
                    //   { value: 'Property Manager', text: 'Property Manager' },
                    //   { value: 'Third Party', text: 'Third Party' },
                    //   { value: 'Vendor Lawyer', text: 'Vendor Lawyer' },
                    //   { value: 'Vendor Lawyer Firm', text: 'Vendor Lawyer Firm' },
                    // ]
                  }
                />
              </Form.Field>
              {errors?.entityType && (
                <p className='text-danger'>This field is required.</p>
              )}
            </div>
          </div>
        </div>
        {identity === 'Individual' ? (
          <>
            <Person
              {...props}
              register={register}
              errors={errors}
              unregister={unregister}
              setValue={setValue}
              borrower={isEntitiyTypeApplicant}
            />
            {isEntitiyTypeApplicant && (
              <>
                <AddressDetails
                  {...props}
                  register={register}
                  setValue={setValue}
                  getValues={getValues}
                  errors={errors}
                />

                {loanAmount > 35000 ? (
                  <Identification
                    {...props}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                ) : (
                  <IdentificationSingle
                    {...props}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                  />
                )}
              </>
            )}
          </>
        ) : (
          identity === 'Company' && (
            <>
              <Entity
                {...props}
                register={register}
                unregister={unregister}
                errors={errors}
                setValue={setValue}
              />
            </>
          )
        )}

        {((getValues('entityType') === undefined &&
          applicantEntities.includes(entity)) ||
          applicantEntities.includes(getValues('entityType'))) && (
          <Declaration {...props} register={register} errors={errors} />
        )}

        <div className='card-body border-bottom pt-3 pb-2'>
          <button className='btn btn-primary me-2 mb-2' type={'submit'}>
            Save Details
          </button>
          <button
            className='btn btn-outline-secondary me-2 mb-2'
            onClick={() => setSave(false)}
          >
            Cancel
          </button>
        </div>
      </Form>
    </div>
  );
};

export default ContactDetails;
