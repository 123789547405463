import React from 'react';
import tapiLogo from '../assets/images/tapi-logo_with text_dark.svg';
import pcLogo from '../assets/images/powered-by-property-credit.svg';
import pcLogo1 from '../assets/images/pc-logo.svg';

export default function NavigationBar({dataOwner} : any) {
  return (
    <nav
      className='navbar navbar-expand-md navbar-light bg-white'
      aria-label='main navbar'
    >
      <div className='container-lg py-md-3'>
      {(dataOwner === 'Tapi') ?
        <div className='d-flex w-100 justify-content-between'>
          <div className='d-flex flex-grow-1 partner-logo-wrapper'> 
            <img src={tapiLogo} /> 
          </div>
          <div className='d-flex flex-grow-1 justify-content-end pc-logo-wrapper'>
            <img src={pcLogo} />
          </div>
        </div>:
        <div className='pc-logo-wrapper'>
        <img src={pcLogo1} />
      </div>
        }
      </div>
    </nav>
  );
}
