import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import OpportunityAPI from '../api/salesforce/OpportunityAPI';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import WithdrawModal from './actions/WithdrawModal';
import PaymentModal from './actions/PaymentModal';
import NavigationBar from './NavigationBar';
import { Select, Input, Form } from 'semantic-ui-react';
import dateStringFormat from '../utils/dateStringFormat';
import { useForm } from 'react-hook-form';
import dateFormat from '../utils/dateFormat';
import formatCurrency from '../utils/formatCurrency';
import SuccessModal from './actions/SuccessModal';
import StatementModal from './actions/StatementModal';
import AddScript from '../utils/loadScripts';
import BasiqPreConsentForm from './basiq/BasiqPreConsentForm';

export default function LoanDetails() {
  const { oppId } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm();
  const [withdrawModalOpen, setWithdrawModalClose] = useState<any>(false);
  const [paymentModalOpen, setPaymentModalClose] = useState<any>(false);
  const [selectBank, setSelectBank] = useState<any>(false);
  const [loading, setloading] = useState<any>(true);

  const [editModal, setEditModal] = useState<any>(false);
  const [oppDetail, setOppDetail] = useState<any>(null);
  const [showMsg, setMsg] = useState<any>(false);
  const [print, setPrint] = useState<any>(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const paramsContentID = searchParams.get('OC');
  const [currentContact, setCurrentContact] = useState<any>(null);

  const navigate = useNavigate();

  let loanListDisplay: any = [];

  const getProductDescription = (product_code: string) => {
    switch (product_code) {
      case 'VPA':
        return 'Advertising';
      case 'POP':
        return 'Pre-Sale Improvements';
      case 'ERC':
        return 'Equity Access';
      case 'GST':
        return 'GST Funding';
      default:
        return '';
    }
  };
  const getLoanBadgeClass = (product_code: string) => {
    switch (product_code) {
      case 'Offered':
      case 'Signed':
      case 'Funded':
      case 'Fund Now':
        return 'bg-success';
      case 'Withdrawn':
      case 'Loan Declined':
        return 'bg-warning';
      default:
        return 'bg-info';
    }
  };

  let fundingPurposeList = '';

  let fundingPurposeArr: any = [];
  let newDate = new Date();
  let date: any = newDate.getDate();
  let month: any = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  if (date < 10) {
    date = '0' + date;
  }

  if (month < 10) {
    month = '0' + month;
  }
  let today = year + '-' + month + '-' + date;

  let balance = 0.0;

  const fetchOppData = async () => {
    console.log('OppDetails.fetchData()', oppId);

    if (!oppId) {
      navigate('/application/ThankYou');
      return;
    }

    let oppDetail: any;
    oppDetail = await OpportunityAPI.getOpportunity(oppId)!;
    localStorage.setItem('productCode', oppDetail.productCode);

    //AddScript(oppDetail.productCode);

    if (!oppDetail) {
      navigate('/application/ThankYou');
      return;
    }
    if (oppDetail.dataOwner === 'Auctions Live') {
      document.body.className = 'partner-auctions-live';
    }
    if (oppDetail.dataOwner === 'Tapi') {
      document.body.className = 'partner-tapi';
    }
    setOppDetail(oppDetail);
    setloading(false);
    setEditModal(false);

    loanListDisplay = oppDetail?.loanList;

    if (loanListDisplay?.length === 1) {
      fundingPurposeList = getProductDescription(
        loanListDisplay[0].product_code,
      );
    } else if (loanListDisplay.length > 1) {
      loanListDisplay.forEach((loan: any) => {
        fundingPurposeArr.push(getProductDescription(loan.product_code));
      });

      fundingPurposeList = `${fundingPurposeArr.slice(0, -1).join(', ')} and ${
        fundingPurposeArr[fundingPurposeArr.length - 1]
      }`;
    }

    let currentUserIsBorrower = false;
    let currentContact: any;
    if (!currentContact && !paramsContentID) {
      let contact = oppDetail?.contacts.filter(
        (user: any) => user.isBorrower,
      )[0];
      console.log('setting current contact to :', contact.firstName);
      currentUserIsBorrower = contact.isBorrower;
      currentContact = contact;
      setCurrentContact(contact);
    }
    if (paramsContentID) {
      let contact = oppDetail?.contacts.filter(
        (user: any) => user.id === paramsContentID,
      )[0];
      currentUserIsBorrower = contact.isBorrower;
      console.log('setting current contact to :', contact.firstName);
      currentContact = contact;
      setCurrentContact(contact);
    }
  };

  useEffect(() => {
    fetchOppData();
  }, []);
  const handleModalOpen = () => {
    setWithdrawModalClose(true);
  };

  const handlePaymentModalOpen = () => {
    setPaymentModalClose(true);
  };
  const handleSelectBankModal = () => {
    setSelectBank(true);
  };
  const handleEditDetails = () => {
    setEditModal((pre: any) => !pre);
  };
  const onSubmit = (data: any) => {
    setloading(true);

    console.log('form data', data);

    const dataObj = {
      opportunityID: oppDetail.opportunityID,
      ...data,
    };
    if (!getValues('contractStatus')) {
      dataObj.contractStatus = null;
    }
    if (!getValues('propertyStatus')) {
      dataObj.propertyStatus = '';
    }
    if (!getValues('soldAtAuction')) {
      dataObj.soldAtAuction = '';
    }
    if (
      getValues('appointmentStartDate') === '' ||
      !getValues('appointmentStartDate')
    ) {
      dataObj.appointmentStartDate = null;
    }
    if (
      getValues('onlineListingLink') === '' ||
      !getValues('onlineListingLink')
    ) {
      dataObj.onlineListingLink = '';
    }
    if (getValues('settlementDate') === '' || !getValues('settlementDate')) {
      dataObj.settlementDate = null;
    }
    if (
      getValues('unconditionalDate') === '' ||
      !getValues('unconditionalDate')
    ) {
      dataObj.unconditionalDate = null;
    }
    if (
      getValues('appointmentDurationDays') === '' ||
      !getValues('appointmentDurationDays')
    ) {
      dataObj.appointmentDurationDays = '';
    }
    console.log('form data2', dataObj);
    OpportunityAPI.saveOpportunity({ ...dataObj })
      .then((res) => {
        if (!res) {
          navigate('/application/ThankYou');
          return;
        }
        setloading(false);
        setMsg(true);
        setTimeout(() => {
          setMsg(false);
        }, 3000);
        fetchOppData();
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const sortingType = [
    'Disbursement',
    'Fee',
    'Interest',
    'Adjustment',
    'Instalment',
  ];

  function compareType(a: any, b: any) {
    if (sortingType.indexOf(a.type) < sortingType.indexOf(b.type)) {
      return -1;
    }
    if (sortingType.indexOf(a.type) > sortingType.indexOf(b.type)) {
      return 1;
    }
    return 0;
  }

  function compareDate(a: any, b: any) {
    if (a.createdDate < b.createdDate) {
      return -1;
    }
    if (a.createdDate > b.createdDate) {
      return 1;
    }
    return 0;
  }

  //sorting the data by type and then date

  if (oppDetail?.paymentHistoryList.length > 0) {
    let data = oppDetail.paymentHistoryList;
    data.sort(compareType);
    data.sort(compareDate);

    oppDetail.paymentHistoryList = data;
  }

  return (
    <React.Fragment>
      <NavigationBar dataOwner={oppDetail?.dataOwner} />
      {loading && (
        <div
          className='position-fixed top-0 start-0 vw-100 vh-100 bg-light bg-opacity-50 d-flex justify-content-center align-items-center'
          style={{ zIndex: 2 }}
        >
          <div className='spinner-border text-primary' role='status'>
            <span className='sr-only'>Loading...</span>
          </div>
        </div>
      )}
      {oppDetail && (
        <main className='pc-page-content-wrapper flex-grow-1'>
          <div className='container-md pb-5'>
            <div className='row'>
              <div className='col-lg-10 offset-lg-1'>
                <div className='body'>
                  <div className='row'>
                    <div className='col-12'>
                      <h5 className='mb-1 pre-heading'>
                        {oppDetail?.opportunityName}
                      </h5>
                      <h1 className='mb-4 lh-1'>{oppDetail?.shortAddress}</h1>

                      <h2 className='h2 mb-3'>Finance Details</h2>

                      <div className='card mb-5'>
                        <div className='card-body do-not-print border-bottom'>
                          <div className='row'>
                            <div className='col-12 pb-2 pb-md-0 mt-1'>
                              {oppDetail.productCode === 'PEF' ||
                              oppDetail.productCode === 'TAB-DRAW' ? (
                                <h5 className='mb-0 pre-heading-h5'>
                                  Investment Property Facility
                                </h5>
                              ) : (
                                <h5 className='mb-0 pre-heading-h5'>
                                  Equity Access
                                </h5>
                              )}
                              <div className='card mt-2 bg-light'>
                                <div className='card-body py-2'>
                                  <div className='row py-1'>
                                    <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                      <h6 className='mb-0 pre-heading-h6 font-normal'>
                                        Pre-Approved Limit
                                      </h6>
                                      <p className='p-font mb-0'>
                                        {oppDetail?.facilityDetails
                                          ?.facilityLimitCurrent > 0
                                          ? formatCurrency(
                                              oppDetail?.facilityDetails
                                                ?.facilityLimitCurrent,
                                            )
                                          : formatCurrency(
                                              oppDetail?.facilityDetails
                                                ?.facilityLimit,
                                            )}
                                      </p>
                                    </div>
                                    <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                      <h6 className='mb-0 pre-heading-h6 font-normal'>
                                        Amount Used
                                      </h6>
                                      <p className='p-font mb-0'>
                                        {formatCurrency(
                                          oppDetail?.facilityDetails
                                            ?.facilityUsedPending,
                                        )}
                                      </p>
                                    </div>
                                    <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                      <h6 className='mb-0 pre-heading-h6 font-normal'>
                                        Amount Available
                                      </h6>
                                      <p
                                        className={`p-font mb-0 ${
                                          oppDetail?.facilityDetails
                                            ?.facilityAvailable > 0
                                            ? 'font-bold'
                                            : ''
                                        }`}
                                      >
                                        {formatCurrency(
                                          oppDetail?.facilityDetails
                                            ?.facilityAvailable,
                                        )}
                                      </p>
                                    </div>

                                    {oppDetail.facilityDetails.facilityId &&
                                      oppDetail?.facilityDetails
                                        ?.facilityAvailable > 0 &&
                                      oppDetail.productCode != 'PEF' &&
                                      oppDetail.productCode != 'TAB-DRAW' && (
                                        <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                          <a
                                            className='btn btn-primary me-2 mb-2'
                                            href={`https://property.credit/drawdown/?add=${oppDetail.shortAddress}&oppid=${oppDetail.opportunityID}&opp=${oppDetail.opportunityName}&facid=${oppDetail.facilityDetails.facilityId}`}
                                            target={'_blank'}
                                          >
                                            Request Funds
                                          </a>
                                        </div>
                                      )}
                                    {oppDetail.productCode != 'PEF' &&
                                      oppDetail.productCode != 'TAB-DRAW' && (
                                        <div className='col-12 col-md-9 pb-2 pb-md-0 mt-2'>
                                          <div className='p-font mb-0 bank-acc__note d-flex  flex-row'>
                                            <i
                                              className='bi bi-info-circle'
                                              data-toggle='tooltip'
                                              data-original-title='Refresh'
                                              style={{
                                                fontSize: '1.2rem',
                                                marginRight: '0.5rem',
                                              }}
                                            ></i>
                                            <div className='pt-1'>
                                              <b>Access your equity</b> for
                                              advertising, improvements,
                                              deposits and moving costs. <br />
                                              <b>Further funding</b> may be
                                              available as your sale progresses
                                              –{' '}
                                              <span className='fst-italic'>
                                                fees only apply for money you
                                                access.
                                              </span>
                                              <br />
                                              <b>Amount used</b> is the total of
                                              all loans disbursed and requested.
                                              <br />
                                              <b>Pre-Approved Limit</b> -
                                              further credit criteria may apply.
                                              <br />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {oppDetail?.loanList?.length > 0 && (
                              <>
                                <div className='col-12 pb-0 pb-md-0 mt-4'>
                                  <h5 className='mb-0 pre-heading-h5'>
                                    Loan
                                    {oppDetail?.loanList?.length > 1 &&
                                      's'}{' '}
                                    Information
                                  </h5>

                                  {oppDetail?.loanList.map(
                                    (loan: any, index: number) => {
                                      return (
                                        <>
                                          {loan?.amount_current > 0 && (
                                            <div className='card mt-2 bg-light'>
                                              <div
                                                key={index + 'loan'}
                                                className='card-body py-2'
                                              >
                                                <div className='row py-1 align-items-center'>
                                                  <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                                    <h6 className='mb-0 pre-heading-h6 font-normal'>
                                                      {loan?.product_code ===
                                                        'VPA' && 'Advertising'}
                                                      {loan?.product_code ===
                                                        'POP' &&
                                                        'Pre-Sale Improvements'}
                                                      {loan?.product_code ===
                                                        'ERC' &&
                                                        'Other Equity Release'}

                                                      {loan?.product_code ===
                                                        'BDF' && 'Deposit'}
                                                      {loan?.product_code ===
                                                        'TAB-DRAW' &&
                                                        'Property Tab Drawdown'}
                                                      {loan?.product_code ===
                                                        'PEF' &&
                                                        'Investment Property Expense'}
                                                    </h6>

                                                    <p className='p-font mb-0'>
                                                      {formatCurrency(
                                                        loan?.amount_current,
                                                      )}
                                                    </p>
                                                  </div>

                                                  <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                                    {loan?.paid_to_name && (
                                                      <>
                                                        <h6 className='mb-0 pre-heading-h6 font-normal'>
                                                          Paid To
                                                        </h6>
                                                        <p className='p-font mb-0'>
                                                          {loan?.paid_to_name}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>

                                                  <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                                    {loan?.establishment_fee &&
                                                      loan?.establishment_fee >
                                                        0 && (
                                                        <>
                                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                                            Drawdown Fee
                                                          </h6>
                                                          <p className='p-font mb-0'>
                                                            {formatCurrency(
                                                              loan?.establishment_fee,
                                                            )}
                                                          </p>
                                                        </>
                                                      )}
                                                  </div>

                                                  <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                                    {loan?.monthly_fee_current &&
                                                      loan?.monthly_fee_current >
                                                        0 && (
                                                        <>
                                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                                            Monthly Admin Fee
                                                          </h6>
                                                          <p className='p-font mb-0'>
                                                            {formatCurrency(
                                                              loan?.monthly_fee_current,
                                                            )}{' '}
                                                            {loan?.fees_paid_by ===
                                                              'Agent' && (
                                                              <div className='badge rounded-pill bg-white border fw-normal text-secondary'>
                                                                Paid by Agent
                                                              </div>
                                                            )}
                                                          </p>
                                                        </>
                                                      )}
                                                  </div>

                                                  <div className='col-6 col-md-3 pb-2 pb-md-0  flex-grow-1 d-md-flex justify-content-end'>
                                                    <div
                                                      className={
                                                        'badge rounded-pill bg-white border text-secondary'
                                                      }
                                                    >
                                                      {loan?.stage}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      );
                                    },
                                  )}
                                </div>

                                <div className='col-12 pb-0 pb-md-0 mt-4'>
                                  <h5 className='mb-0 pre-heading-h5'>
                                    Account Summary
                                  </h5>
                                  <div className='card mt-2 bg-light'>
                                    <div className='card-body py-2'>
                                      <div className='row py-1'>
                                        <div className='col-sm-3 pb-2 pb-sm-0'>
                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                            Total Advanced
                                          </h6>
                                          <p className='p-font mb-0'>
                                            {formatCurrency(
                                              oppDetail?.loan
                                                ?.totalDisbursements,
                                            )}
                                          </p>
                                        </div>
                                        <div className='col-sm-3 pb-2 pb-sm-0'>
                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                            Current Balance
                                          </h6>
                                          <p className='p-font mb-0'>
                                            {formatCurrency(
                                              oppDetail?.loan?.balanceTotal,
                                            )}
                                          </p>
                                        </div>
                                        <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                            Loan Term End
                                          </h6>

                                          <p className='p-font mb-0'>
                                            {oppDetail?.loan?.lastPaymentDate
                                              ? dateStringFormat(
                                                  oppDetail?.loan
                                                    ?.lastPaymentDate,
                                                  '-',
                                                  '/',
                                                  true,
                                                )
                                              : '-'}
                                          </p>
                                        </div>
                                        <div className='col-6 col-md-3 pb-2 pb-md-0'>
                                          <h6 className='mb-0 pre-heading-h6 font-normal'>
                                            Max. Loan Term
                                          </h6>
                                          <p className='p-font mb-0'>
                                            {/* {loan?.payment_frequency_current !== 'Single' &&
                              loan?.instalmentsAmount && (
                                <>
                                  {formatCurrency(loan?.instalmentsAmount)}{' '}
                                  {loan?.payment_frequency_current}
                                  <br />
                                </>
                              )} */}
                                            {oppDetail?.loan?.loanTerm}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-12 pb-md-0 mt-4'>
                                  <h5 className='mb-0 pre-heading-h5'>
                                    Applicant
                                    {oppDetail?.contacts?.filter(
                                      (contact: any) => contact.isBorrower,
                                    ).length > 1 && 's'}
                                  </h5>

                                  <div className='card mb-2 mt-2 bg-light'>
                                    {oppDetail?.contacts
                                      ?.filter(
                                        (contact: any) => contact.isBorrower,
                                      )
                                      .map((con: any, index: number) => {
                                        return (
                                          <div
                                            key={index + 'con'}
                                            className='card-body border-bottom'
                                          >
                                            <div className='row'>
                                              <div className='col-6 col-md-3 pb-3 pb-md-0'>
                                                {/* <h6 className='mb-0 pre-heading-h6 font-normal'>
                                              Borrower
                                            </h6> */}
                                                <p className='p-font mb-0'>
                                                  {con?.companyName
                                                    ? con?.companyName
                                                    : `${con?.firstName} ${con?.lastName}`}
                                                </p>
                                              </div>
                                              {con?.email && (
                                                <div className='col-sm-6'>
                                                  {/* <h6 className='mb-0 pre-heading-h6 font-normal'>
                                                Borrower Email
                                              </h6> */}
                                                  <p className='p-font mb-0'>
                                                    {con?.email}
                                                  </p>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {/* <div className='card-body do-not-print border-bottom'>
                          <div className='row'>
                            <div className='col-sm-3 pb-2 pb-sm-0'>
                              <h6 className='mb-0 pre-heading-h6'>
                                Payment Frequency
                              </h6>
                              <p className='p-font mb-0'>
                                {oppDetail?.loan?.repaymentFrequency}
                              </p>
                            </div>
                          </div>
                        </div> */}

                        <div className='card-body do-not-print border-bottom pt-3 pb-2'>
                          {oppDetail.facilityDetails.facilityId && (
                            <a
                              className='btn btn-primary me-2 mb-2'
                              href={`https://property.credit/drawdown/?add=${oppDetail.shortAddress}&oppid=${oppDetail.opportunityID}&opp=${oppDetail.opportunityName}&facid=${oppDetail.facilityDetails.facilityId}`}
                              target={'_blank'}
                            >
                              Request Funds
                            </a>
                          )}
                          {oppDetail.bankStatements === 'required' && (
                            <button
                              className='btn btn-blue me-2 mb-2'
                              onClick={handleSelectBankModal}
                            >
                              Supply Bank Statement
                            </button>
                          )}
                          {oppDetail.canWithdraw && (
                            <button
                              className='btn btn-blue me-2 mb-2'
                              onClick={handleModalOpen}
                            >
                              Withdraw Application
                            </button>
                          )}

                          <a
                            className='btn btn-blue me-2 mb-2'
                            href={`${window.location.origin}/application/${oppDetail.opportunityID}?DocumentUpload=true`}
                            target={`_blank`}
                          >
                            Upload Documents
                          </a>

                          <button
                            className='btn btn-blue me-2 mb-2'
                            onClick={handlePaymentModalOpen}
                          >
                            Make a Payment
                          </button>
                        </div>
                      </div>
                      {oppDetail.productCode === 'INV' ? (
                        <>
                          <h2 className='h2 mb-3'>Invoice Details</h2>
                          <div className='card mb-5'>
                            <div className='card-body do-not-print border-bottom'>
                              <div className='row'>
                                <div className='col-sm-4 pb-2 pb-sm-0'>
                                  <h6 className='mb-0 pre-heading-h6'>
                                    Invoice
                                  </h6>
                                  <p className='p-font mb-0'>
                                    {oppDetail?.opportunityName}
                                  </p>
                                </div>
                                <div className='col-sm-4 pb-2 pb-sm-0'>
                                  <h6 className='mb-0 pre-heading-h6'>
                                    Amount
                                  </h6>
                                  <p className='p-font mb-0'>
                                    {oppDetail?.loan.invoiceAmount}
                                  </p>
                                </div>
                                <div className='col-sm-4 pb-2 pb-sm-0'>
                                  <a
                                    className='btn btn-blue me-2 mb-2'
                                    href={oppDetail?.loan.invoiceUrl}
                                    target={'_blank'}
                                  >
                                    View Invoice
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className='card-body do-not-print border-bottom'>
                              <div className='row'>
                                <div className='col-sm-9 pb-2 pb-sm-0'>
                                  <h6 className='mb-0 pre-heading-h6'>
                                    Property Address
                                  </h6>
                                  <p className='p-font mb-0'>
                                    {oppDetail?.propertyAddress}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        !editModal && (
                          <>
                            <h2 className='h2 mb-3'>Property Details</h2>
                            <div className='card mb-5'>
                              <div className='card-body do-not-print border-bottom'>
                                <div className='row'>
                                  <div className='col-sm-9 pb-2 pb-sm-0'>
                                    <h6 className='mb-0 pre-heading-h6'>
                                      Property Address
                                    </h6>
                                    <p className='p-font mb-0'>
                                      {oppDetail?.propertyAddress}
                                    </p>
                                  </div>
                                  <div className='col-sm-3'>
                                    <h6 className='mb-0 pre-heading-h6'>
                                      Property Type
                                    </h6>
                                    <p className='p-font mb-0'>
                                      {oppDetail?.propertyType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {oppDetail.productCode === 'PEF' ||
                              oppDetail.productCode === 'TAB-DRAW' ? (
                                <>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Property Status
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail.propertyStatus != ''
                                            ? oppDetail?.propertyStatus
                                            : '?'}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Property Management Agency
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.agencyName}
                                        </p>
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Email
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.agencyEmail}
                                        </p>
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Mobile
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.agencyMobile}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Agency
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.agencyName}
                                        </p>
                                      </div>
                                      <div className='col-sm-3 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Appointment Start
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.appointmentStartDate
                                            ? dateStringFormat(
                                                oppDetail?.appointmentStartDate,
                                                '-',
                                                '/',
                                                true,
                                              )
                                            : '-'}
                                        </p>
                                      </div>
                                      <div className='col-sm-2 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Duration
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.appointmentDurationDays !=
                                          ''
                                            ? `${oppDetail?.appointmentDurationDays} Days`
                                            : '-'}
                                        </p>
                                      </div>
                                      <div className='col-sm-3 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Appointment End
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.appointmentExpiryDate
                                            ? dateStringFormat(
                                                oppDetail?.appointmentExpiryDate,
                                                '-',
                                                '/',
                                                true,
                                              )
                                            : '-'}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Sale Status
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.contractStatus != '' ? (
                                            oppDetail?.contractStatus
                                          ) : (
                                            <button
                                              type='button'
                                              className='btn btn-sm btn-outline-danger'
                                              onClick={handleEditDetails}
                                            >
                                              Add Sale Status
                                            </button>
                                          )}
                                        </p>
                                      </div>
                                      <div className='col-sm-8'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Listing Link
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.onlineListingLink !=
                                          '' ? (
                                            oppDetail?.onlineListingLink
                                          ) : (
                                            <button
                                              type='button'
                                              className='btn btn-sm btn-outline-danger'
                                              onClick={handleEditDetails}
                                            >
                                              Add Listing Link
                                            </button>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Sold At Auction?
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.soldAtAuction
                                            ? oppDetail?.soldAtAuction
                                            : '-'}
                                        </p>
                                      </div>
                                      <div className='col-sm-5 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Unconditional Date
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.unconditionalDate ? (
                                            dateStringFormat(
                                              oppDetail?.unconditionalDate,
                                              '-',
                                              '/',
                                              true,
                                            )
                                          ) : (
                                            <>
                                              {oppDetail?.contractStatus ===
                                              'Conditional Contract' ? (
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-outline-danger'
                                                  onClick={handleEditDetails}
                                                >
                                                  Add Unconditional Date
                                                </button>
                                              ) : (
                                                '-'
                                              )}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                      <div className='col-sm-3'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Settlement Date
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {oppDetail?.settlementDate ? (
                                            dateStringFormat(
                                              oppDetail?.settlementDate,
                                              '-',
                                              '/',
                                              true,
                                            )
                                          ) : (
                                            <>
                                              {oppDetail?.contractStatus ===
                                              'Unconditional Contract' ? (
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-outline-danger'
                                                  onClick={handleEditDetails}
                                                >
                                                  Add Settlement Date
                                                </button>
                                              ) : (
                                                '-'
                                              )}
                                            </>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className='card-body do-not-print border-bottom pt-3 pb-2'>
                                <button
                                  className='btn btn-primary  me-2 mb-2'
                                  // disabled={oppDetail.productCode === 'INV'}
                                  onClick={handleEditDetails}
                                >
                                  Update Details
                                </button>
                              </div>
                            </div>
                          </>
                        )
                      )}

                      {editModal && (
                        <>
                          <h2 className='h2 mb-3'>Property & Sale Details</h2>

                          <div className='card  edit mb-5 overflow-visible'>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                              <div className='card-body do-not-print border-bottom'>
                                <div className='row'>
                                  <div className='col-sm-8 pb-2 pb-sm-0'>
                                    <h6 className='mb-0 pre-heading-h6'>
                                      Property Address
                                    </h6>
                                    <p className='p-font mb-0'>
                                      {oppDetail?.propertyAddress}
                                    </p>
                                  </div>
                                  <div className='col-sm-4'>
                                    <h6 className='mb-0 pre-heading-h6'>
                                      Property Type
                                    </h6>
                                    <p className='p-font mb-0'>
                                      {oppDetail?.propertyType}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              {oppDetail.productCode === 'PEF' ||
                              oppDetail.productCode === 'TAB-DRAW' ? (
                                <>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Property Status
                                        </h6>
                                        <Form.Field>
                                          <Select
                                            style={{
                                              width: '100%',
                                              minWidth: '100%',
                                            }}
                                            refs={register('propertyStatus')}
                                            onChange={(e: any, data: any) =>
                                              setValue(
                                                'propertyStatus',
                                                `${data.value}`,
                                              )
                                            }
                                            defaultValue={
                                              oppDetail?.propertyStatus
                                            }
                                            options={[
                                              {
                                                text: `Rented`,
                                                value: 'Rented',
                                              },
                                              {
                                                text: `Up for Rent`,
                                                value: 'Up for Rent',
                                              },
                                              {
                                                text: `Will be Rented-Out Shortly`,
                                                value:
                                                  'Will be Rented-Out Shortly',
                                              },
                                            ]}
                                          />
                                        </Form.Field>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Property Management Agency
                                        </h6>
                                        <Form.Field>
                                          <input
                                            type={'text'}
                                            defaultValue={oppDetail?.agencyName}
                                            {...register('agencyName')}
                                          />
                                        </Form.Field>
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Email
                                        </h6>
                                        <Form.Field>
                                          <input
                                            type={'email'}
                                            defaultValue={
                                              oppDetail?.agencyEmail
                                            }
                                            {...register('agencyEmail')}
                                          />
                                        </Form.Field>
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Mobile
                                        </h6>
                                        <Form.Field>
                                          <input
                                            type={'number'}
                                            defaultValue={
                                              oppDetail?.agencyMobile
                                            }
                                            {...register('agencyMobile')}
                                          />
                                        </Form.Field>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Appointment Start Date
                                        </h6>
                                        <Form.Field>
                                          <input
                                            type={'date'}
                                            defaultValue={
                                              oppDetail?.appointmentStartDate
                                            }
                                            {...register(
                                              'appointmentStartDate',
                                              {
                                                //min: today
                                              },
                                            )}
                                          />
                                        </Form.Field>
                                        {/* {errors.appointmentStartDate && <p className="text-danger">Please check, Date must not be in the past.</p>} */}
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Appointment Duration (Days)
                                        </h6>
                                        <Form.Field>
                                          <input
                                            defaultValue={
                                              oppDetail?.appointmentDurationDays
                                            }
                                            {...register(
                                              'appointmentDurationDays',
                                              {
                                                min: 0,
                                              },
                                            )}
                                            type={'number'}
                                          />
                                        </Form.Field>
                                        {errors.appointmentDurationDays && (
                                          <p className='text-danger'>
                                            Duration must be positive
                                          </p>
                                        )}
                                      </div>
                                      <div className='col-sm-4'>
                                        <h6 className='mb-0 pre-heading-h6'>
                                          Appointment End Date
                                        </h6>
                                        <p className='p-font mb-0'>
                                          {dateStringFormat(
                                            oppDetail?.appointmentExpiryDate,
                                            '-',
                                            '/',
                                            true,
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Sale Status
                                        </h6>
                                        <Form.Field>
                                          <Select
                                            style={{
                                              width: '100%',
                                              minWidth: '100%',
                                            }}
                                            defaultValue={
                                              oppDetail?.contractStatus
                                            }
                                            refs={register('contractStatus')}
                                            onChange={(e: any, data: any) =>
                                              setValue(
                                                'contractStatus',
                                                `${data.value}`,
                                              )
                                            }
                                            options={[
                                              {
                                                text: `Express Of Interest`,
                                                value: 'Express Of Interest',
                                              },
                                              {
                                                text: `Conditional Contract`,
                                                value: 'Conditional Contract',
                                              },
                                              {
                                                text: `Unconditional Contract`,
                                                value: 'Unconditional Contract',
                                              },
                                              {
                                                text: `Pre-Offer/Auction`,
                                                value: 'Pre-Offer/Auction',
                                              },
                                              {
                                                text: `For Sale`,
                                                value: 'For Sale',
                                              },
                                              {
                                                text: `For Sale Soon`,
                                                value: 'For Sale Soon',
                                              },
                                              {
                                                text: `Taken off Market`,
                                                value: 'Taken off Market',
                                              },
                                            ]}
                                          />
                                        </Form.Field>
                                      </div>
                                      <div className='col-sm-8'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Listing Link
                                        </h6>
                                        <Form.Field>
                                          <input
                                            style={{ width: '100%' }}
                                            {...register('onlineListingLink', {
                                              pattern:
                                                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                                            })}
                                            defaultValue={
                                              oppDetail?.onlineListingLink
                                            }
                                            type={'url'}
                                          />
                                        </Form.Field>
                                        {errors.onlineListingLink && (
                                          <p className='text-danger'>
                                            Please check the Url
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='card-body do-not-print border-bottom'>
                                    <div className='row'>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Selling At Auction?
                                        </h6>
                                        <Form.Field>
                                          <Select
                                            style={{
                                              width: '100%',
                                              minWidth: '100%',
                                            }}
                                            refs={register('soldAtAuction')}
                                            onChange={(e: any, data: any) =>
                                              setValue(
                                                'soldAtAuction',
                                                `${data.value}`,
                                              )
                                            }
                                            defaultValue={
                                              oppDetail?.soldAtAuction
                                            }
                                            options={[
                                              { text: `Yes`, value: 'Yes' },
                                              { text: `No`, value: 'No' },
                                            ]}
                                          />
                                        </Form.Field>
                                      </div>
                                      <div className='col-sm-4 pb-2 pb-sm-0'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Unconditional Date
                                        </h6>
                                        <Form.Field>
                                          <input
                                            style={{ width: '100%' }}
                                            {...register('unconditionalDate', {
                                              //min: today
                                            })}
                                            defaultValue={
                                              oppDetail?.unconditionalDate
                                            }
                                            type={'date'}
                                          />
                                        </Form.Field>
                                        {/* {errors.unconditionalDate && <p className="text-danger">Please check, Date must not be in the past.</p>} */}
                                      </div>
                                      <div className='col-sm-4'>
                                        <h6 className='mb-1 pre-heading-h6'>
                                          Settlement Date
                                        </h6>
                                        <Form.Field>
                                          <input
                                            style={{ width: '100%' }}
                                            {...register('settlementDate')}
                                            defaultValue={
                                              oppDetail?.settlementDate
                                            }
                                            type={'date'}
                                          />
                                        </Form.Field>
                                        {/* {errors.settlementDate && <p className="text-danger">Please check, Date must not be in the past.</p>} */}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className='card-body do-not-print border-bottom pt-3 pb-2'>
                                <button
                                  className='btn btn-primary me-2 mb-2'
                                  type={'submit'}
                                >
                                  Save Details
                                </button>
                                <button
                                  className='btn btn-outline-secondary me-2 mb-2'
                                  onClick={handleEditDetails}
                                >
                                  Cancel
                                </button>
                              </div>
                            </Form>
                          </div>
                        </>
                      )}
                      {currentContact?.isBorrower && (
                        <div>
                          <h2 className='h2 mb-3'>
                            Statement{' '}
                            <button
                              className='btn btn-blue'
                              onClick={() => setPrint(true)}
                            >
                              Print
                            </button>
                          </h2>
                          <Tabs>
                            <TabList>
                              <Tab>Transaction History</Tab>
                              <Tab>Transaction Schedule</Tab>
                            </TabList>

                            <TabPanel>
                              <div className='table-responsive-sm'>
                                <table className='table card__table mb-4'>
                                  <thead className='card__table__table-head'>
                                    <tr>
                                      <th scope='col' className=''>
                                        DATE
                                      </th>
                                      <th scope='col' className=''>
                                        TRANSACTION{' '}
                                      </th>
                                      <th scope='col' className='text-end'>
                                        DEBIT
                                      </th>
                                      <th scope='col' className='text-end'>
                                        CREDIT
                                      </th>
                                      <th scope='col' className='text-end'>
                                        BALANCE
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {oppDetail.paymentHistoryList?.map(
                                      (pay: any, ind: any) => {
                                        if (pay.direction === 'Credit') {
                                          balance -= Number(pay.amount);
                                        }
                                        if (
                                          pay.direction === 'Debit' &&
                                          ind === 0
                                        ) {
                                          balance = Number(pay.amount);
                                        }
                                        if (
                                          pay.direction === 'Debit' &&
                                          ind > 0
                                        ) {
                                          balance += Number(pay.amount);
                                        }
                                        return (
                                          <tr key={ind + 'pay'}>
                                            <td className=''>
                                              {dateFormat(new Date(pay.date))}
                                            </td>
                                            <td className=''>
                                              {pay.name}{' '}
                                              {pay?.agentAccount && (
                                                <div className='badge rounded-pill bg-white  fw-normal border text-secondary'>
                                                  Agent
                                                </div>
                                              )}
                                            </td>
                                            <td className='text-end'>
                                              {pay.direction === 'Debit'
                                                ? `${formatCurrency(
                                                    pay.amount,
                                                  )}`
                                                : null}
                                            </td>
                                            <td className='text-end'>
                                              {pay.direction === 'Credit'
                                                ? `${formatCurrency(
                                                    pay.amount,
                                                  )}`
                                                : null}
                                            </td>
                                            <td className='text-end'>
                                              {formatCurrency(balance)}
                                            </td>
                                          </tr>
                                        );
                                      },
                                    )}
                                    {!oppDetail.paymentHistoryList.length && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className='align-middle'
                                        >
                                          There are no records to display.
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className='table-responsive-sm'>
                                <table className='table card__table mb-4'>
                                  <thead className='card__table__table-head'>
                                    <tr>
                                      <th scope='col' className=''>
                                        DATE
                                      </th>
                                      <th scope='col' className=''>
                                        TRANSACTION
                                      </th>
                                      <th scope='col' className='text-end'>
                                        DEBIT
                                      </th>
                                      <th scope='col' className='text-end'>
                                        CREDIT
                                      </th>
                                      <th scope='col' className='text-end'>
                                        BALANCE
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className=''>
                                        {dateFormat(new Date())}
                                      </td>
                                      <td className=''>Current Balance</td>
                                      <td className='text-end'></td>
                                      <td className='text-end'></td>
                                      <td className='text-end'>
                                        {formatCurrency(balance)}
                                      </td>
                                    </tr>
                                    {oppDetail.paymentScheduleList?.map(
                                      (pay: any, ind: any) => {
                                        if (pay.status === 'Processing') {
                                          return;
                                        }
                                        if (pay.direction === 'Credit') {
                                          balance -= Number(pay.amount);
                                        }
                                        if (pay.direction === 'Debit') {
                                          balance += Number(pay.amount);
                                        }
                                        return (
                                          <tr key={ind + 'pay'}>
                                            <td className=''>
                                              {dateFormat(new Date(pay.date))}
                                            </td>
                                            <td className=''>
                                              {pay.name}{' '}
                                              {pay?.agentAccount && (
                                                <div className='badge rounded-pill bg-white border  fw-normal text-secondary'>
                                                  Agent
                                                </div>
                                              )}
                                            </td>
                                            <td className='text-end'>
                                              {pay.direction === 'Debit'
                                                ? `${formatCurrency(
                                                    pay.amount,
                                                  )}`
                                                : null}
                                            </td>
                                            <td className='text-end'>
                                              {pay.direction === 'Credit'
                                                ? `${formatCurrency(
                                                    pay.amount,
                                                  )}`
                                                : null}
                                            </td>
                                            <td className='text-end'>
                                              {formatCurrency(balance)}
                                            </td>
                                          </tr>
                                        );
                                      },
                                    )}
                                    {!oppDetail.paymentScheduleList?.length && (
                                      <tr>
                                        <td
                                          colSpan={4}
                                          className='align-middle'
                                        >
                                          There are no records to display...
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      )}
                      {withdrawModalOpen && (
                        <WithdrawModal
                          setModalClose={setWithdrawModalClose}
                          {...oppDetail}
                          fetchOppData={fetchOppData}
                        />
                      )}
                      {showMsg && <SuccessModal />}
                      {paymentModalOpen && (
                        <PaymentModal
                          setModalClose={setPaymentModalClose}
                          {...oppDetail}
                          fetchOppData={fetchOppData}
                        />
                      )}
                      {print && (
                        <StatementModal
                          {...oppDetail}
                          setPrint={setPrint}
                          today={today}
                        />
                      )}
                      {selectBank && (
                        <div
                          className='modal fade show'
                          style={{
                            display: 'block',
                            background: '#00000077',
                            zIndex: 35,
                          }}
                        >
                          <div className='modal-dialog modal-fullscreen-sm-down'>
                            <div className='modal-content'>
                              <div className='modal-header'>
                                <h5 className='h5 modal-title'>
                                  Bank Statements
                                </h5>
                                <button
                                  type='button'
                                  className='btn-close'
                                  onClick={() => {
                                    setSelectBank(false);
                                  }}
                                ></button>
                              </div>
                              <div
                                className='modal-body'
                                style={{
                                  maxHeight: '80vh',
                                  overflowY: 'auto',
                                }}
                              >
                                <BasiqPreConsentForm
                                  oppId={oppId}
                                  currentContact={currentContact}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
    </React.Fragment>
  );
}
