import React, { useState } from 'react'

const SuccessModal = (props) => {
    const [date, setDate] = useState('1');

    const closeModal = () => {
        props.setSettlementDateModal(false)
    }

    return (
        <div className='position-fixed top-0 start-0 vw-100 vh-100 bg-opacity-50 d-flex justify-content-center align-items-center'
            style={{ zIndex: 50,backgroundColor:"#00000077" }}
        >
            <div className='modal-content' style={{width:"auto"}}>
                <div className='modal-body'>
                    <div className=" mb-4">
                        <p className='text-success'>Property Details updated successfully.</p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SuccessModal;